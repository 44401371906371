import html2canvas from 'html2canvas'
import { jsPDF } from 'jspdf'
import './report_pdf.scss'
import { useParams } from 'react-router-dom'
import { useState, useEffect } from 'react'
import { URLS, convertDictToList, addAvgMetric } from '../helper/util'
import { Typography, Button } from '@material-ui/core'
import { renderName } from "../helper/util";
import { PiechartSmall } from '../components/PiechartSmall'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFileArrowDown } from '@fortawesome/free-solid-svg-icons'
import BarLineComboChart from '../components/BarLineComboChart.jsx'
import Recommendations from '../components/Recommendations'
import axiosInstance from '../helper/axios'


export function pageToPdf(documentName) {
    const data = document.getElementById('report-page')
    html2canvas(data, {scale: 3})
        .then((canvas) => {
            const imgWidth = 210;
            const pageHeight = 297;
            const imgHeight = (canvas.height * imgWidth) / canvas.width;
            let heightLeft = imgHeight;
            let position = 0;
            heightLeft -= pageHeight;
            const pdf = new jsPDF('p', 'mm', 'a4');
            pdf.addImage(canvas, 'PNG', 0, position, imgWidth, imgHeight, '', 'FAST');
            while (heightLeft >= 0) {
                position = heightLeft - imgHeight;
                pdf.addPage();
                pdf.addImage(canvas, 'PNG', 0, position, imgWidth, imgHeight, '', 'FAST');
                heightLeft -= pageHeight;
            }
            pdf.save(documentName + "_Report.pdf");
    });
}

const ReportPdf = () => {
    const params = useParams()
    const cityName = params['name']
    const [ cityInfo, setCityInfo ] = useState()
    const [ foundCity, setFoundCity ] = useState(true)
    const [ transportation, setTransportation ] = useState([])
    const [ reportData, setReportData ] = useState({'report': {}, 'action_plan': {}})
    const [ energyMixes, setEnergyMixes ] = useState([])
    const [ renewables, setRenewables ] = useState([])
    const [ collectedAllData, setCollectedAllData ] = useState(false)
    const [ rankingsAvg, setRankingsAvg ] = useState()
    const [ recommendations, setRecomendations ] = useState()
    const [ sameClusterRankingsAvg, setSameClusterRankingsAvg ] = useState()
    const [ collectedDataPieces, setCollectedDataPieces] = useState({info: false, transportation: false, report: false, energy: false, renewables: false, rankingsAvg: false, recommendations: false, sameClusterRankingsAvg: false})

    const handleCollectedDataPiece = (piece) => {
        setCollectedDataPieces(previousState => {
            return {...previousState, [piece]: true}
        })
    } 

    const getCityInfo = () => {
        axiosInstance.get('/open-organizations/infos/?names[]=' + [cityName, ])
            .then((response) => {
                response.data[cityName].ranking = addAvgMetric(convertDictToList(response.data[cityName].ranking))
                setCityInfo(response.data[cityName])
                handleCollectedDataPiece('info')
            })
            .catch(e => {
                setFoundCity(false)
                handleCollectedDataPiece('info')
            })
    }

    const getTransportationData = () => {
        axiosInstance.get('/open-organizations/transportation/?names[]=' + [cityName, ])
            .then((response) => {
                setTransportation(convertDictToList(response.data[cityName]))
                handleCollectedDataPiece('transportation')
            })
            .catch(e => {
                console.log(e)
                handleCollectedDataPiece('transportation')
            })
    }

    const getReportData = () => {
        axiosInstance.get('/organisations/report_action_plan/?names[]=' + [cityName, ])
            .then((response) => {
                setReportData(response.data)
                handleCollectedDataPiece('report')
            })
            .catch(e => {
                console.log(e)
                handleCollectedDataPiece('report')
            })
    }

    const getEnergyMixesData = () => {
        axiosInstance.get('/open-organizations/energy_mixes/?names[]=' + [cityName, ])
            .then((response) => {
                setEnergyMixes(convertDictToList(response.data[cityName]['mix']))
                handleCollectedDataPiece('energy')
            })
            .catch(e => {
                console.log(e)
                handleCollectedDataPiece('energy')
            })
    }

    const getRenewablesData = () => {
        axiosInstance.get('/open-organizations/renewables_capacity/?names[]=' + [cityName, ])
            .then((response) => {
                if (response.data[cityName]) {
                    const renewablesList = convertDictToList(response.data[cityName])
                    setRenewables(renewablesList)
                }
                handleCollectedDataPiece('renewables')
            })
            .catch(e => {
                console.log(e)
                handleCollectedDataPiece('renewables')
            })
    }

    const getRankingsAvg = () => {
        axiosInstance.get('/open-organizations/rankings_avg/')
        .then((response) => {
            setRankingsAvg(addAvgMetric(convertDictToList(response.data)))
            handleCollectedDataPiece('rankingsAvg')
        })
        .catch(e => {
            console.log(e)
            handleCollectedDataPiece('rankingsAvg')
        })
    }
    
    const getRecomendations = () => {
        axiosInstance.get('/open-organizations/recommendations/?names[]=' + [cityName, ])
        .then((response) => {
            setRecomendations(response.data)
            handleCollectedDataPiece('recommendations')
        })
        .catch(e => {
            console.log(e)
            handleCollectedDataPiece('recommendations')
        })
    }

    const getSameClusterRankingsAvg = () => {
        axiosInstance.get('/open-organizations/same_cluster_rankings_avg/?cluster=' + cityInfo.cluster)
        .then((response) => {
            setSameClusterRankingsAvg(addAvgMetric(convertDictToList(response.data).filter(item => item.name !== 'cluster')))
            handleCollectedDataPiece('sameClusterRankingsAvg')
        })
        .catch(e => {
            console.log(e)
            handleCollectedDataPiece('sameClusterRankingsAvg')
        })
    }

    useEffect(() => {
        getCityInfo()
        getTransportationData()
        getReportData()
        getEnergyMixesData()
        getRenewablesData()
        getRankingsAvg()
        getRecomendations()
    }, [])

    useEffect(() => {
        if (cityInfo) {
            // Only 2021 city reports have a Cluster Metric
            if (cityName.split('_')[1] === '2021') {
                getSameClusterRankingsAvg()
            }
            else {
                // Fake collected data behaviour
                collectedDataPieces.sameClusterRankingsAvg = true
            }
        }
    }, [cityInfo])

    useEffect(() => {
        for (const value in collectedDataPieces) {
            if (!collectedDataPieces[value]) return
        }
        // Wait an extra of 100 milliseconds to make sure that React loaded the Data Generated Components
        const timeout = setTimeout(() => setCollectedAllData(true), 100);
        return () => clearTimeout(timeout);
    }, [collectedDataPieces])

    return (
        <div>
            <div style={{display: 'flex', justifyContent: 'flex-end'}}>
                <Button variant="contained"                    
                    disabled={!foundCity || !collectedAllData}
                    style={{height: '4em', marginRight: '2.5em'}}
                    color="primary"
                    onClick={() => pageToPdf(cityName)}>
                    { collectedAllData ? 'Download PDF Report' : 'Prepearing document ...'}
                    &nbsp;&nbsp;
                    <FontAwesomeIcon icon={faFileArrowDown} /> 
                </Button>
            </div>
            <div className='report-page' id='report-page'>
                <div className='header'>
                    <img src='/logo.svg' className='logo' />
                    <Typography className='city-name' variant='h6'>{cityName.split('_')[0].toUpperCase() + ' REPORT'}</Typography>
                    <div className='year'>{cityName.split('_')[1]}</div>
                </div>
                <div className='body'>
                    <div className='title-placeholder'>
                        Overview
                    </div>
                    <div className='row'>
                        <div className='report-overview'>
                            <div className='rankings-title'>Report Overview</div>
                            <div className='seperator'></div>
                            <div className='report-entries'>
                            { reportData && 
                                Object.entries(reportData.report).length!==0 && Object.entries(reportData.report).map(([key, row], index) => 
                                    key !== 'organisation_id' &&
                                    <div className='entry' key={index}>
                                        <div className='entry-title'>{renderName(key).replace('flag', '')}</div>
                                        <div className='entry-value'>{row}</div>
                                    </div>
                                )
                            }
                            </div>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='rankings-graph'>
                            <div className='rankings-title'>Overall scores</div>
                            <div className='seperator'></div>
                            { (rankingsAvg && cityInfo) &&
                                <BarLineComboChart
                                    rankings={cityInfo.ranking}
                                    avgRankings={rankingsAvg}
                                />
                            }
                        </div>
                    </div>
                    <div className='row'>
                        <div className='rankings-graph'>
                            <div className='rankings-title'>Similar cities score</div>
                            <div className='seperator'></div>
                            { (rankingsAvg && cityInfo) &&
                                <BarLineComboChart
                                    rankings={cityInfo.ranking}
                                    sameClusterAvgRankings={cityName.split('_')[1] === '2021' ? sameClusterRankingsAvg : null }
                                />
                            }
                        </div>
                    </div>
                    <div className='title-placeholder'>
                        Recommendations
                    </div>
                    <div className='recommendations'>
                        { recommendations &&
                            <Recommendations recommendations={recommendations} />
                        }
                    </div>
                    <div className='title-placeholder'>
                        Statistics
                    </div>
                    <div className='row2'>
                        <div className='chart'>
                            <div className='rankings-title'>Transportation</div>
                            <div className='seperator'></div>
                            { transportation && <PiechartSmall rounded={true} data={transportation}/> }
                        </div>
                        <div className='chart'>
                            <div className='rankings-title'>Energy Sources</div>
                            <div className='seperator'></div>
                            { transportation && <PiechartSmall rounded={true} data={energyMixes}/> }
                        </div>
                    </div>
                    <div className='row3'>
                        <div className='chart'>
                            <div className='rankings-title'>Renewable Energy Sources</div>
                            <div className='seperator'></div>
                            { transportation && <PiechartSmall rounded={true} data={renewables}/> }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ReportPdf