/**
 * As material ui Tab panel, this component is used to build the content of tabs made.
 * Children prop is basically the content of the tab shown, the value prop is used so the user can select a specific tab for active(number) and index as the 
 * index of each tab made with this component(number)
 */


import PropTypes from 'prop-types';
import {Box, Typography} from '@material-ui/core'


export function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }
  
  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };
  