/**
 * This component is used to compare the selected years of a city or multiple cities based on some rankings provided below as rankingOptions.
 * It displays a dropdown which allows the user to select the rankingValues they want to compare, once a compare years button is clicked,
 * a table with the data fetched from smart hints and a line chart with a line for each selected ranking value appear.
 * Colors of the lines are imported from a list of colors in options.js
 * CityName prop is the string provided to declare the city which we compare the years.
 */


import {useState, useEffect} from 'react'
import { TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Paper, Button, TextField, Chip } from '@material-ui/core'  
import axios from 'axios'
import {Autocomplete} from '@material-ui/lab'
import {URLS} from '../helper/util'
import { Line } from "react-chartjs-2";
import { Grid } from '@material-ui/core';
import {COLORS} from '../helper/options'
import axiosInstance from '../helper/axios'


export function CompareYears({cityName}) {   
    const [choose, setChoose] = useState(true) 
    const [options, setOptions] = useState([])
    const [rankings, setRankings] = useState([])
    const [rankingValues, setRankingValues] = useState([])


    const rankingOptions=[
        'Local Government Metric',
        'Adaptation Metric',
        'Renewable Energy Metric',
        'Transport Metric',
        'Emissions Metric',
        'Waste Metric',
        'Water Security',
    ]

    useEffect(() => {
        async function fetch() { 
            var names = []
            names.push(cityName)
            const r = await axiosInstance.get('/organisations/smart_hints/', {params:{names}})
            setRankings(r.data)
        }
        fetch()
        
    }, [])

    const compare = () => {
        const lista = []
        for(let i=0;i < rankingValues.length;i++){
            var dataset={
                label: rankingValues[i],
                data: rankings[rankingValues[i]],
                fill: false,
                borderColor: COLORS.map((entry,index)=>{
                                    return COLORS[index % COLORS.length]
                                })
            }
            lista.push(dataset)
            setOptions(lista)
        }
        setChoose(false);
        
    }


    const handleDelete = (item) => {
        setRankingValues((currentOptions) =>
            currentOptions.filter((f) => !(f.label === item.label))
        );
    }

    useEffect(() => {
        console.log(rankingValues)
    }, [rankingValues])

    if (choose) {
        return (
            <Grid container>
                <Grid item md={12} lg={6}>
                    <br/> 
                    <Autocomplete options={rankingOptions} getOptionLabel={option => option} id="disable-clearable" disableClearable
                        renderInput={(params) => <TextField {...params} label="Choose ranking metrics" variant="outlined"/>}
                        onChange={(_event, item) => {
                            setRankingValues([...rankingValues, item]);
                    }}/>
                    <div style={{marginTop: 10}}>
                        {rankingValues && rankingValues.map((item , i) => 
                            <Chip label={item} onDelete={() => handleDelete(item)} />
                        )}
                    </div>
                    <br/>
                    <div style={{direction: 'rtl'}}>
                        <Button style={{backgroundColor: '#e0e0e0', textTransform: 'none', marginBottom: 70}} color='secondary' onClick={() => compare()}> Compare Rankings</Button>
                    </div>
                </Grid>
            </Grid>
        )
    } else {
        return (
            <Grid container spacing={5}>
                <Grid item xs={12} s={12} md={6} lg={6}>
                    <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 650}}>
                            <TableHead>
                            <TableRow>
                                <TableCell>{cityName}</TableCell>
                                {rankings && rankings['Adaptation Metric'].length === 2 &&
                                    <TableCell>2020</TableCell>
                                }
                                <TableCell>2021</TableCell>
                            </TableRow>
                            </TableHead>
                            <TableBody>
                            {rankings && Object.entries(rankings).map(([key, row], index) => 
                                <TableRow
                                    key={index}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >
                                    <TableCell component="th" scope="row" style={{padding:8}}>
                                        {key}
                                    </TableCell>
                                    {row.map((item, i) => 
                                        <TableCell key={i} align="left" style={{padding:8}}>{item}</TableCell>
                                    )}
                               </TableRow>
                            )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                    <Line data={{labels:['2020', '2021'], datasets: options}} />
                </Grid> 
            </Grid>
        )
    }
}