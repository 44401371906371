import {
  Chart as ChartJS,
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Tooltip,
  LineController,
  BarController,
} from "chart.js";
import { Chart } from "react-chartjs-2";
import { renderName } from "../helper/util";

ChartJS.register(
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Tooltip,
  LineController,
  BarController
);

const BarChart2 = (props) => {
	console.log(props.data)
  const labels = props.data.map((item) => renderName(item.name));

  let datasets = [
    {
      type: "bar",
      label: "CO2e",
      backgroundColor: "rgb(75, 192, 192)",
      data: props.data.map((item) => item.value),
      borderColor: "white",
      borderWidth: 2,
    },
  ];

  const data = {
    labels,
    datasets: datasets,
  };

  const options = {
    // Disable Click event
    events: ["mousemove", "mouseout", "touchstart", "touchmove"],
  };

  return <Chart type="bar" data={data} options={options} />;
};

export default BarChart2;
