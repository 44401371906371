import { useContext, useEffect, useState } from "react";
import AuthContext from "../context/auth";
import { Header } from "../components/Header";
import { HeaderSimple } from "../components/HeaderSimple";
import { Outlet } from "react-router-dom";
import { decodeJWT } from "../helper/util";

const RootLayout = () => {
  const { isAuthenticated } = useContext(AuthContext);

  return (
    <>
      <header>{isAuthenticated ? <Header /> : <HeaderSimple />}</header>
      <main>
        <Outlet />
      </main>
    </>
  );
};

export default RootLayout;
