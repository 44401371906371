/**
 * City page is where a user is navigated either after selecting a city from dashboard map either from choose city.
 * The city name and country flag are displayed along with a year select dropdown for the city's year data and multiple tabs.
 * Each tab has it's own use, showing data tables and graphs where needed, based on the category stated as the tab title.
 * You can also navigate to compare cities and compare year through the buttons on tabs.
*/


import React, {useContext, useEffect, useState} from "react";
import { useParams, Link } from "react-router-dom";
import Flag from 'react-world-flags'
import { Piechart } from "../components/Piechart";
import {convertDictToList} from '../helper/util'
import { COUNTRY_CODES } from "../helper/options";
import {Grid, Typography, Tab, Tabs, Box, Button,TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Paper, 
        TextField, } from '@material-ui/core'
import Chip from '@mui/material/Chip';
import {Timeline, TimelineItem, TimelineSeparator, TimelineConnector, TimelineContent, TimelineOppositeContent, TimelineDot} from '@mui/lab';
import FlagIcon from '@mui/icons-material/Flag';
import TrackChangesIcon from '@mui/icons-material/TrackChanges';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import {TabPanel} from "../components/TabPanel";
import {Autocomplete} from '@material-ui/lab'
import ClearIcon from '@mui/icons-material/Clear';
import CheckIcon from '@mui/icons-material/Check';
import { CircularProgress } from '@mui/material';
import {renderName, renderName2} from "../helper/util";
import Spidergraph from "../components/Spidergraph";
import './city.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFilePdf } from '@fortawesome/free-solid-svg-icons'
import { useNavigate } from 'react-router-dom'
import BarChart2 from "../components/BarChart2";
import axiosInstance from "../helper/axios";
import { Line } from "react-chartjs-2";
import {COLORS} from '../helper/options'
import LightbulbIcon from '@mui/icons-material/Lightbulb';
import AuthContext from '../context/auth'

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

export default function City() {
    const { userData } = useContext(AuthContext)
    const params = useParams()
    const [city, setCity] = useState({})
    const [smartHints, setSmartHints] = useState({})
    const [governance, setGovernance] = useState([])
    const [hazardConsequences, setHazardConsequences] = useState([])
    const [adaptationActions, setAdaptationActions] = useState([])
    const [energyMixes, setEnergyMixes] = useState([])
    const [emissionTarget, setEmissionTarget] = useState({})
    const [renewables, setRenewables] = useState([])
    const [transportation, setTransportation] = useState([])
    const [emissions, setEmissions] = useState([])
    const [buildings, setBuildings] = useState([])
    const [initiatives, setInitiatives] = useState([])
    const [value, setValue] = useState(0);
    const [year, setYear] = useState(params['name'].split('_')[1])
    const years=['2019','2020', '2021']
    const [chosenHazard, setChosenHazard] = useState([""])
    const [CRF_sectors, setCRF_sectors] = useState([])
    const [GPC_sectors, setGPC_sectors] = useState([])
    const [list_unique,setList_unique] = useState([])
    const [loading, setLoading] = useState(true)
    const [foundCity, setFoundCity] = useState(true)
    const [recommendations, setRecommendations] = useState([])
    const [rankings, setRankings] = useState()
    const [options, setOptions] = useState([])
    const [sameClusterAvgRankings, setSameClusterAvgRankings] = useState({});
    const [cluster, setCluster] = useState();
    const [myRankings, setMyRankings] = useState({});
    const [totalAvgRankings, setΤotalAvgRankings] = useState({});
    const [isPerformingBetter, setIsPerformingBetter] = useState()
    const [isMetricPerformingBetter, setIsMetricPerformingBetter] = useState()
    const history = useNavigate()
    let cityName = params['name']
    let nameSplitted = cityName.split('_')[0]

    let rankingValues=[
        'Local Government Metric',
        'Adaptation Metric',
        'Renewable Energy Metric',
        'Transport Metric',
        'Emissions Metric',
        'Waste Metric',
        'Water Security Metric',
    ]
    let lista = []

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    


    useEffect(() => {
        function fetch() {
            axiosInstance.get('/open-organizations/infos/?names[]=' + [cityName, ])
                .then((response) => {
                    setCity(response.data[cityName])

                    axiosInstance
                    .get(
                        "/open-organizations/same_cluster_rankings_avg/?cluster=" +
                        response.data[cityName].cluster
                    )
                    .then((response_2) => {
                        setCluster(response_2.data.cluster);
                        delete response_2.data.cluster;
                        let formattedClusterResults = {};
                        Object.entries(response_2.data).map(([key, value], index) => {
                            formattedClusterResults[renderName2(key)] = value;
                        });
                        setSameClusterAvgRankings(formattedClusterResults);
                    });
                })
                .catch ((error) => {
                    console.log(error)
                    setFoundCity(false)
                })

            axiosInstance
                .get(
                    "/open-organizations/smart_hints_current/?names[]=" + nameSplitted
                )
                .then((res) => {
                    setMyRankings(res.data);
                });

            axiosInstance.get("/open-organizations/rankings_avg/").then((res) => {
                let formattedTotalResults = {};
                Object.entries(res.data).map(([key, value], index) => {
                    formattedTotalResults[renderName2(key)] = value;
                });
                setΤotalAvgRankings(formattedTotalResults);
            });

            axiosInstance.get('/open-organizations/is_performing_better/?names[]=' + nameSplitted)
                .then((response) => {
                    setIsPerformingBetter(response.data['percentage'])
                })
                .catch((error) => {
                    console.log(error)
                })
            
            axiosInstance.get('/open-organizations/is_metric_performing_better/?names[]=' + nameSplitted)
                .then((response) => {
                    setIsMetricPerformingBetter(response.data)
                })
                .catch((error) => {
                    console.log(error)
                })

            axiosInstance.get('/open-organizations/goals/?names[]=' + [cityName, ])
                .then((response) => {
                    setGovernance(response.data)
                })
                .catch ((error) => {
                    console.log(error)
                })

            axiosInstance.get('/open-organizations/recommendations/?names[]=' + [cityName, ])
                .then((response) => {
                    setRecommendations(response.data)
                })
                .catch ((error) => {
                    console.log(error)
                })

            axiosInstance.get('/open-organizations/hazard_consequences/?names[]=' + [cityName, ])
                .then((response) => {
                    setHazardConsequences(response.data[cityName])
                })
                .catch ((error) => {
                    console.log(error)
                })

            axiosInstance.get('/open-organizations/adaptation_actions/?names[]=' + [cityName, ])
                .then((response) => {
                    setAdaptationActions(response.data[cityName])
                })
                .catch ((error) => {
                    console.log(error)
                })
            
            axiosInstance.get('/open-organizations/smart_hints/?names[]=' + nameSplitted)
                .then((response) => {
                    for(let i=0;i < rankingValues.length;i++){
                        var dataset={
                            label: rankingValues[i],
                            data: response.data[rankingValues[i]],
                            fill: false,
                            borderColor: COLORS.map((entry,index)=>{
                                                return COLORS[index % COLORS.length]
                                            }),
                            maintainAspectRatio: true,
                        }
                        lista.push(dataset)
                        setOptions(lista)
                    }
                    setRankings(response.data)
                })
                .catch ((error) => {
                    console.log(error)
                })

            axiosInstance.get('/open-organizations/emission_target/?names[]=' + [cityName, ])
                .then((response) => {
                    setEmissionTarget(response.data[cityName])
                })
                .catch ((error) => {
                    console.log(error)
                })

            axiosInstance.get('/open-organizations/energy_mixes/?names[]=' + [cityName, ])
                .then((response) => {
                    setEnergyMixes(convertDictToList(response.data[cityName]['mix']))
                    setSmartHints({'percentage_of_zero_carbon_sources': response.data[cityName]['percentage_of_zero_carbon_sources']})
                })
                .catch ((error) => {
                    console.log(error)
                })

            axiosInstance.get('/open-organizations/renewables_capacity/?names[]=' + [cityName, ])
                .then((response) => {
                    setRenewables(convertDictToList(response.data[cityName]))
                })
                .catch ((error) => {
                    console.log(error)
                })

            axiosInstance.get('/open-organizations/transportation/?names[]=' + [cityName, ])
                .then((response) => {
                    setTransportation(convertDictToList(response.data[cityName]))
                })
                .catch ((error) => {
                    console.log(error)
                })

            axiosInstance.get('/open-organizations/inventory/?names[]=' + [cityName, ])
                .then((response) => {
                    setEmissions(response.data[cityName])
                    setLoading(false)
                })
                .catch ((error) => {
                    console.log(error)
                })

            axiosInstance.get('/open-organizations/building_emissions/?names[]=' + [cityName, ])
                .then((response) => {
                    setBuildings(response.data[cityName])
                })
                .catch ((error) => {
                    console.log(error)
                })

            axiosInstance.get('/open-organizations/initiatives/?names[]=' + [cityName, ])
                .then((response) => {
                    setInitiatives(response.data[cityName])
                })
                .catch ((error) => {
                    console.log(error)
                })
        }
        fetch()
    }, [cityName])  
    
    const onChangeCRF = (item)=>{
        var lista1 = []
        emissions.sectors.map((sector,i) => {
            if(item === sector.supercategory){
                lista1.push({name:sector.category, value:parseInt(sector.emissions)})

                setCRF_sectors(lista1)
            } 
        } 
        )
    }


    const onChangeGPC = (item) => {
        var lista2 = []
        emissions.sectors.map((sector,i) => {
            if(item === sector.category.split('(')[0]){

                lista2.push({name:sector.supercategory, value:parseInt(sector.emissions)})
                setGPC_sectors(lista2)
            } 
        } 
        )
    }

    const listMaker = () =>{
        if(Object.entries(emissions).length!==0 && emissions.type === 'CRF'){
            var CRF_list=[]
            emissions.sectors.map((item,i) => {
                if(!item.supercategory){
                    item.supercategory = 'Total'
                }
                if(item.supercategory!=='AFOLU'&&item.supercategory!=='IPPU')
                    CRF_list.push(item.supercategory)
            })
            setList_unique([...new Set(CRF_list)])
        }else if(Object.entries(emissions).length!==0 && emissions.type !== 'CRF'){
            var GPC_list=[]
            emissions.sectors.map((item,i) => {
                if(!item.category.startsWith('TOTAL')){
                    GPC_list.push(item.category.split('(')[0])
                }
                })
            setList_unique([...new Set(GPC_list)])
        }
    }

    const jumpToReleventDiv = () => {
        const releventDiv = document.getElementById('med');
        // behavior: "smooth" parameter for smooth movement
        releventDiv.scrollIntoView({behavior: "smooth"});
      }

    useEffect(()=>{
        listMaker()
    },[emissions])

    const totalMetricSum = city && city.ranking && Object.values(city.ranking).reduce((a, b) => a + b, 0)
    const totalMetric = totalMetricSum && Math.round(100 * totalMetricSum / 7) / 100

    return (
        <div style={{display: 'block', width: '90%', margin: 'auto'}}>
            <Grid container style={{margin: '50px 0 50px 50px'}}>
                <Grid item xs={3} sm={2} md={1} lg={1}>
                    <Flag code={COUNTRY_CODES[city['country']]} height="50"/>
                </Grid>
                <Grid item xs={7} sm={10} md={7} lg={7}>
                    <Typography variant='h3'>
                        { foundCity ? nameSplitted : 'No data ...'}
                    </Typography>
                </Grid>
                <Grid item container xs={12} sm={12} md={4} lg={4}>
                    <Autocomplete options={years} getOptionLabel={option => option} style={{width:300}} value={year} id="disable-clearable" disableClearable
                        renderInput={(params) => <TextField {...params} label="Choose year" variant="outlined" />} noOptionsText="Loading Options"
                        onChange={(_event, year) => {
                            cityName = nameSplitted + '_' + year
                            setYear(year)
                            window.location.href = '/service31/city/' + nameSplitted + '_' + year
                    }}/>
                </Grid>
            </Grid>
            <Box sx={{ width: '105%' }}>
                <Grid container>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        { (cityName.includes(userData.organization) || userData.isAdmin) ?
                            <Tabs value={value} onChange={handleChange} variant="scrollable"
                                scrollButtons="auto"
                                allowscrollbuttonsmobile
                                aria-label="scrollable auto tabs example"
                            >

                                <Tab label="Report" {...a11yProps(0)} /> 
                                <Tab label="Governance" {...a11yProps(1)} />
                                <Tab label="Climate hazards" {...a11yProps(2)} />
                                <Tab label="Adaptation" {...a11yProps(3)} />
                                <Tab label="Mitigation" {...a11yProps(4)} />
                                <Tab label="Energy" {...a11yProps(5)} />
                                <Tab label="Buildings" {...a11yProps(6)} />
                                <Tab label="GHG Emissions" {...a11yProps(7)} />
                                <Tab label="Transportation" {...a11yProps(8)} />
                                { (cityName.includes(userData.organization) || userData.isAdmin) &&
                                    <button className="MuiButtonBase-root MuiTab-root MuiTab-textColorInherit"
                                            tabindex="-1"
                                            type="button"
                                            role="tab"
                                            aria-selected="false"
                                            id="simple-tab-8"
                                            aria-controls="simple-tabpanel-8"
                                    >
                                    <Link to={'/service31/report/' + cityName }>
                                        <span className="MuiTab-wrapper">
                                            My Covenant Data
                                        </span>
                                    </Link>
                                    <span className="MuiTouchRipple-root"></span>
                                    </button>
                                }
                            </Tabs>
                            :

                            <Tabs value={value} onChange={handleChange} variant="scrollable"
                            scrollButtons="auto"
                            allowscrollbuttonsmobile
                            aria-label="scrollable auto tabs example"
                        >
                            <Tab label="Governance" {...a11yProps(0)} />
                            <Tab label="Climate hazards" {...a11yProps(1)} />
                            <Tab label="Adaptation" {...a11yProps(2)} />
                            <Tab label="Mitigation" {...a11yProps(3)} />
                            <Tab label="Energy" {...a11yProps(4)} />
                            <Tab label="Buildings" {...a11yProps(5)} />
                            <Tab label="GHG Emissions" {...a11yProps(6)} />
                            <Tab label="Transportation" {...a11yProps(7)} />
                            { cityName.includes(userData.organization) || userData.isAdmin &&
                                <button className="MuiButtonBase-root MuiTab-root MuiTab-textColorInherit"
                                        tabindex="-1"
                                        type="button"
                                        role="tab"
                                        aria-selected="false"
                                        id="simple-tab-8"
                                        aria-controls="simple-tabpanel-8"
                                >
                                <Link to={'/service31/report/' + cityName }>
                                    <span className="MuiTab-wrapper">
                                        My Covenant Data
                                    </span>
                                </Link>
                                <span className="MuiTouchRipple-root"></span>
                                </button>
                            }
                        </Tabs>
                        }
                        </Box>
                    </Grid>
                </Grid>
            
                { (cityName.includes(userData.organization) || userData.isAdmin) &&
                <TabPanel value={value} index={0}>
                    <Grid container style={{marginTop: 50}} spacing={2}>
                        <Grid item md={6} lg={6}>
                            <div className="spidergraph-container">
                                { city.ranking && 
                                    <Spidergraph data={city.ranking} />
                                }
                            </div>
                            <div className="metric-cluster">
                                <div className="metric">
                                    <Typography variant='h5' color='primary'>
                                        Total Metric
                                    </Typography>
                                    { totalMetric ? 
                                        <div className="metric-value">{totalMetric}</div>
                                        : <Typography style={{marginTop: '1em'}}>No data ..</Typography>
                                    }
                                </div>
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={6} md={6} lg={6}>
                            { city.name &&
                                <Button variant="contained"
                                        disabled={!foundCity}
                                        color="secondary"
                                        style={{color: "white", height: "4em", maxHeight: "4em", margin: '0em 0 2em 0em', display: 'flex'}} // rgb(255, 79, 79), #ff6969", backgroundColor: "rgb(255, 79, 79)"
                                        onClick={() => history('/service31/report/' + city.name + '/pdf')}>
                                        View PDF Report
                                        &nbsp;
                                        <FontAwesomeIcon icon={faFilePdf} />
                                </Button>
                            }
                                <Grid item xs={8} sm={8} md={8} lg={8}>
                                    <Typography variant='h5' color='primary'>
                                        How to improve your score
                                    </Typography>
                                    
                                    <div style={{ display: 'inline', marginTop: 20 }}>
                                        <ol style={{ listStyle: 'circle' }}>
                                            { recommendations && recommendations.length !== 0 ?
                                                recommendations.map(item => 
                                                    <li>{item}</li>
                                                )
                                            : <Typography>No data ..</Typography>}
                                        </ol>
                                    </div>
                                </Grid>
                            {/* )} */}
                        </Grid>
                    </Grid>
                        <br/><br/>
                        <button className="MuiButtonBase-root MuiTab-root MuiTab-textColorInherit"
                                tabindex="-1"
                                type="button"
                                role="tab"
                                aria-selected="false"
                                id="simple-tab-8"
                                aria-controls="simple-tabpanel-8"
                        onClick={jumpToReleventDiv}>
                            <Link to={'/#box' + cityName }>
                                <span className="MuiTab-wrapper">
                                    About methodology
                                </span>
                            </Link>
                            <span className="MuiTouchRipple-root"></span>
                        </button>
                        <Grid container spacing={5} style={{ marginTop: '2em' }} >
                            <Grid item xs={12} s={12} md={6} lg={6}>
                                <Typography variant='h5' color='primary'>
                                    Your city's score through years
                                </Typography>
                                <TableContainer component={Paper} style={{ marginTop: '2em' }}>
                                    <Table sx={{ minWidth: 650 }}>
                                        <TableHead>
                                        <TableRow>
                                            <TableCell>{cityName}</TableCell>
                                            {rankings && rankings['Adaptation Metric'].length === 2 &&
                                                <TableCell>2020</TableCell>
                                            }
                                            <TableCell>2021</TableCell>
                                        </TableRow>
                                        </TableHead>
                                        <TableBody>
                                        {rankings && Object.entries(rankings).map(([key, row], index) => 
                                            <TableRow
                                                key={index}
                                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                >
                                                <TableCell component="th" scope="row" style={{padding:8}}>
                                                    {key}
                                                </TableCell>
                                                {row.map((item, i) => 
                                                    <TableCell key={i} align="left" style={{padding:8, paddingLeft: 20}}>{item}</TableCell>
                                                )}
                                        </TableRow>
                                        )}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Grid>
                            { (options && options.length === 7) &&
                                <Grid item xs={12} sm={12} md={6} lg={6} >
                                    <div style={{ backgroundColor: 'Highlight', display: 'flex', padding: '.5em', marginBottom: '.3em' }}>
                                        <LightbulbIcon style={{color: 'white', marginRight: '.5em' }} />
                                        <Typography style={{ textAlign: 'center', color: 'white' }}>You can toggle metric categories on the chart by clicking on the labels</Typography>
                                    </div>
                                    <Line data={{labels:['2020', '2021'], datasets: options}} />
                                </Grid>
                            }
                        { year === '2021' &&
                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                <Typography
                                    variant="h5"
                                    color="primary"
                                    style={{ marginBottom: '1em' }}
                                    >
                                    Check how your city's metric scores compared to others
                                </Typography>
                                <TableContainer component={Paper}>
                                    <Table sx={{ minWidth: 650 }}>
                                    <TableHead>
                                        <TableRow>
                                        <TableCell>Metric</TableCell>
                                        <TableCell style={{textAlign: 'center'}}>Your city score</TableCell>
                                        <TableCell style={{textAlign: 'center'}}>All cities average score</TableCell>
                                        <TableCell style={{textAlign: 'center'}}>Similar cities average score</TableCell>
                                        <TableCell></TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {(myRankings && isMetricPerformingBetter) &&
                                        Object.entries(myRankings).map(([key, value], index) => (
                                            <TableRow key={index}>
                                                <TableCell>{renderName(key)}</TableCell>
                                                <TableCell style={{textAlign: 'center'}}>{value}</TableCell>
                                                <TableCell style={{textAlign: 'center'}}>{totalAvgRankings[key]}</TableCell>
                                                <TableCell style={{textAlign: 'center'}}>{sameClusterAvgRankings[key]}</TableCell>
                                                <TableCell>Performing better than <span style={{color: 'Highlight'}}>{isMetricPerformingBetter[key.split(' ').map(word => word.toLowerCase()).join('_')]}%</span> of cities in {key}</TableCell>
                                            </TableRow>
                                        ))}
                                        <TableRow>
                                            <TableCell>Total Metric</TableCell>
                                            <TableCell style={{textAlign: 'center'}}>{ (Object.values(myRankings).reduce((accumulator, currentValue) => accumulator + currentValue[0], 0) / Object.values(myRankings).length).toFixed(2) }</TableCell>
                                            <TableCell style={{textAlign: 'center'}}>{ (Object.values(totalAvgRankings).reduce((accumulator, currentValue) => accumulator + currentValue, 0) / Object.values(totalAvgRankings).length).toFixed(2) }</TableCell>
                                            <TableCell style={{textAlign: 'center'}}>{ (Object.values(sameClusterAvgRankings).reduce((accumulator, currentValue) => accumulator + currentValue, 0) / Object.values(sameClusterAvgRankings).length).toFixed(2) }</TableCell>
                                            <TableCell style={{color: 'Highlight'}}><Typography variant="body1">Your city is performing better than {isPerformingBetter}% of cities</Typography></TableCell>
                                        </TableRow>
                                    </TableBody>
                                    </Table>
                                </TableContainer>
                            </Grid>
                        }
                        </Grid>
                </TabPanel> }

                <TabPanel value={value} index={(cityName.includes(userData.organization) || userData.isAdmin) ? 1 : 0}>
                    <Grid container style={{marginTop: 50}}>
                        <Grid item lg={12}>
                            <Typography variant='h5' color='primary'>
                                City's goals
                            </Typography>
                            <br/>
                            {governance && Object.keys(governance).length !== 0 ?
                                <>
                                    {Object.entries(governance).map(([key, value], index) => 
                                        <div style={{ display: 'flex', alignItems: 'center', marginBottom: '1.5em'}}>
                                                <Chip key={index} label={key} sx={{
                                                                                    height: 'auto',
                                                                                    '& .MuiChip-label': {
                                                                                    display: 'block',
                                                                                    whiteSpace: 'normal',
                                                                                    width: '300px',
                                                                                    padding: '10px',
                                                                                    textAlign: 'center'
                                                                                    },
                                                                                }} id={'chipped'} />
                                            <p style={{textAlign: 'left', margin: '0 2em', borderBottom: '2px solid #2ab683', paddingBottom: '.5em'}}>{ value }</p>
                                        </div>
                                    )}
                                </>
                                :  <p>No data</p>
                            }
                        </Grid>
                    </Grid>
                </TabPanel>

                <TabPanel value={value} index={(cityName.includes(userData.organization) || userData.isAdmin) ? 2 : 1}>
                    <Grid container style={{marginTop: 50}}>
                        <Grid item lg={12}>
                            <Typography variant='h5' color='primary'>
                                Choose Hazard
                            </Typography>
                            <br/>
                            {hazardConsequences ? 
                                <>
                                <Autocomplete options={hazardConsequences} getOptionLabel={option => option.type} style={{width:400}} value={chosenHazard} id="disable-clearable" disableClearable
                                    renderInput={(params) => <TextField {...params} label="Choose hazard" variant="outlined"/>} noOptionsText="Loading Options"
                                    onChange={(_event, item) => {
                                        setChosenHazard(item)    
                                    }}/>
                                <br/> <br/>
                                {hazardConsequences.length !== 0 && hazardConsequences.map((item, i) => {
                                    if(chosenHazard===item){
                                        return (
                                            <div key={i}>
                                                <Typography variant='h6' color='secondary'>
                                                    {item.type}
                                                </Typography>
                                                <Typography variant='h7'>
                                                    Assets affected
                                                </Typography>
                                                <br/>
                                                {item.assets.map((asset, i) => 
                                                    <Chip label={asset} />
                                                )}
                                                <br/> <br/>
                                                <Typography variant='h7'>
                                                    Population cluster affected
                                                </Typography>
                                                <br/>
                                                {item.populations.map((asset, i) => 
                                                    <Chip label={asset} />
                                                )}
                                                <br/> <br/>
                                                <Typography variant='h7'>
                                                    Social impacts
                                                </Typography>
                                                <br/>
                                                {item.social_impacts.map((asset, i) => 
                                                    <Chip label={asset} />
                                                )}
                                                <br/> <br/>
                                                <Button color='primary' variant='outlined' onClick={()=> (cityName.includes(userData.organization) || userData.isAdmin) ? setValue(3) : setValue(2)}>
                                                    See adaptation actions
                                                </Button>
                                            </div>
                                        )   
                                    }
                                })}
                                </> : <p>No data</p>}
                        </Grid>
                    </Grid>
                </TabPanel> 
                <TabPanel value={value} index={(cityName.includes(userData.organization) || userData.isAdmin)? 3 : 2}>
                    <Grid container style={{marginTop: 50}}>
                        <Grid item sm={12} md={12} lg={12}>
                            <Typography variant='h5' color='primary'>
                                Initiatives
                            </Typography>
                            <br/>
                            {initiatives && initiatives.length !== 0 ? 
                            <>
                                {initiatives.length !== 0 && initiatives.map((item, i) => 
                                    <Chip key={i} label={item} />
                                )}
                            </>
                            : <p> No data</p>
                            }
                        </Grid>
                        <Grid item lg={12}>
                            <br/> <br/>
                            <Typography variant='h5' color='primary'>
                                City's hazard adaptation actions
                            </Typography>
                            <br/>
                            {hazardConsequences && adaptationActions ? 
                            <>
                                <Autocomplete options={hazardConsequences} getOptionLabel={option => option.type} style={{width:400}} value={chosenHazard} id="disable-clearable" disableClearable
                                renderInput={(params) => <TextField {...params} label="Choose hazard" variant="outlined"/>} noOptionsText="Loading Options"
                                onChange={(_event, item) => {
                                    setChosenHazard(item)    
                                }}/>
                                <br/>
                                {adaptationActions && adaptationActions.length !== 0 && adaptationActions.map((item, i) => {
                                    if(chosenHazard.type === item.type){
                                        return (
                                            <>
                                                <Typography variant='h6' color='secondary'>
                                                    {item.type}
                                                </Typography>
                                                <Typography variant='h7'>
                                                    Name
                                                </Typography> 
                                                <br/>
                                                <Chip key={i} label={item.name} />
                                                <br/> <br/>
                                                <Typography variant='h7'>
                                                    Status
                                                </Typography> 
                                                <br/>
                                                <Chip key={i} label={item.status} />
                                                <br/> <br/>
                                                <Typography variant='h7'>
                                                    Finance status
                                                </Typography> 
                                                <br/>
                                                <Chip key={i} label={item.finance_status} />
                                                <br/>
                                            </>
                                        )
                                    }
                                }       
                                )}
                            </>
                            : <p> No data</p>
                        }
                        </Grid>
                    </Grid>  
                </TabPanel>
                <TabPanel value={value} index={(cityName.includes(userData.organization) || userData.isAdmin) ? 4 : 3}>
                    <Typography variant='h6' color='secondary'>
                        {emissionTarget.typ}
                    </Typography>
                    <text> Regarding sectors "{emissionTarget.sector}"</text>
                    <br/>
                    <Timeline position="alternate" style={{position: 'absolute', left: 50}}>
                        <TimelineItem>
                            <TimelineOppositeContent
                                sx={{ m: 'auto 0' }}
                                color="text.secondary"
                                >
                                Base year
                            </TimelineOppositeContent>
                            <TimelineSeparator>
                                <TimelineConnector />
                                <TimelineDot>
                                    <FlagIcon />
                                </TimelineDot>
                                <TimelineConnector />
                            </TimelineSeparator>
                            <TimelineContent sx={{ py: '12px', px: 2 }}>
                                <Typography variant="h6" component="span">
                                    {emissionTarget.base_year}
                                </Typography>
                                <Typography>The {nameSplitted} reported {emissionTarget.base_year_emissions} emissions.</Typography>
                            </TimelineContent>
                        </TimelineItem>
                        <TimelineItem>
                            <TimelineOppositeContent
                                sx={{ m: 'auto 0' }}
                                color="text.secondary"
                                >
                                Year starting implementation
                            </TimelineOppositeContent>
                            <TimelineSeparator>
                                <TimelineConnector />
                                <TimelineDot color="primary" variant="outlined">
                                    <TrackChangesIcon />
                                </TimelineDot>
                                <TimelineConnector />
                            </TimelineSeparator>
                            <TimelineContent sx={{ py: '12px', px: 2 }}>
                                <Typography variant="h6" component="span">
                                    {emissionTarget.year_starting_implement}
                                </Typography>
                                <Typography>The city has achieved {emissionTarget.percentage_achieved}% reduction.</Typography>
                            </TimelineContent>
                        </TimelineItem>
                        <TimelineItem>
                            <TimelineOppositeContent
                                sx={{ m: 'auto 0' }}
                                color="text.secondary"
                                >
                                Year ending implementation
                            </TimelineOppositeContent>
                            <TimelineSeparator>
                                <TimelineConnector />
                                <TimelineDot color="primary" >
                                    <EmojiEventsIcon />
                                </TimelineDot>
                                <TimelineConnector sx={{ bgcolor: 'primary.main' }} />
                            </TimelineSeparator>
                            <TimelineContent sx={{ py: '12px', px: 2 }}>
                                <Typography variant="h6" component="span">
                                    {emissionTarget.year}
                                </Typography>
                                <Typography>Aims to achieve {emissionTarget.percentage}% reduction.</Typography>
                            </TimelineContent>
                        </TimelineItem>
                    </Timeline>
                    
                </TabPanel>
                <TabPanel value={value} index={(cityName.includes(userData.organization) || userData.isAdmin) ? 5 : 4} >
                    <Grid container style={{marginTop: 50}}>
                        <Grid item lg={6}>
                            <Typography variant='h5' color='primary' style={{paddingLeft: '4.5em', marginBottom: '1em'}}>
                                    Mix of electricity consumed in the city
                            </Typography>
                            {energyMixes.length !== 0 ?
                                <Piechart data={energyMixes} rounded={true}/>
                                : <Typography style={{ marginLeft: '35%' }}>No data ..</Typography>
                            }

                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6}>
                            <Typography variant='h5' color='primary' style={{paddingLeft: '8.2em', marginBottom: '1em'}}>
                                    Installed capacity (MW)
                            </Typography>
                            {renewables.length !== 0 ?
                                <Piechart data={renewables} rounded={true}/>
                                : <Typography style={{ marginLeft: '35%' }}>No data ..</Typography> 
                            }
                        </Grid>
                    </Grid>
                </TabPanel>
                <TabPanel value={value} index={(cityName.includes(userData.organization) || userData.isAdmin) ? 6 : 5} >
                    <Grid container style={{marginTop: 50}}>
                            <Typography variant='h5' color='primary' style={{marginBottom: '1em'}}>
                                About city's reduction / energy efficiency targets
                            </Typography>
                            <div className="legend">
                                <div className="legend__item">
                                    <CheckIcon style={{color: 'green', margin: '0'}}/> 
                                    &nbsp;
                                    <Typography style={{margin: '0'}} variant="overline">Reported emissions and emissions reduction targets (government operations, city wide targets) and energy efficiency targets for the following building types</Typography>
                                </div>
                                <div className="legend__item">
                                    <ClearIcon style={{color: 'red', margin: '0'}} />
                                    &nbsp;
                                    <Typography style={{margin: '0'}} variant="overline">The city has <b>not</b> reported emissions or implemented emission reduction targets / energy efficiency targets</Typography>
                                </div>
                            </div>
                            <TableContainer component={Paper} style={{}}>
                                <Table >
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>{nameSplitted}</TableCell>
                                            <TableCell>Emission reduction target</TableCell>
                                            <TableCell>Energy efficiency target</TableCell>
                                            {(year === '2019' || year === '2020') && (
                                                <>
                                                    <TableCell> Annual energy use</TableCell>
                                                    <TableCell> Emissions per cacpita</TableCell>
                                                </>
                                            )}
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                    {buildings && buildings.length !== 0 && buildings.map((item, i) => 
                                        i <= 5 &&
                                        <TableRow 
                                            key={i}
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                            >
                                            <TableCell> {renderName(item.typ)}</TableCell>
                                            <TableCell component="th" scope="row" style={{padding: 10}}>
                                                {item.emission_reduction_target === 'Yes' ? <CheckIcon style={{color: 'green'}}/> : <ClearIcon style={{color: 'red'}}/>}
                                            </TableCell>
                                            <TableCell scope="row" style={{padding: 10}}>
                                                {item.energy_efficiency_target === 'Yes' ? <CheckIcon style={{color: 'green'}}/> : <ClearIcon style={{color: 'red'}}/>}
                                            </TableCell>
                                            {year !== '2021' && (
                                                <>
                                                    <TableCell> {item.annual_energy_use}</TableCell>
                                                    <TableCell> {item.emissions_per_capita}</TableCell>
                                                </>
                                            )}
                                        </TableRow>
                                    )}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                    </Grid>
                </TabPanel>


                <TabPanel value={value} index={(cityName.includes(userData.organization) || userData.isAdmin) ? 7 : 6}>
                    { (emissions && Object.entries(emissions).length!==0) ?
                        <Grid container style={{marginTop: 50}}>
                            <Grid item xs={12} sm={12} md={4} lg={4}>
                                <Typography variant='h5' color='primary'>
                                    GHG Emissions Invertory ({emissions.type})
                                </Typography>
                                <br/>
                                <Autocomplete noOptionsText="Loading Options" loading={loading} loadingText='Loading Options'
                                    options={list_unique} getOptionLabel={option => option} style={{width:300}} id="disable-clearable" disableClearable
                                    renderInput={(params) => <TextField {...params} label="Choose Supercategory" variant="outlined" InputProps={{
                                        ...params.InputProps,
                                        endAdornment: (
                                            <React.Fragment>
                                                {loading ? (
                                                <CircularProgress color="inherit" size={20} />
                                                ) : null}
                                                {params.InputProps.endAdornment}
                                            </React.Fragment>
                                            )
                                        }}
                                        />
                                    }
                                    onChange={(_event, item) => onChangeCRF(item)} 
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={7} lg={7}>
                                <Typography  variant='h5' color='primary' style={{textAlign: 'center', marginBottom: '.7em'}}>
                                    Emissions (CO2e)
                                </Typography>
                                <BarChart2 data={CRF_sectors} />
                            </Grid>
                        </Grid> 
                        : <Typography style={{textAlign: 'center'}}>No data ..</Typography>}
                </TabPanel>
                <TabPanel value={value} index={(cityName.includes(userData.organization) || userData.isAdmin) ? 8 : 7} >
                    <Grid container style={{marginTop: 50}}>
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                            <Typography variant="h5" color="primary" style={{textAlign: 'center', marginBottom: '1.5em'}}>
                                Share of each transport mode in your city for passenger transportation
                            </Typography>
                            <div style={{display: 'flex', justifyContent: 'center'}}>
                                { transportation && transportation.length !== 0 ? 
                                    <Piechart rounded={true} data={transportation} />
                                    : <Typography>No data ..</Typography>
                                }
                            </div>
                        </Grid>
                    </Grid>
                </TabPanel>
            </Box>
        </div>    
    );
}