/**
 * In AdaptationActions page there is a map displayed with multiple filter select dropdowns. When filters are selected, the cities that get fetched from backend
 * are shown with markers in the map, based on if they satisfy the active filters.
 */


import React, {useEffect, useState, useContext} from "react";
import {Grid, Typography} from '@material-ui/core'
import CircularProgress from '@mui/material/CircularProgress';
import {Map} from '../components/Map'
import {GenMultipleSelectCheckmarks} from '../components/GenMultipleSelectCheckmarks'
import {URLS} from '../helper/util'
import { HAZARDTYPES, ACTION_FINANCE_STATUS, ACTION_STATUS } from "../helper/options";
import {filterExists, isShownByFilter} from "../helper/util";
import AuthContext from "../context/auth";
import './App.css'
import axiosInstance from "../helper/axios";


export default function AdaptationAction() {
    const [cities, setCities] = useState({})
    const [items, setItems] = useState() //the items with the desired properties
    const [filteredCities, setFilteredCities] = useState({}) // the keys and coordinates after filtering the unfiltered items
    const [typeFilters, setTypeFilters] = useState([])
    const [ loading, setLoading ] = useState(true)
    const { userData } = useContext(AuthContext)
    
    const GROUP = {
        'HAZARD': 'hazard',
        'STATUS': 'status',
        'FINANCE_STATUS': 'finance_status'
        // add here
    }
      
    function toggleFilter(name, group) {
        if (filterExists(name, group, typeFilters)) {
            setTypeFilters((currentFilters) =>
                currentFilters.filter((f) => !(f.name === name && f.group === group))
            );
        } else {
            setTypeFilters(typeFilters.concat({'name': name, group}));
        }
    }


    useEffect(() => {
        axiosInstance.get("/open-organizations/adaptation_actions/?all=1")
                        .then(res => {
                            setItems(res.data)
                        })

            axiosInstance.get("/coordinates/all/")
                            .then(res => {
                                setCities(res.data)
                                setFilteredCities(res.data)
                            })
    },[])

    function applyFilters() {
        var tmp = {}
        for(var [key, val] of Object.entries(items)){
            // add here
            const filter1 = isShownByFilter(key, typeFilters, GROUP.HAZARD, 'type', items)
            const filter2 = isShownByFilter(key, typeFilters, GROUP.STATUS, 'status', items)
            const filter3 = isShownByFilter(key, typeFilters, GROUP.FINANCE_STATUS, 'finance_status', items)

            if(filter1 && filter2 && filter3) {
                tmp[key] = cities[key]
            }
        }   
        setFilteredCities(tmp)
    }

    useEffect(() => {
    },[filteredCities])

    useEffect(() => {
        if (items) {
            setLoading(false)
        }
    }, [items])


    return (
        <>
        { loading && <CircularProgress style={{ position: 'absolute', top: '50%', left: '50%' }} /> }
        <Grid container style={{ width: '90%', margin: 'auto', opacity: loading ? '0.1' : '1', pointerEvents: loading ? 'none' : 'all' }}>
            <Grid item xs={12} sm={12} md={12} lg={12}>
                <Typography variant='h5' color='secondary'> Filter with one or more of the selected filters</Typography>
            </Grid>
            <Grid item sm={6} md={6} lg={3} >
                <GenMultipleSelectCheckmarks filterType={"Hazard Filter"} filterOptions={HAZARDTYPES} 
                        filters={typeFilters} setFilters={toggleFilter} group={GROUP.HAZARD}
                        applyFilters={applyFilters}/>
            </Grid>
            <Grid item sm={6} md={6} lg={3} >
                <GenMultipleSelectCheckmarks filterType={"Action status"} filterOptions={ACTION_STATUS} 
                        filters={typeFilters} setFilters={toggleFilter} group={GROUP.STATUS} 
                        applyFilters={applyFilters}/> 
            </Grid>
            <Grid item sm={6} md={6} lg={3} >
                <GenMultipleSelectCheckmarks filterType={"Finance status"} filterOptions={ACTION_FINANCE_STATUS} 
                    filters={typeFilters} setFilters={toggleFilter} group={GROUP.FINANCE_STATUS} 
                    applyFilters={applyFilters}/> 
            </Grid>
            <Grid item xs={12} s={12} md={12} lg={12}>
                <Map data={filteredCities} details={[]} userData={userData}
                    detailsAttribute1={null} detailsAttribute2={'name'}
                    loading={loading} />
            </Grid>
        </Grid>
        </>
    );
}