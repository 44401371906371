/**
 * In Compare cities, the user can select multiple cities and compare them. When the compare button is clicked, there is a data table displayed with
 * rankings for all the cities selected. There is a loading before dropdown gets the data from server.
 */

import { useState, useEffect, useContext } from "react";
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  Typography,
} from "@material-ui/core";
import axiosInstance from "../helper/axios";
import AuthContext from "../context/auth";
import { renderName, renderName2 } from "../helper/util";

export default function CompareCities() {
  const [myRankings, setMyRankings] = useState({});
  const [totalAvgRankings, setΤotalAvgRankings] = useState({});
  const [sameClusterAvgRankings, setSameClusterAvgRankings] = useState({});
  const [cluster, setCluster] = useState();
  const { userData } = useContext(AuthContext);

  useEffect(() => {
    axiosInstance
      .get(
        "/organisations/smart_hints_current/?names[]=" + userData.organization
      )
      .then((res) => {
        setMyRankings(res.data);
      });

    axiosInstance.get("/open-organizations/rankings_avg/").then((res) => {
      let formattedTotalResults = {};
      Object.entries(res.data).map(([key, value], index) => {
        formattedTotalResults[renderName2(key)] = value;
      });
      setΤotalAvgRankings(formattedTotalResults);
    });

    axiosInstance
      .get("/organisations/infos/?names[]=" + userData.organization + "_2021")
      .then((res1) => {
        axiosInstance
          .get(
            "/open-organizations/same_cluster_rankings_avg/?cluster=" +
              res1.data[userData.organization + "_2021"].cluster
          )
          .then((res2) => {
            setCluster(res2.data.cluster);
            delete res2.data.cluster;
            let formattedClusterResults = {};
            Object.entries(res2.data).map(([key, value], index) => {
              formattedClusterResults[renderName2(key)] = value;
            });
            setSameClusterAvgRankings(formattedClusterResults);
          });
      });
  }, []);

  return (
    <div style={{ width: "90%", margin: "auto" }}>
      <div style={{ width: "70%", margin: "auto" }}>
        <Typography
          variant="h5"
          color="primary"
          style={{ textAlign: "center" }}
        >
          Your city's rankings in comparison with Total Average & Similar Cities
          Average Rankings
        </Typography>
        <br />
      </div>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }}>
          <TableHead>
            <TableRow>
              <TableCell></TableCell>
              <TableCell>Your City's Rankings</TableCell>
              <TableCell>Total Average</TableCell>
              {cluster && (
                <TableCell>Similar Cities Average</TableCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {myRankings &&
              Object.entries(myRankings).map(([key, value], index) => (
                <TableRow key={index}>
                  <TableCell>{renderName(key)}</TableCell>
                  <TableCell>{value}</TableCell>
                  <TableCell>{totalAvgRankings[key]}</TableCell>
                  <TableCell>{sameClusterAvgRankings[key]}</TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}
