import React, { useContext, useEffect, useState } from 'react'
import { Box, Stack } from '@mui/material'
import { styles } from './SignInPage.styles'
import { InputField } from '../../atoms/InputField/InputField'
import { MyButton } from '../../atoms/Button/Button'
import AuthContext from '../../context/auth'
import { useNavigate } from 'react-router-dom'


export default function SignInPage ({setIsSigned}) {
  const classes = styles()
  const authContext = useContext(AuthContext)
  const navigate = useNavigate()

  useEffect(() => {
    if (authContext.isAuthenticated) {
      navigate('/service31')
    }
  })

  const initialFormData = Object.freeze({
    username: '',
    password: '',
  })

  const [ formData, setFormData ] = useState(initialFormData)

  const handleFormInputChange = (e) => {
    authContext.setErrorMessage('')

    setFormData(previousState => {
      return { ...previousState,
                [e.target.name]: e.target.value.trim() }
    })
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    authContext.setErrorMessage('')
    authContext.loginUser(formData)
  }

  return (
  <Box padding={5} >
    <Stack className={classes.root} spacing={2}>
      <InputField label="Username" type="text" name={'username'} onChange={handleFormInputChange} required />
      <InputField label="Password" type="password" name={'password'} onChange={handleFormInputChange} required />
      <span style={{ color: 'red', fontSize: 10 }}>{ authContext.errorMessage }</span>
      <MyButton buttonText='Sign in' onClick={handleSubmit} />
    </Stack>
  </Box>
  )
}
