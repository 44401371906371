/**
 * MapIntensity is another type of Map. It displays a map with custom markers placed on data values(data prop with an array of objects,cities).
 * Each data value(city) must have coordinates(longitude and latitude) for the marker to show and there must be a function called on the func prop
 * to take place on Marker onClick event.
 */

import React, {useEffect, useState} from 'react'
import { MapContainer, TileLayer, Pane, Rectangle } from 'react-leaflet'
import 'leaflet/dist/leaflet.css'
import 'leaflet-defaulticon-compatibility/dist/leaflet-defaulticon-compatibility.css'
import 'leaflet-defaulticon-compatibility';
import "leaflet-extra-markers/dist/css/leaflet.extra-markers.min.css";
import "leaflet-extra-markers/dist/js/leaflet.extra-markers.js";

export function MapIntensity({data, target, details, func}) {
  const [map, setMap] = useState(null);

  useEffect(() => {}, [details])
  
  return (
    <MapContainer center={[40.42, -3.7]} zoom={12} scrollWheelZoom={true} 
      style={{height: "500px"}} 
      whenCreated={setMap}
    >
        <TileLayer
            url="https://api.mapbox.com/styles/v1/mapbox/light-v10/tiles/256/{z}/{x}/{y}@2x?access_token=pk.eyJ1Ijoicm9va2llcm9vayIsImEiOiJjbDA4MnVhbnQwNTFjM2NwOXBycHU2ZHNrIn0.64Yv7XFZumGxYKAMCvm8nA"
            attribution="Map data &copy; <a href=&quot;https://www.openstreetmap.org/&quot;>OpenStreetMap</a> contributors, <a href=&quot;https://creativecommons.org/licenses/by-sa/2.0/&quot;>CC-BY-SA</a>, Imagery &copy; <a href=&quot;https://www.mapbox.com/&quot;>Mapbox</a>"
        />
        {Object.entries(data).length !== 0 && Object.entries(data).map(([key, value], i) => 
          <Pane style={{ zIndex: 499 }} key={i}>
            <Rectangle bounds={[[value['latitude'] + 0.001, value['longitude'] + 0.001], [value['latitude'] - 0.001, value['longitude'] - 0.001]]} 
              pathOptions={{ color: '#14487c'}}
              eventHandlers={{click: () => func(value, key)}}/>

          </Pane>
        )}
        {/* <Legend map={map} details={details}/> */}
    </MapContainer>
  )
}

