import { createContext, useEffect, useState } from "react";
import axiosInstance from "../helper/axios";
import { decodeJWT } from "../helper/util";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [ isAuthenticated, setIsAuthenticated ] = useState(false);
  const [ errorMessage, setErrorMessage ] = useState('');
  const access_token = localStorage.getItem('access_token') || null
  const refresh_token = localStorage.getItem('refresh_token') || null
  let userData = {
    isAdmin: false,
    organization: null,
  }

  // Decode Token
  const jwtDecoded = decodeJWT(access_token)
  if (jwtDecoded) {
      userData = {
        isAdmin: jwtDecoded.isAdmin,
        organization: jwtDecoded.organization,
      }
  }

  useEffect(() => {
    // Check if User is Authenticated on Load
    if (access_token) {
      setIsAuthenticated(true)
    }

    if (refresh_token) {
      if (refresh_token === 'expired') {
        setErrorMessage('Session has expired. Please, login again')
        window.location.href = '/service31/login/'
      }
    }

  }, []);

  const loginUser = (formData) => {
    axiosInstance
      .post("/auth/", {
        username: formData.username,
        password: formData.password,
      })
      .then((res) => {
        // If the User is created or changed password, perform new login attempt
        if (res.status === 201 || res.status === 204) {
          loginUser(formData)
          return
        }
        // Clear error message
        setErrorMessage("");
        // Setup Axios Instance to use Access Token
        axiosInstance.defaults.headers["Authorization"] =
        "JWT " + res.data.access;
        // Save Tokens to Local Storage
        localStorage.setItem("access_token", res.data.access);
        localStorage.setItem("refresh_token", res.data.refresh);
        // Decode Token
        const jwtDecoded = decodeJWT(res.data.access)
        if (jwtDecoded) {
            userData = {
              isAdmin: jwtDecoded.isAdmin,
              organization: jwtDecoded.organization,
            }
        }
        // Manage Authenticated State
        setIsAuthenticated(true);
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status === 400) {
            console.log(error.response.status)
            setErrorMessage("You must provide all relevant fields");
          }
          else if (error.response.status === 401) {
            setErrorMessage("Wrong username or password");
          } 
          else if (error.request) {
            console.log(error);
            setErrorMessage(
              "Something went wrong. Check your internet connection or try again later. If the problem persists please contact the Administrator."
            );
          }
        }
      });
    };

    const logoutUser = () => {
      localStorage.removeItem('access_token')
      localStorage.removeItem('refresh_token')
      window.location.href = '/service31/login/'
    }

    const contextData = {
      'isAuthenticated': isAuthenticated,
      'setIsAuthenticated': setIsAuthenticated,
      'errorMessage': errorMessage,
      'setErrorMessage': setErrorMessage,
      'loginUser': loginUser,
      'logoutUser': logoutUser,
      'userData': userData,
      'access_token': access_token,
    };

    return (
      <AuthContext.Provider value={contextData}>
        { children }
      </AuthContext.Provider>
    );
};

export default AuthContext;
