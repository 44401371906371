/**
 * Compare Years lets the user select a city (or multiple cities) and compare their rankings using the compare years component.
 */


import {useState, useEffect, useContext} from 'react'
import { TextField, Chip, Typography } from '@material-ui/core'  
import {Autocomplete} from '@material-ui/lab'
import { Grid } from '@material-ui/core';
import { CompareYears } from '../components/CompareYears'
import Loading from './../components/Loading'
import axiosInstance from '../helper/axios'
import AuthContext from '../context/auth'

export default function CompareYear() {   
    const [cities, setCities] = useState({})
    const [options, setOptions] = useState([])
    const [loading, setLoading] = useState(true)
    const { userData } = useContext(AuthContext)

    useEffect(() => {
        async function fetch() { 
            let converted = []
            if (userData.organization && !userData.isAdmin) {
                converted = [{ label: userData.organization }]
                setCities(converted)
                setLoading(false)
            } else {
                const r = await axiosInstance.get('/coordinates/all/')
                Object.entries(r.data).map(([key, value],i) => {
                    converted = [...converted, {label: key.split('_')[0], city_id: value.uid}]       
                })
                setCities(converted)
                setLoading(false)
            }
        }
        fetch()
    }, [])


    const handleDelete = (item) => {
        setOptions((currentOptions) =>
            currentOptions.filter((f) => !(f.label === item.label))
        );
    }
    
    return (
            <Grid container>
                <Grid item md={10} lg={10} style={{marginLeft:90}}>
                    <Typography variant='h5' color='primary'>
                        Compare city's ranking throughout the years of conducting measurements
                    </Typography>
                    <br/> 
                    {!loading ? 
                        <Autocomplete options={cities} getOptionLabel={option => option.label} id="disable-clearable" disableClearable
                            renderInput={(params) => <TextField {...params} label={ userData.isAdmin ? "Choose cities" : "Choose city"} variant="outlined"/>}
                            onChange={(_event, item) => {
                                setOptions([...options, item]);
                            }}/> 
                    : 
                        <Loading/>
                    }
                    {options && options.map((item , i) => 
                        <div key={i}>
                            <Chip label={item.label} onDelete={() => handleDelete(item)} style={{marginTop: 10, marginBottom: 10}}/>
                            <br/>
                            <CompareYears cityName={item.label}/>
                        </div>
                    )}
                </Grid>
            </Grid>
    )

}