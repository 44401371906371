/**
 * In Madrid case study the user can see a map of Madrid and depending on their selection on the dropdown, see the buildings or panels shown on the map with custom markers.
 * When the markers are clicked, there is a line chart shown showing values for each month of building or panels. Panels also show a future prediction of 24h 
 * for panel energy production.
 */


import React, {useEffect, useState} from "react";
import {buildings, panels} from '../helper/data'
import {Autocomplete} from '@material-ui/lab'
import {Grid, TextField, Typography, Chip} from '@material-ui/core'
import { MapIntensity } from "../components/MapIntensity";
import { Line } from "react-chartjs-2";
import {CategoryScale} from 'chart.js'; 
import Chart from 'chart.js/auto';
Chart.register(CategoryScale);


export default function MadridCaseStudy() {
    const [filter, setFilter] = useState('Buildings')
    const [mapData, setMapData] = useState(buildings)
    const [plotData, setPlotData] = useState({})
    const [hour1, setHour1] = useState(0)
    const [hour12, setHour12] = useState(0)
    const [hour24, setHour24] = useState(0)
    const [build, setBuild] = useState(true)

    const filterOptions = ['Buildings', 'Panels']

    useEffect(() => {}, [filter, panels])


    const onClickPlot = (clicked, name) => {
        if(filter === 'Buildings') {
            const data = {
                labels: Object.keys(clicked['consumption']),
                datasets: [
                    {
                    label: name + ' energy consumption in ' + Object.values(clicked['consumption'])[0].split(' ')[1] ,
                    data: Object.values(clicked['consumption']).map((item,i)=>
                        parseInt(item.split(' ')[0])
                    ),
                    fill: false,
                    borderColor: "#14487c"
                    }
                ]
            }
            setBuild(true)
            setPlotData(data)  
        } else {
            let month = Object.entries(clicked['energy']).filter(([key, value]) => key === '2020 7')[0][1].split(' ')[0]
            setHour1(((parseInt(month) / 30) / 24).toFixed(2))
            setHour12(((parseInt(month) / 30) / 12).toFixed(2))
            setHour24((parseInt(month) / 30).toFixed(2))
            setBuild(false)
            const data = {
                labels: Object.keys(clicked['energy']),
                datasets: [
                    {
                    label: name + ' solar panel energy production in kWh',
                    data: Object.values(clicked['energy']).map((item,i)=>
                        parseInt(item.split('k')[0])
                    ),
                    fill: false,
                    borderColor: "#14487c"
                    }
                ]
            }
            setPlotData(data)  
        }
        
    }


 
    return (
        <Grid container style={{width: '90%', margin: 'auto'}}>
            <Grid item xs={12} sm={12} md={12} lg={3} style={{marginBottom: 20, marginTop: 20}}>
                <Autocomplete options={filterOptions} getOptionLabel={option => option} value={filter} style={{width:300}} id="disable-clearable" disableClearable
                    renderInput={(params) => <TextField {...params} label="Choose type" variant="outlined"/>} size='small'
                    onChange={(_event, item) => {
                        setFilter(item)
                        if(item === 'Buildings'){
                            setMapData(buildings)
                        }else{
                            setMapData(panels)
                            setBuild(false);
                        }    
                    }}
                /> 
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}></Grid>
            
            <Grid item xs={12} sm={12} md={12} lg={6}>
                <MapIntensity data={mapData} func={(clicked, name) => onClickPlot(clicked, name)}/>
            </Grid>
            <br/>
            <Grid item xs={12} sm={12} md={12} lg={6} style={{padding: '0px 40px 20px 40px'}}>
                {Object.entries(plotData).length!==0 && (
                    <>
                        <Line data={plotData}/>
                        <br/>
                        {build === false && (
                            <>
                                <Typography variant='h5' color='primary'>
                                    Future projection
                                </Typography>
                                <Typography variant='h7' style={{width: 50, marginRight: 10}}>
                                    1h
                                </Typography> 
                                <Chip label={hour1} style={{marginRight: 40}}/>
                                <Typography variant='h7' style={{width: 50, marginRight: 10}}>
                                    12h
                                </Typography> 
                                <Chip label={hour12} style={{marginRight: 40}}/>
                                <Typography variant='h7' style={{width: 50, marginRight: 10}}>
                                    24h
                                </Typography> 
                                <Chip label={hour24} />
                            </>
                        )}
                    </>    
                )}
            </Grid>
        </Grid>
    );
}