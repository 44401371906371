/**
 * The Dashboard page is basically the main page of the app. There is a map where all the fetched cities are shown and by clicking them the user can go to their city page.
 * There are also some information about the app, an accordion with more text info and cards for section analysis and madrid case study,
 *  where when the user clicks learn more, they can navigate to the specific page.
 */


import React, {useContext, useEffect, useState} from "react";
import axiosInstance from "../helper/axios";
import {CardComponent} from '../components/CardComponent'
import {Grid, Typography, Link, Button} from '@material-ui/core'
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown';
import { styled } from '@mui/material/styles';
import {Map} from '../components/Map'
import {StatWithIcon} from "../components/StatWithIcon";
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import './dashboard.scss'
import AuthContext from "../context/auth";

const Accordion = styled((props) => (
    <MuiAccordion disableGutters elevation={0} {...props} />
    ))(({ theme }) => ({
            padding: '0',
            backgroundColor: 'transparent',
            border: 'none'
  }));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: '2em',
    fontSize: 'larger',
    // backgroundColor: 'rgba(20, 72, 124, 0.11)',
    backgroundColor: '#e5ebf1',
    color: '#1f5082',
    marginBottom: '1em',
    borderRadius: '10px',
  }));

export default function Dashboard() {
    const [cities, setCities] = useState({})
    const [ methodologyExpanded, setMethodologyExpanded ] = useState(false)
    const { userData } = useContext(AuthContext)

    const handleExpandMethodology = (e) => {
        setMethodologyExpanded(previousState => !previousState)
    }
    console.log(userData.organization)

    useEffect(() => {
        async function fetch() { 
            try {
                const r = await axiosInstance.get('/coordinates/all/')
                setCities(r.data)
            } catch (error) {
                console.log(error)
            }
        }
        fetch()
    },[])

    
    return (
        <div style={{display: 'block', width: '90%', margin: 'auto'}}>
            <Grid container>
                <Grid item xs={12} s={12} md={12} lg={7}>
                    <Map data={cities} userData={userData} />
                    <br/><br/>
                </Grid>
                <Grid item sm={12} md={12} lg={4} style={{padding: 20}}>
                    <Typography variant='h5' style={{textAlign: 'justify'}}>
                        <Link color='secondary' href="https://matrycs.eu/">MATRYCS </Link>
                        pilot combines existing modern technological breakthroughs in 
                        the areas of <span style={{fontWeight: 'bold'}}>ML / DL and big data</span>, in order to develop a new 
                        <span style={{fontWeight: 'bold'}}> decision-making</span> and 
                        <span style={{fontWeight: 'bold'}}> data analytics solution for energy-efficient cities and buildings</span>.   
                    </Typography>
                    <Grid container>
                       <StatWithIcon icon='/locations.svg' stat='Over 1000 Cities / Municipalities' />
                       <StatWithIcon icon='/data.svg' stat='Over 1000000 rows of data' />
                       <StatWithIcon icon='/analytics.svg' stat='Over 100 new metrics' />
                       <StatWithIcon icon='/analytics.svg' stat='Over 50 methodologies' />
                    </Grid>
                    
                </Grid>
                <Grid item lg={12} id='med'>
                    {/* <Methodology /> */}

                </Grid>
            </Grid>
            <br/><br/>
            <Grid item sm={12} md={12} lg={12} style={{display: 'flex', flexDirection: 'column'}}>
                <Typography variant="h4" style={{marginBottom: '.5em'}}>Methodology</Typography>
                <Button 
                    color="primary"
                    endIcon={<KeyboardDoubleArrowDownIcon />}
                    startIcon={<KeyboardDoubleArrowDownIcon />}
                    onClick={handleExpandMethodology}>
                    Discover what theese metrics mean
                </Button>
                {/* <Grid className="methodology-text">
                    <Typography variant="p">The ranking methodology aims to provide insights of cities ability to tackle climate change. Based on cities disclosed data, a unique process in several categories is facilitated. Answers that cities provide are cumulated and normalized to provide a score in each of category identified and the cumulative one.
                        To create the ranking process, 7 different categories have been identified: Governance, Adaptation, Renewable Energy, Transportation, GHG Emissions, Waste Management and Water Security. 
                        In each category we assess the answers that cities have provided and calculate the initial score. A normalization process is then followed to rank the cities compared to each other. The score reflects the city’s relevant performance in comparison to the others and it aims to be indicative of their level. It does not mean that if a city scores low that their overall activities to tackle climate change is insufficient, as the ranking process is determined by the overall number of cities responded to the questionnaires. 
                        The methodology considers all types of question and their respective answers. Quantitative, qualitative data and combination of both are used to create the indices and the scores of each category. The ranking process aims to facilitate data disclosure and sharing, to support better and open decision-making in activities to tackle climate change. Thus, the lack of answers provided by cities negatively impacts its score. A key point for this methodology is to make cities and local governments aware that their resources can be used in open data initiatives, so data exchanging is a primary factor to be considered.
                        A considerable amount of data processing is taking place to extract the relevant questions to be used and combine the complex information, providing a simple and effective measurement to assess cities’ performance; a simple number!
                        For each category, a description of the questions used to create the indices are explained.
                    </Typography> */}
                    <Accordion expanded={methodologyExpanded} className="methodology-text">
                        <MuiAccordionSummary aria-controls="panel1d-content" id="panel1d-header" style={{display: 'none'}}>
                            <Typography>Collapsible Group Item #1</Typography>
                        </MuiAccordionSummary>
                            <AccordionDetails>
                                <Typography>
                                    The ranking methodology aims to provide insights of cities ability to tackle climate change. Based on cities disclosed data, a unique process in several categories is facilitated. Answers that cities provide are cumulated and normalized to provide a score in each of category identified and the cumulative one.
                                    To create the ranking process, 7 different categories have been identified: Governance, Adaptation, Renewable Energy, Transportation, GHG Emissions, Waste Management and Water Security. 
                                    In each category we assess the answers that cities have provided and calculate the initial score. A normalization process is then followed to rank the cities compared to each other. The score reflects the city’s relevant performance in comparison to the others and it aims to be indicative of their level. It does not mean that if a city scores low that their overall activities to tackle climate change is insufficient, as the ranking process is determined by the overall number of cities responded to the questionnaires. 
                                    The methodology considers all types of question and their respective answers. Quantitative, qualitative data and combination of both are used to create the indices and the scores of each category. The ranking process aims to facilitate data disclosure and sharing, to support better and open decision-making in activities to tackle climate change. Thus, the lack of answers provided by cities negatively impacts its score. A key point for this methodology is to make cities and local governments aware that their resources can be used in open data initiatives, so data exchanging is a primary factor to be considered.
                                    A considerable amount of data processing is taking place to extract the relevant questions to be used and combine the complex information, providing a simple and effective measurement to assess cities’ performance; a simple number!
                                    For each category, a description of the questions used to create the indices are explained.
                                </Typography>
                            </AccordionDetails>
                    </Accordion>
                {/* </Grid>     */}
            </Grid>
            <Grid container className="objectivesContainer">
                <Grid container style={{justifyContent: 'space-evenly'}}>
                    <div className='field'>
                        <img src="/1.svg" className="objectiveIcon has-tooltip" />
                        <span className='tooltip yellow'>
                            <p>The <b>Governance metric</b> uses indices that measure a city's activities and abilities to address climate change through internal infrastructure and resource allocation. It considers factors such as strategic decisions for sustainability, joint initiatives, opportunities for local governments to combat climate change, and the support and cooperation of local companies.</p>
                        </span>
                    </div>
                    <div className='field'>
                        <img src="/3.svg" className="objectiveIcon has-tooltip" />
                        <span className='tooltip orange'>
                            <p>The <b>Adaptation metric</b> uses indices that measure cities' activities and abilities to address climate hazards they are or will be facing. This metric takes into account the identification of these hazards, corresponding adaptation actions, risk assessment for health issues, and factors that challenge or support the ability to adapt.</p>
                        </span>
                    </div>
                    <div className='field'>
                        <img src="/4.svg" className="objectiveIcon has-tooltip" />
                        <span className='tooltip green'>
                            <p>The <b>Renewable Energy metric</b> uses indices that assess cities' activities and abilities to promote clean energy within their boundaries. This metric considers relevant actions taken to support the increase of renewable energy capacity, as well as the percentage of clean energy use within their electricity mix in current or future target years.</p>
                        </span>
                    </div>
                    <div className='field'>
                        <img src="/5.svg" className="objectiveIcon has-tooltip" />
                        <span className='tooltip grey'>
                            <p>The <b>Transportation metric</b> uses indices that measure cities' activities and abilities to promote the use of public transportation and low-emission transportation systems. This metric considers factors such as the amount of transit used each year for different means of transportation, availability of charging spots, and air quality information.</p>
                        </span>
                    </div>
                    <div className='field'>
                        <img src="/7.svg" className="objectiveIcon has-tooltip" />
                        <span className='tooltip blue'>
                            <p>The <b>Transportation metric</b> uses indices that measure cities' activities and abilities to promote the use of public transportation and low-emission transportation systems. This metric considers factors such as the amount of transit used each year for different means of transportation, availability of charging spots, and air quality information.</p>
                        </span>
                    </div>
                </Grid>
                <Grid container style={{justifyContent: 'space-evenly'}}>
                    <div className='field'>
                        <img src="/8.svg" className="objectiveIcon has-tooltip" />
                        <span className='tooltip red'>
                            <p>The <b>GHG emissions metric</b> evaluates city response to effective greenhouse gas (GHG) emissions measuring,  monitoring, reporting and verification processes. It evaluates the emissions inventory quality, under defined methodologies and covering representative sources of emissions. In addition, it assesses the relevance and impact of cities GHG emissions reduction targets in alignment to the Paris Agreement.</p>
                        </span>
                    </div>
                    <div className='field'>
                        <img src="/9.svg" className="objectiveIcon has-tooltip" />
                        <span className='tooltip aliceblue'>
                            <p>The <b>Water Security metric</b> uses indices that measure cities' activities and abilities to assess the access and security of clean water for their inhabitants. This metric considers factors such as clean water access and strategic plans for water management, factors and hazards affecting water security in the future, and corresponding adaptation actions.</p>
                        </span>
                    </div>
                </Grid>
            </Grid>
            <br />
            <br />
            <br />
            <br />
            <Grid container>
                <Grid item sm={12} md={12} lg={12}>
                    <Typography variant="h4">Aggregated city analytics</Typography>
                </Grid>
                <br/><br/><br/>
                <Grid item xs={12} sm={6} md={6} lg={4} style={{padding: 20}}>
                    <CardComponent image='/hazards.jpg' title='Climate hazards & Vulnerabilities' locateTo='/service31/cities/hazard-consequences' button={true}
                        description='Climate hazard & Vulnerabilities e.g sectors affected, social impact and population clusters affected for each climate hazard.'/>
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={4} style={{padding: 20}}>
                    <CardComponent image='/adaptationPlan.jpg' title='Adaptation' locateTo='/service31/cities/adaptation-plan' button={true}
                        description='Adaptation actions undertaken by cities for each climate hazard and filtering.'/>
                </Grid>
            </Grid>
            <br/><br/>  <br/><br/>
            { (userData.organization === 'Municipality of Madrid' || userData.isAdmin) &&
                <Grid container>
                    <Grid item sm={12} md={12} lg={12}>
                        <Typography variant="h4"> Case studies</Typography>
                    </Grid>
                    <br/><br/><br/>
                    <Grid item lg={6} style={{padding: 20}}>
                        <CardComponent image='/madrid.jpg' title='Madrid, Spain' locateTo='/service31/case-studies/madrid' button={true}
                            description='A case study about building energy consumption and sustainability.'/>
                    </Grid>
                </Grid>
            }
            <br/><br/><br/><br/>
        </div>
    );
}