/**
 * In Hazard Consequences page the user can choose filters from multiple filter groups, with dropdowns for each one of them,
 * and filter the cities fetched from the server, showing them on the map with markers.
 */

import React, { useEffect, useState, useContext } from "react";
import { Grid, Typography } from "@material-ui/core";
import CircularProgress from '@mui/material/CircularProgress';
import { Map } from "../components/Map";
import { GenMultipleSelectCheckmarks } from "../components/GenMultipleSelectCheckmarks";
import {
  HAZARDTYPES,
  SOCIAL_IMPACT,
  POPULATION,
  ASSET,
} from "../helper/options";
import { filterExists, isShownByFilter } from "../helper/util";
import AuthContext from "../context/auth";
import "./App.css";
import axiosInstance from "../helper/axios";

export default function HazardConsequences() {
  const [cities, setCities] = useState({});
  const [items, setItems] = useState(); //the items with the desired properties
  const [filteredCities, setFilteredCities] = useState({}); // the keys and coordinates after filtering the unfiltered items
  const [typeFilters, setTypeFilters] = useState([]);
  const [ loading, setLoading ] = useState(true)
  const { userData } = useContext(AuthContext);

  const GROUP = {
    HAZARD: "hazard",
    SOCIAL_IMPACT: "social_impacts",
    POPULATION: "populations",
    ASSET: "assets",
  };

  function toggleFilter(name, group) {
    if (filterExists(name, group, typeFilters)) {
      setTypeFilters((currentFilters) =>
        currentFilters.filter((f) => !(f.name === name && f.group === group))
      );
    } else {
      setTypeFilters(typeFilters.concat({ name: name, group }));
    }
  }

  useEffect(() => {
    axiosInstance
      .get("/open-organizations/hazard_consequences/?all=1")
      .then((res) => {
        setItems(res.data);
      })
      .catch((err) => console.error(err));

    axiosInstance.get("/coordinates/all/").then((res) => {
      setCities(res.data);
      setFilteredCities(res.data);
    });
  }, []);

  useEffect(() => {
    if (items) {
      setLoading(false)
    }
  }, [items])

  function applyFilters() {
    var tmp = {};
    for (var [key, val] of Object.entries(items)) {
      // add here
      const filter1 = isShownByFilter(
        key,
        typeFilters,
        GROUP.HAZARD,
        "type",
        items
      );
      const filter2 = isShownByFilter(
        key,
        typeFilters,
        GROUP.POPULATION,
        "populations",
        items
      );
      const filter3 = isShownByFilter(
        key,
        typeFilters,
        GROUP.SOCIAL_IMPACT,
        "social_impacts",
        items
      );
      const filter4 = isShownByFilter(
        key,
        typeFilters,
        GROUP.ASSET,
        "assets",
        items
      );
      if (filter1 && filter2 && filter3 && filter4) {
        tmp[key] = cities[key];
      }
    }
    setFilteredCities(tmp);
  }

  return (
    <>
    { loading && <CircularProgress style={{ position: 'absolute', top: '50%', left: '50%' }} />}
    <Grid container style={{ width: "90%", margin: "auto", opacity: loading ? '0.1' : '1', pointerEvents: loading ? 'none' : 'all' }}>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <Typography variant="h5" color="secondary">
          {" "}
          Filter with one or more of the selected filters
        </Typography>
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={3}>
        <GenMultipleSelectCheckmarks
          filterType={"Hazard Filter"}
          filterOptions={HAZARDTYPES}
          filters={typeFilters}
          setFilters={toggleFilter}
          group={GROUP.HAZARD}
          applyFilters={applyFilters}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={3}>
        <GenMultipleSelectCheckmarks
          filterType={"Social impact"}
          filterOptions={SOCIAL_IMPACT}
          filters={typeFilters}
          setFilters={toggleFilter}
          group={GROUP.SOCIAL_IMPACT}
          applyFilters={applyFilters}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={3}>
        <GenMultipleSelectCheckmarks
          filterType={"Population clusters"}
          filterOptions={POPULATION}
          filters={typeFilters}
          setFilters={toggleFilter}
          group={GROUP.POPULATION}
          applyFilters={applyFilters}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={3}>
        <GenMultipleSelectCheckmarks
          filterType={"Assets"}
          filterOptions={ASSET}
          filters={typeFilters}
          setFilters={toggleFilter}
          group={GROUP.ASSET}
          applyFilters={applyFilters}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <Map
          data={filteredCities}
          details={null}
          userData={userData}
          detailsAttribute1={null}
          detailsAttribute2={"name"}
          loading={loading}
        />
      </Grid>
    </Grid>
    </>
  );
}