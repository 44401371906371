import {useTheme, useMediaQuery, List} from '@material-ui/core'
import { Buffer } from 'buffer'

//renderName function splits a name on each '_' it contains, makes the first Letter of the first splitted part Upper cased and returns all parts with a space in between
export const renderName = (name) => {
    const name1 = name.split('_')
    var name2 = name1[0].charAt(0).toUpperCase() + name1[0].slice(1)
    for(let i=1; i<name1.length; i++){
        name2 = name2 + ' ' + name1[i]
    }
    return name2
}

export const renderName2 = (name) => {
    const name_arr = name.split('_')
    const name_1 = name_arr[0].charAt(0).toUpperCase() + name_arr[0].slice(1)
    // console.log(name_arr)
    const name_2 = name_arr[1].charAt(0).toUpperCase() + name_arr[1].slice(1)
    if (name_2 === 'Security' || name_2 === 'Government' || name_2 === 'Energy') {
        const name_3 = name_arr[2].charAt(0).toUpperCase() + name_arr[2].slice(1)
        return name_1 + ' ' + name_2 + ' ' + name_3
    }
    return name_1 + ' ' + name_2
}


//isShownByFilter function helps to set active filters from multiple filter groups at the same time
export const isShownByFilter = (key, filters, group, typeOfFilter, items) => {
    const activeFilters = filters.filter((filter) => filter.group === group);
    const valuesToBeFiltered = [];
    items[key].map((item, i) => Array.isArray(item[typeOfFilter]) ? valuesToBeFiltered.push(...item[typeOfFilter]) : valuesToBeFiltered.push(item[typeOfFilter]));

    if (!activeFilters.length) return true;
    return activeFilters.some((filter) => valuesToBeFiltered.includes(filter.name));
  }

//filterExists function checks if a filter is selected in a filter Group so there is no duplicate active filter(if the same filter is selected it's getting unchecked)
export const filterExists = (name, group, typeFilters) => {
    return (
        typeFilters.find((f) => f.name === name && f.group === group) !== undefined
    );
}

//GetRenders function is used for responsiveness, based on screen size used
export function GetRenders() {
    const theme = useTheme();
    return {
        UP_MD: useMediaQuery(theme.breakpoints.up('md')),
        DOWN_SM: useMediaQuery(theme.breakpoints.down('sm')),
        XS: useMediaQuery(theme.breakpoints.down('xs')),
        LG: useMediaQuery(theme.breakpoints.up('lg')),
        DOWN_MD : useMediaQuery(theme.breakpoints.down('md'))
    }
}

export const URLS = {
    SERVER: "https://matrycsanalytics.epu.ntua.gr/service31_back",
    // SERVER: "http://0.0.0.0:8000",
    HOME: '/service31',
    CITY: '/service31/city',
    SIGNIN: '/service31/signin'
}

//convertDictToList function is used to convert a dict coming from backend to a list, in the form needed for some components
export const convertDictToList = (dict) => {
    var converted = []
    Object.entries(dict).forEach(([key, value]) => {
        converted = [...converted, {name: key, value: value}]
    })
    return converted
}

export const addAvgMetric = (arr) => {
    let total = 0
    arr.forEach(item => total += item.value)
    arr.push({name: 'total_average', value: Number((total / arr.length).toFixed(2))})
    return arr
}

export const decodeJWT = (token) => {
    if (token) {
        return JSON.parse(Buffer.from(token.split(".")[1], "base64").toString())
    }
    return false
}
