const Recommendations = ({ recommendations }) => {
  /**
   * This Components splits Recommendations array into two Columns
   */
  if (recommendations.length === 1) {
    return (
      <ul className="recomendation-entries">
        <li>{recommendations[0]}</li>
      </ul>
    );
  }

  let recommendationSplitRemainder = recommendations.length % 2;

  /**
   * The first column takes 1 more Recommendation if the remainder of
   * the division between the length of the Recommendation array and 2
   * is greater than 0.
   */
  let firstCol = recommendations.slice(
    0,
    ~~(recommendations.length / 2) + recommendationSplitRemainder
  );

  /**
   * The second column takes all the rest.
   */
  let secondCol =
    recommendationSplitRemainder > 0
      ? recommendations.slice(
          ~~(recommendations.length / 2) + recommendationSplitRemainder,
          recommendations.length + 1
        )
      : recommendations.slice(
          ~~(recommendations.length / 2),
          recommendations.length + 1
        );

  return (
    <>
      <ul className="recomendation-entries">
        {firstCol.map((item, index) => (
          <li key={index}>{item}</li>
        ))}
      </ul>
      <ul className="recomendation-entries">
        {secondCol.map((item, index) => (
          <li key={index}>{item}</li>
        ))}
      </ul>
    </>
  );
};

export default Recommendations;
