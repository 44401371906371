/**
 * Header component is as the name states, the header of the page, containing a logo of the site which goes to the main page on click and 2 navigation links
 * One for main page and one for city insights which allows you to select a city which you want to go to it's page.
 */


import { React } from 'react'
import { Grid, TableHead, TableRow, Checkbox, TableCell, Table } from '@material-ui/core'  


export function CustomTable2({dict, removeValue}) {
    // console.log(dict)

    return (
        <Table sx={{ minWidth: 650 }} size="small" style={{maxWidth: "430px"}}>
            {Object.keys(dict).length !== 0 && Object.entries(dict).map(([key, value], i) => (
                <>
                    {(value === "True" || value === "False") ? (
                        <TableRow key={i}>
                            <TableCell>
                                {(key.split('_').join(' ').charAt(0).toUpperCase() + key.split('_').join(' ').slice(1)).replace('flag', '')}
                            </TableCell>
                            <TableCell align='right'>
                                <Checkbox defaultChecked={value === "True"} disabled/>
                            </TableCell>
                        </TableRow>
                    ) : (
                        <>
                        {(key !== removeValue) && (
                            <TableRow key={i}>
                                <TableCell>
                                    {(key.split('_').join(' ').charAt(0).toUpperCase() + key.split('_').join(' ').slice(1)).replace('flag', '')}
                                </TableCell>
                                <TableCell align="right">
                                    {value}
                                </TableCell>
                            </TableRow>
                        )}
                        </>
                    )}
                </>
                )
            )}
        </Table>
    )

}