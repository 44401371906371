/**
 * Piechart component is used to generate a Pie chart for given data. Data prop provides the data needed (array of objects, with name(string) and value(number))
 * and rounded is a boolean prop that rounds the values of data.NameKey and valueKey props declare the key of the data objects as stated above(name,value as default)
 */


import React, {useEffect, useState} from 'react';
import { PieChart, Pie, Legend, Cell } from 'recharts';
import {COLORS} from '../helper/options'
import {renderName} from "../helper/util";

export function Piechart({data, rounded, nameKey='name', valueKey='value', transformed = true}) {
  const [transformedData, setTransformedData] = useState([])
  const RADIAN = Math.PI / 180;

  var lista = []
  useEffect(()=>{
    data.map((item,i)=>{
      lista.push({['name']: renderName(item.name), ['value'] : item.value})
      setTransformedData(lista)
    }
  )
  }, [data]) 


  const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index, payload, ...props }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 1.1;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);
  
    return (
      <>
        {percent > 0.009 && (
          <>
          {rounded ? 
              <text x={x} y={y} fill="black" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
                {payload.payload.name} {' '}
                {`${(percent * 100).toFixed(0)}%`}
              </text>
            :
            <text x={x} y={y} fill="black" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
              {payload.payload.name} {' '}
              {`${(percent * 100)}%`}
            </text>
          }
          </>
        )}
      </>
    );
  };
  

  return (
    <PieChart width={700} height={400}>      
      <Pie data={transformed ? transformedData : data} isAnimationActive={false} labelLine={false} nameKey={nameKey}
        dataKey={valueKey} cx="50%" cy="50%" outerRadius={100} fill="#8884d8" label={renderCustomizedLabel}>
          {data.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
            ))}
      </Pie>
      {/* <Tooltip /> */}
      <Legend verticalAlign="top" height={36}/>
    </PieChart>
  );
}
