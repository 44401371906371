import { Navigate } from 'react-router-dom'
import { useContext } from 'react'
import AuthContext from '../context/auth'

const RequireAuthentication = ({ children }) => {
    const { access_token } = useContext(AuthContext)

    if ( !access_token ) {
        return <Navigate to='/service31/login' replace />
    }

    return children
}

export default RequireAuthentication