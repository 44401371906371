import { TableRow, TableCell } from "@material-ui/core"

const ReportTableBody = ({ data }) => {
    return (
        data.map((item, i) => 
            <TableRow key={i}>
                {Object.keys(item).map((x, i) =>
                    <TableCell key={i}>{item[x]}</TableCell>    
                )}
            </TableRow>
        )
    )
}

export default ReportTableBody