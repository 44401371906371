import { TableCell } from "@material-ui/core"

const ReportTableHead = ({ data }) => {
    return (
        Object.keys(data[0]).map(item => 
            <TableCell>{(item.charAt(0).toUpperCase() + item.slice(1)).replaceAll('_', ' ')}</TableCell>
        )
    )
}

export default ReportTableHead