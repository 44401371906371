/**
 * EnergyMix page renders the report data
 */

import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import axiosInstance from "../helper/axios";

import {
  Grid,
  Table,
  TableBody,
  TableContainer,
  Paper,
  Box,
  Tabs,
  Tab,
  Typography,
  Button,
  TableHead,
  TableRow,
  TableCell,
  Checkbox,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
} from "@material-ui/core";
import { URLS } from "../helper/util";
import Flag from "react-world-flags";
import { COUNTRY_CODES } from "../helper/options";
import { CustomTable2 } from "../components/CustomTable2";
import { TabPanel } from "../components/TabPanel";
import ReportTableHead from "../components/ReportTableHead";
import ReportTableBody from "../components/ReportTableBody";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function Report() {
  const params = useParams();
  const [reportData, setReportData] = useState({});
  const [actionPlanIds, setActionPlanIds] = useState({});
  const [actionPlanInventory, setActionPlanInventory] = useState();
  const [cityInfo, setCityInfo] = useState({});
  const [activeTab, setActiveTab] = useState(0);
  const [actionsData, setActionsData] = useState();
  const [actionPlanGoals, setActionPlanGoals] = useState();
  const [actionPlanHazards, setActionPlanHazards] = useState();
  const [actionPlanSectors, setActionPlanSectors] = useState();
  const [adaptationScoreboard, setAdaptationScoreboard] = useState();
  const [adaptationSectors, setAdaptationSectors] = useState();
  const [foundCity, setFoundCity] = useState(true);
  const navigate = useNavigate()
  const cityName = params.name;

  const handleTabChange = (e, currentTabValue) => {
    setActiveTab(currentTabValue);
  };

  function handleActionsDataChange(id, data) {
    setActionsData(function (previousState) {
      return { ...previousState, [id]: data };
    });
  }

  useEffect(() => {
    axiosInstance
      .get("/open-organizations/infos/?names[]=" + [cityName])
      .then((res) => {
        setCityInfo(res.data[cityName]);
      })
      .catch((err) => {
        setFoundCity(false);
        console.log(err);
      });

    axiosInstance
      .get("/organisations/report_action_plan/?names[]=" + [cityName])
      .then((res) => {
        setReportData(res.data);

        // No Cities with Adaptation Action Sector(s). Check if something wrong with the population of the Data
        const action_plan_ids = Object.keys(res.data.action_plan);
        setActionPlanIds(action_plan_ids);
        for (const id of action_plan_ids) {
          // handleActionsDataChange(id, ( await axiosInstance.get('/organisations/report_action_plan_actions/?action_plan_id=' + id + "&names[]=City of Helsinki")).data)
          axiosInstance
            .get(
              "/organisations/report_action_plan_actions/?action_plan_id=" +
                id +
                "&names[]=City of Helsinki"
            )
            .then((res) => {
              handleActionsDataChange(id, res.data);
            });
        }
      })
      .catch((err) => {
        console.log(err);
      });

    axiosInstance
      .get("/organisations/report_action_plan_inventory/?names[]=" + [cityName])
      .then((res) => {
        setActionPlanInventory(res.data);
      })
      .catch((err) => {
        console.log(err);
      });

    axiosInstance
      .get("/organisations/report_action_plan_goals/?names[]=" + [cityName])
      .then((res) => {
        setActionPlanGoals(res.data);
      })
      .catch((err) => {
        console.log(err);
      });

    axiosInstance
      .get("/organisations/report_action_plan_hazards/?names[]=" + [cityName])
      .then((res) => {
        setActionPlanHazards(res.data);
      })
      .catch((err) => {
        console.log(err);
      });

    axiosInstance
      .get("/organisations/report_action_plan_sectors/?names[]=" + [cityName])
      .then((res) => {
        setActionPlanSectors(res.data);
      })
      .catch((err) => {
        console.log(err);
      });

    axiosInstance
      .get("/organisations/report_adaptation_scoreboard/?names[]=" + [cityName])
      .then((res) => {
        setAdaptationScoreboard(res.data);
      })
      .catch((err) => {
        console.log(err);
      });

    axiosInstance
      .get(
        "/organisations/report_adaptation_action_sectors/?names[]=" + [cityName]
      )
      .then((res) => {
        setAdaptationSectors(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [cityName]);

  return (
    <>
      <div id="doc" style={{ display: "block", width: "90%", margin: "auto" }}>
        <Grid container style={{ margin: "50px" }}>
          <Grid item xs={3} sm={2} md={1} lg={1}>
            <Flag code={COUNTRY_CODES[cityInfo["country"]]} height="50" />
          </Grid>
          <Grid item xs={7} sm={10} md={7} lg={10}>
            <Typography variant="h3">
              {foundCity
                ? cityName.replace("_", " ") + " my covenant report data"
                : "Report not found..."}
            </Typography>
          </Grid>
          <Button variant="outlined" startIcon={<ArrowBackIcon />} onClick={() => {navigate('/service31/city/' + cityName)}} >
            Go back
          </Button>
        </Grid>
        <Box sx={{ width: "105%" }}>
          <Grid container>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <Tabs
                  value={activeTab}
                  onChange={handleTabChange}
                  variant="scrollable"
                  scrollButtons="auto"
                  allowscrollbuttonsmobile
                  aria-label="Tabs of Report Data Category"
                >
                  <Tab label="Overview" {...a11yProps(0)} />
                  <Tab label="Action Plans" {...a11yProps(1)} />
                  <Tab label="Actions" {...a11yProps(2)} />
                  <Tab label="Emission Inventories" {...a11yProps(3)} />
                  <Tab label="Goals" {...a11yProps(4)} />
                  <Tab label="Hazards" {...a11yProps(5)} />
                  <Tab label="Sectors" {...a11yProps(6)} />
                  <Tab label="Adaptation Sectors" {...a11yProps(7)} />
                  <Tab label="Adaptation Scoreboard" {...a11yProps(8)} />
                </Tabs>
              </Box>
            </Grid>
          </Grid>
          <TabPanel value={activeTab} index={0}>
            <Grid container style={{ marginTop: 50 }} spacing={2}>
              <Grid item md={6} lg={6}>
                <Typography variant="h5" color="primary">
                  Basic Information
                </Typography>
                {!foundCity ? (
                  "No data"
                ) : reportData && reportData.report ? (
                  <>
                    <TableContainer
                      component={Paper}
                      style={{ width: 300, marginTop: 20 }}
                    >
                      <CustomTable2
                        dict={reportData.report}
                        removeValue="organisation_id"
                      />
                    </TableContainer>
                  </>
                ) : (
                  <Typography style={{ marginTop: "2em" }}>
                    No data ..
                  </Typography>
                )}
              </Grid>
            </Grid>
          </TabPanel>
          <TabPanel value={activeTab} index={1}>
            <Grid
              container
              style={{ marginTop: 50, width: "100%" }}
              spacing={2}
            >
              <Grid item md={6} lg={12}>
                <Typography variant="h5" color="primary">
                  Action Plans
                </Typography>
                {reportData.actionPlan ? (
                  <TableContainer style={{ width: "100%" }}>
                    <Table style={{ width: "100%" }}>
                      <TableHead>
                        <TableRow>
                          <TableCell>Action Plan ID</TableCell>
                          {reportData.action_plan &&
                            Object.keys(reportData.action_plan).length !== 0 &&
                            Object.keys(
                              reportData.action_plan[
                                Object.keys(reportData.action_plan)[0]
                              ]
                            ).map((item, index) => (
                              <TableCell key={index}>
                                {(
                                  item.charAt(0).toUpperCase() + item.slice(1)
                                ).replaceAll("_", " ")}
                              </TableCell>
                            ))}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {reportData.action_plan &&
                          Object.keys(reportData.action_plan).length !== 0 &&
                          Object.keys(reportData.action_plan).map(
                            (actionPlan, i) => (
                              <TableRow key={i}>
                                <TableCell>{actionPlan}</TableCell>
                                {Object.keys(
                                  reportData.action_plan[actionPlan]
                                ).map((actionPlanEntry) => (
                                  <TableCell>
                                    {
                                      reportData.action_plan[actionPlan][
                                        actionPlanEntry
                                      ]
                                    }
                                  </TableCell>
                                ))}
                              </TableRow>
                            )
                          )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                ) : (
                  <Typography style={{ marginTop: "2em" }}>
                    No data ..
                  </Typography>
                )}
              </Grid>
            </Grid>
          </TabPanel>
          <TabPanel value={activeTab} index={2}>
            <Grid
              container
              style={{ marginTop: 50, width: "100%" }}
              spacing={2}
            >
              <Grid item md={6} lg={12}>
                <Typography variant="h5" color="primary">
                  Actions
                </Typography>
                {actionsData ? (
                  <TableContainer style={{ width: "100%" }}>
                    <Table style={{ width: "100%" }}>
                      <TableHead>
                        <TableRow>
                          <TableCell>Action Plan ID</TableCell>
                          <TableCell>Action Description</TableCell>
                          {actionsData &&
                          Object.keys(actionsData).length !== 0 ? (
                            Object.keys(
                              actionsData[Object.keys(actionsData)[0]][
                                Object.keys(
                                  actionsData[Object.keys(actionsData)[0]]
                                )[0]
                              ]
                            ).map((label, index) => (
                              <TableCell key={index}>
                                {(
                                  label.charAt(0).toUpperCase() + label.slice(1)
                                ).replaceAll("_", " ")}
                              </TableCell>
                            ))
                          ) : (
                            <Typography style={{ marginTop: "2em" }}>
                              No data ..
                            </Typography>
                          )}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {actionsData &&
                        Object.keys(actionsData).length !== 0 ? (
                          Object.keys(actionsData).map((actionPlanId, index) =>
                            Object.keys(actionsData[actionPlanId]).map(
                              (action, index) => (
                                <TableRow>
                                  <TableCell>{actionPlanId}</TableCell>
                                  <TableCell>
                                    {
                                      Object.keys(actionsData[actionPlanId])[
                                        index
                                      ]
                                    }
                                  </TableCell>
                                  {Object.keys(
                                    actionsData[actionPlanId][action]
                                  ).map((item) => (
                                    <TableCell>
                                      {actionsData[actionPlanId][action][item]}
                                    </TableCell>
                                  ))}
                                </TableRow>
                              )
                            )
                          )
                        ) : (
                          <Typography style={{ marginTop: "2em" }}>
                            No data ..
                          </Typography>
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                ) : (
                  <Typography style={{ marginTop: "2em" }}>
                    No data ..
                  </Typography>
                )}
              </Grid>
            </Grid>
          </TabPanel>
          <TabPanel value={activeTab} index={3}>
            <Grid
              container
              style={{ marginTop: 50, width: "100%" }}
              spacing={2}
            >
              <Grid item md={6} lg={12}>
                <Typography variant="h5" color="primary">
                  Emission Inventories
                </Typography>
                {actionPlanInventory && actionPlanInventory.length !== 0 ? (
                  <TableContainer style={{ width: "100%" }}>
                    <Table style={{ width: "100%" }}>
                      <TableHead>
                        <TableRow>
                          {actionPlanInventory &&
                            actionPlanInventory.length !== 0 && (
                              <ReportTableHead data={actionPlanInventory} />
                            )}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {actionPlanInventory &&
                          actionPlanInventory.length !== 0 && (
                            <ReportTableBody data={actionPlanInventory} />
                          )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                ) : (
                  <Typography style={{ marginTop: "2em" }}>
                    No data ..
                  </Typography>
                )}
              </Grid>
            </Grid>
          </TabPanel>
          <TabPanel value={activeTab} index={4}>
            <Grid
              container
              style={{ marginTop: 50, width: "100%" }}
              spacing={2}
            >
              <Grid item md={6} lg={12}>
                <Typography variant="h5" color="primary">
                  Action Plan Goals
                </Typography>
                {actionPlanGoals && actionPlanGoals.length !== 0 ? (
                  <TableContainer style={{ width: "100%" }}>
                    <Table style={{ width: "100%" }}>
                      <TableHead>
                        <TableRow>
                          {actionPlanGoals && actionPlanGoals.length !== 0 && (
                            <ReportTableHead data={actionPlanGoals} />
                          )}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {actionPlanGoals && actionPlanGoals.length !== 0 && (
                          <ReportTableBody data={actionPlanGoals} />
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                ) : (
                  <Typography style={{ marginTop: "2em" }}>
                    No data ..
                  </Typography>
                )}
              </Grid>
            </Grid>
          </TabPanel>
          <TabPanel value={activeTab} index={5}>
            <Grid
              container
              style={{ marginTop: 50, width: "100%" }}
              spacing={2}
            >
              <Grid item md={6} lg={12}>
                <Typography variant="h5" color="primary">
                  Action Plan Hazards
                </Typography>
                {actionPlanHazards && actionPlanHazards.length !== 0 ? (
                  <TableContainer style={{ width: "100%" }}>
                    <Table style={{ width: "100%" }}>
                      <TableHead>
                        <TableRow>
                          {actionPlanHazards &&
                            actionPlanHazards.length !== 0 && (
                              <ReportTableHead data={actionPlanHazards} />
                            )}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {actionPlanHazards &&
                          actionPlanHazards.length !== 0 && (
                            <ReportTableBody data={actionPlanHazards} />
                          )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                ) : (
                  <Typography style={{ marginTop: "2em" }}>
                    No data ..
                  </Typography>
                )}
              </Grid>
            </Grid>
          </TabPanel>
          <TabPanel value={activeTab} index={6}>
            <Grid
              container
              style={{ marginTop: 50, width: "100%" }}
              spacing={2}
            >
              <Grid item md={6} lg={12}>
                <Typography variant="h5" color="primary">
                  Action Plan Sectors
                </Typography>
                {actionPlanSectors && actionPlanSectors.length !== 0 ? (
                  <TableContainer style={{ width: "100%" }}>
                    <Table style={{ width: "100%" }}>
                      <TableHead>
                        <TableRow>
                          {actionPlanSectors &&
                            actionPlanSectors.length !== 0 && (
                              <ReportTableHead data={actionPlanSectors} />
                            )}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {actionPlanSectors &&
                          actionPlanSectors.length !== 0 && (
                            <ReportTableBody data={actionPlanSectors} />
                          )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                ) : (
                  <Typography style={{ marginTop: "2em" }}>
                    No data ..
                  </Typography>
                )}
              </Grid>
            </Grid>
          </TabPanel>
          <TabPanel value={activeTab} index={7}>
            <Grid
              container
              style={{ marginTop: 50, width: "100%" }}
              spacing={2}
            >
              <Grid item md={6} lg={12}>
                <Typography variant="h5" color="primary">
                  Adaptation Sectors
                </Typography>
                {adaptationSectors && adaptationSectors.length !== 0 ? (
                  <TableContainer style={{ width: "100%" }}>
                    <Table style={{ width: "100%" }}>
                      <TableHead>
                        <TableRow>
                          {adaptationSectors &&
                            adaptationSectors.length !== 0 && (
                              <ReportTableHead data={adaptationSectors} />
                            )}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {adaptationSectors &&
                          adaptationSectors.length !== 0 && (
                            <ReportTableBody data={adaptationSectors} />
                          )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                ) : (
                  <Typography style={{ marginTop: "2em" }}>
                    No data ..
                  </Typography>
                )}
              </Grid>
            </Grid>
          </TabPanel>
          <TabPanel value={activeTab} index={8}>
            <Grid
              container
              style={{ marginTop: 50, width: "100%" }}
              spacing={2}
            >
              <Grid item md={6} lg={12}>
                <Typography variant="h5" color="primary">
                  Adaptation Scoreboard
                </Typography>
                {adaptationScoreboard && adaptationScoreboard.length !== 0 ? (
                  <TableContainer style={{ width: "100%" }}>
                    <Table style={{ width: "100%" }}>
                      <TableHead>
                        <TableRow>
                          {adaptationScoreboard &&
                            adaptationScoreboard.length !== 0 && (
                              <ReportTableHead data={adaptationScoreboard} />
                            )}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {adaptationScoreboard &&
                          adaptationScoreboard.length !== 0 && (
                            <ReportTableBody data={adaptationScoreboard} />
                          )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                ) : (
                  <Typography style={{ marginTop: "2em" }}>
                    No data ..
                  </Typography>
                )}
              </Grid>
            </Grid>
          </TabPanel>
        </Box>
      </div>
    </>
  );
}
