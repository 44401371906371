    /**
 * Header component is as the name states, the header of the page, containing a logo of the site which goes to the main page on click and 2 navigation links
 * One for main page and one for city insights which allows you to select a city which you want to go to it's page.
 */


import { React, useState, useContext } from 'react'
import { Grid, Paper, Link, Avatar, IconButton, Tooltip } from '@material-ui/core'  
import {URLS, GetRenders} from '../helper/util'
import AuthContext from '../context/auth'
import { decodeJWT } from '../helper/util'


export function Header() {
    const [open, setOpen] = useState(false)
    const { logoutUser } = useContext(AuthContext)

    // Decode Token
    let initial = 'U'
    const access_token = localStorage.getItem('access_token')
    const jwtDecoded = decodeJWT(access_token)
    if (jwtDecoded) initial = jwtDecoded.initial
    let org = jwtDecoded.organization;

    const styles={
        link: {
            color: '#222', 
            fontSize: GetRenders().XS ? 12 : 20, 
            marginRight: GetRenders().XS ? 5 : 10, 
            cursor: 'pointer'
        }
    }

    return (
        <Grid container style={{padding: 10, marginBottom: 20, width: '100%', backgroundColor: '#cad2d4', borderRadius: 0}} component={Paper} elevation={6}>
            <Grid item xs={12} sm={5} md={4} lg={6} style={{padding: GetRenders().XS ? 0 : 20, marginLeft: 50}}>
                <img src='/logo.svg' alt='matrycs-logo' onClick={() => window.location.href=URLS.HOME}/>
            </Grid>
            <Grid item xs={12} sm={5} md={6} lg={4} style={{ right: 60, marginTop: 20, padding: GetRenders().XS ? 0 : 20}}>
                <Link style={{color: '#222', fontSize: 20, marginRight: 10, cursor: 'pointer'}} onClick={() => window.location.href=URLS.HOME}> Main page</Link>
                <Link style={{color: '#222', fontSize: 20, marginRight: 10, cursor: 'pointer'}} onClick={() => window.location.href=URLS.HOME + '/cities/choose-city'}> Search city</Link>
                <Link style={{color: '#222', fontSize: 20, marginRight: 10, cursor: 'pointer', 
                            border: '1px solid #00897b', borderRadius: 20, padding: 15}} 
                    onClick={() => org ? window.location.href= URLS.HOME + "/city/" + org + '_2021' : null}> My city's insights</Link>
            </Grid>
            <Grid item xs={12} sm={1} md={1} lg={1} style={{ right: 10, marginTop: 18, padding: GetRenders().XS ? 0 : 20}}>
                <Tooltip title="Logout">
                    <IconButton
                        onClick={() => { logoutUser() }}
                        size="small"
                        sx={{ ml: 2 }}
                        aria-controls={open ? 'account-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? 'true' : undefined}
                    >
                        <Avatar>
                            <img
                                src={`https://api.dicebear.com/6.x/initials/svg?seed=${initial}&radius=50&backgroundColor=00897b,00acc1,039be5,1e88e5,3949ab,43a047,5e35b1,7cb342,8e24aa`}>
                            </img>
                        </Avatar>
                    </IconButton>
                </Tooltip>
            </Grid>
        </Grid>
    )

}