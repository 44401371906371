import { makeStyles } from '@material-ui/styles'

export const styles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '100px 0px'
  },
  button: {
    color: '#1a88c9 !important',
    textTransform: 'none !important'
  }
}))
