/**
 * App.js is the main page that loads the site, containing the route paths used in navigating the whole application
 */

import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { ThemeProvider, CssBaseline } from "@material-ui/core";
import { theme } from "./App.style.js";
import City from "./City";
import Dashboard from "./Dashboard";
import AdaptationAction from "./AdaptationAction";
import HazardConsequences from "./HazardConsequences";
import CompareCities from "./CompareCities";
import CompareYear from "./CompareYear";
import ChooseCity from "./ChooseCity";
import MadridCaseStudy from "./MadridCaseStudy";
import SignInPage from "./SignInPage/SignInpage";
import Report from "./Report";
import ReportPdf from "./ReportPdf";
import { AuthProvider } from "../context/auth";
import RequireAuthentication from "../helper/RequireAuthentication";
import RootLayout from "../components/RootLayout";
import RequireAuthorization from "../helper/RequireAutorization.jsx";

function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Router>
        <AuthProvider>
          <Routes>
            <Route element={<RootLayout />}>
              <Route
                exact
                path="/service31/city/:name"
                element={
                  <RequireAuthentication>
                      <City />
                  </RequireAuthentication>
                }
              />
              <Route
                exact
                path="/service31/report/:name"
                element={
                  <RequireAuthentication>
                    <RequireAuthorization>
                      <Report />
                    </RequireAuthorization>
                  </RequireAuthentication>
                }
              />
              <Route
                exact
                path="/service31/report/:name/pdf"
                element={
                  <RequireAuthentication>
                    <RequireAuthorization>
                      <ReportPdf />
                    </RequireAuthorization>
                  </RequireAuthentication>
                }
              />
              <Route
                exact
                path="/service31/case-studies/madrid"
                element={
                  <RequireAuthentication>
                    <RequireAuthorization>
                      <MadridCaseStudy />
                    </RequireAuthorization>
                  </RequireAuthentication>
                }
              />
              <Route
                exact
                path="/service31/cities/hazard-consequences"
                element={
                  <RequireAuthentication>
                    <HazardConsequences />
                  </RequireAuthentication>
                }
              />
              <Route
                exact
                path="/service31/cities/adaptation-plan"
                element={
                  <RequireAuthentication>
                    <AdaptationAction />
                  </RequireAuthentication>
                }
              />
              <Route
                exact
                path="/service31/cities/compare"
                element={
                  <RequireAuthentication>
                    <CompareCities />
                  </RequireAuthentication>
                }
              />
              <Route
                exact
                path="/service31/cities/compare-year"
                element={
                  <RequireAuthentication>
                    <CompareYear />
                  </RequireAuthentication>
                }
              />
              <Route
                exact
                path="/service31/cities/choose-city"
                element={
                  <RequireAuthentication>
                    <ChooseCity />
                  </RequireAuthentication>
                }
              />
              <Route exact path="/service31/login" element={<SignInPage />} />
              <Route
                exact
                path="/service31"
                element={
                  <RequireAuthentication>
                    <Dashboard />
                  </RequireAuthentication>
                }
              />
            </Route>
          </Routes>
        </AuthProvider>
      </Router>
    </ThemeProvider>
  );
}

export default App;
