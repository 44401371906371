import React from 'react';
import {
  Chart as ChartJS,
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Tooltip,
  LineController,
  BarController,
} from 'chart.js';
import { Chart } from 'react-chartjs-2';
import { renderName } from "../helper/util";

ChartJS.register(
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Tooltip,
  LineController,
  BarController
);

export default function BarLineComboChart(props) {
  const labels = props.rankings.map(item => renderName(item.name))

  let datasets = [
    {
      type: 'bar',
      label: 'City Rankings',
      backgroundColor: 'rgb(75, 192, 192)',
      data: props.rankings.map(item => item.value),
      borderColor: 'white',
      borderWidth: 2,
    },
  ]

  if (props.sameClusterAvgRankings) {
    datasets.unshift({
      type: 'line',
      label: 'Average Rankings (Same Cluster)',
      borderColor: 'rgb(104, 231, 82)',
      borderWidth: 2,
      fill: false,
      data: props.sameClusterAvgRankings.map(item => item.value),
    })
  }

  if (props.avgRankings) {
    datasets.unshift(    {
      type: 'line',
      label: 'Average Rankings (Total)',
      borderColor: 'rgb(255, 99, 132)',
      borderWidth: 2,
      fill: false,
      data: props.avgRankings.map(item => item.value),
    })
  }

  const data = {
    labels,
    datasets: datasets
  };

  const options = {
    // Disable Click event
    events: ['mousemove', 'mouseout', 'touchstart', 'touchmove'],
    scales: {
      y: {
        min: 0,
        max: 1
      }
    }
  };

  return <Chart type='bar' data={data} options={options} />;
}
