import React from 'react';
import {
  Chart as ChartJS,
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend,
} from 'chart.js';
import { Radar } from 'react-chartjs-2';
import { renderName } from "../helper/util";

ChartJS.register(
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend,
);


export default function Spidergraph(props) {

  const data = {
    labels: [ ...Object.keys(props.data).map(key => renderName(key))],
    datasets: [
      {
        label: "City's Ranking",
        data: [ ...Object.values(props.data) ],
        backgroundColor: 'rgba(112, 160, 255, 0.2)',
        borderColor: 'rgba(97, 255, 58, 1)',
        borderWidth: 1,
      },
    ],
  };

  const options = {
    responsive: true,
    scale: {     
      min: 0,
      max: 1
    }
  };

  return <Radar data={data} options={options}/>;
}
