/**
 * Map component displays a leaflet map with specific height, it zooms in and out and there are markers displayed based on the data passed as prop(array of objects,
 * cities in particular).Each of the data values gets a marker with a color from Color list(options.js) which goes to the specific city when clicked.
 * Data objects(cities) have each own longitude and latitude which are needed for the map to display the position and using the cord1 and cord2 props, 
 * the way the latitude and longitude are declared every time can be changed to lng and lat as the map needs(default value set to lat and lng already so if the user wants
 * to specify another 'name' for their data coordinates, it can be passed as prop)
 */


import { MapContainer, TileLayer, Marker, Tooltip } from 'react-leaflet'
import {COLORS} from '../helper/options'
import L from 'leaflet'
import 'leaflet/dist/leaflet.css'
import 'leaflet-defaulticon-compatibility/dist/leaflet-defaulticon-compatibility.css'
import 'leaflet-defaulticon-compatibility';
import "leaflet-extra-markers/dist/css/leaflet.extra-markers.min.css";
import "leaflet-extra-markers/dist/js/leaflet.extra-markers.js";
import './Map.scss'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'


export function Map({ data, details, detailsAttribute1, detailsAttribute2, cord1='lat', cord2='lng', userData, loading = false }) {
  const [ errorMessage, setErrorMessage ] = useState('')
  const navigate = useNavigate()

  const getIcon = (color) => {
    return (
      L.ExtraMarkers.icon({
        icon: 'fa-marker',
        markerColor: color,
        shape: 'penta',
        prefix: 'fa',
        svg: true,
      })
    )
  }

  const getNoClickIcon = (color) => {
    return (
      L.ExtraMarkers.icon({
        icon: 'fa-marker',
        markerColor: color,
        shape: 'penta',
        prefix: 'fa',
        svg: true,
        className: 'noclick',
      })
    )
  }

  const handleNotAuthorizedClick = (e) => {
    setErrorMessage(`You can only access ${userData.organization}`)
  }

  return (
    <>
    <div style={{color: 'rgb(255, 0, 0)', marginBottom: '.6em', textAlign: 'center', fontSize: '16px', pointerEvents: loading ? 'none' : 'all' }}>{ errorMessage }</div>
    <MapContainer center={[53, 9]} zoom={4} scrollWheelZoom={true} style={{height: "600px"}}>
        <TileLayer attributionControl={false}
            url="https://api.mapbox.com/styles/v1/mapbox/light-v10/tiles/256/{z}/{x}/{y}@2x?access_token=pk.eyJ1Ijoicm9va2llcm9vayIsImEiOiJjbDA4MnVhbnQwNTFjM2NwOXBycHU2ZHNrIn0.64Yv7XFZumGxYKAMCvm8nA"
            // attribution="Map data &copy; <a href=&quot;https://www.openstreetmap.org/&quot;>OpenStreetMap</a> contributors, <a href=&quot;https://creativecommons.org/licenses/by-sa/2.0/&quot;>CC-BY-SA</a>, Imagery &copy; <a href=&quot;https://www.mapbox.com/&quot;>Mapbox</a>"
        />
        {Object.entries(data).length !== 0 && Object.entries(data).map(([key, value], i) =>
          <Marker key={i} position={[value[cord1], value[cord2]]} animate={true}
            icon={ getIcon(COLORS[i]) } 
            eventHandlers={{
              click: () => { !loading && navigate("/service31/city/" + key) }
            }}>
              <Tooltip>
                <h3>
                  {key.split('_')[0]}
                </h3>
                {details && Object.entries(details).length !== 0 && Object.entries(details).map(([key, value], i) => 
                  <ul key={i}>
                    <li> {value[detailsAttribute1]}</li>
                    {value[detailsAttribute2]}
                  </ul>
                )}
              </Tooltip>
          </Marker>
        )}
    </MapContainer>
    </>
  )
}

