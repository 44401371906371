export const HAZARDTYPES = [
    'Extreme Precipitation > Rain storm',
    'Extreme Precipitation > Monsoon',
    'Extreme Precipitation > Heavy snow',
    'Extreme Precipitation > Fog',
    'Extreme Precipitation > Hail',
    'Storm and wind > Severe wind',
    'Storm and wind > Tornado',
    'Storm and wind > Cyclone (Hurricane / Typhoon)',
    'Storm and wind > Extra tropical storm',
    'Storm and wind > Tropical storm',
    'Storm and wind > Storm surge',
    'Storm and wind > Lightning / thunderstorm',
    'Extreme cold temperature > Extreme winter conditions',
    'Extreme cold temperature > Cold wave',
    'Extreme cold temperature > Extreme cold days',
    'Extreme hot temperature > Heat wave',
    'Extreme hot temperature > Extreme hot days',
    'Water Scarcity > Drought',
    'Wild fire > Forest fire',
    'Wild fire > Land fire',
    'Flood and sea level rise > Flash / surface flood',
    'Flood and sea level rise > River flood',
    'Flood and sea level rise > Coastal flood',
    'Flood and sea level rise > Groundwater flood',
    'Flood and sea level rise > Permanent inundation',
    'Chemical change > Salt water intrusion',
    'Chemical change > Ocean acidification',
    'Chemical change > Atmospheric CO2 concentrations',
    'Mass movement > Landslide',
    'Mass movement > Avalanche',
    'Mass movement > Rock fall',
    'Mass movement > Subsidence',
    'Biological hazards > Water-borne disease',
    'Mass movement > Vector-borne disease',
    'Mass movement > Air-borne disease',
    'Mass movement > Insect infestation',
]

export const PROBABILITY = [
    'Low', 
    'Medium',
    'High'
]

export const MAGNITUDE = [
    'Low', 
    'Medium',
    'High'
]

export const ACTION_STATUS = [
    'Scoping',
    'Pre-feasibility study',
    'Pre-implementation',
    'Implementation',
    'Implementation complete but not in operation',
    'Operation',
    'Monitoring and reporting',
]

export const ACTION_FINANCE_STATUS = [
    'Pre-feasibility study status',
    'Feasibility undertaken',
    'Feasibility finalized and finance partially secured',
    'Finance secured'
]

export const ASSET = [
    'Energy',
    'Water supply & sanitation',
    'Transport',
    'Food & agriculture',
    'Waste management',
    'Information & communications technology',
    'Environment, biodiversity, forestry',
    'Industrial',
    'Commercial',
    'Residential',
    'Education',
    'Tourism',
    'Public health',
    'Society / community & culture',
    'Law & order',
    'Emergency services',
    'Land use planning',
    'Other'
]

export const POPULATION = [
    'Women & girls',
    'Children & youth',
    'Elderly',
    'Indigenous population',
    'Marginalized groups',
    'Persons with disabilities',
    'Persons with chronic diseases',
    'Low-income households',
    'Unemployed persons',
    'Persons living in sub-standard housing',
    'Other'
]

export const SOCIAL_IMPACT = [
    'Fluctuating socio-economic conditions',
    'Increased incidence and prevalence of disease and illness',
    'Increased demand for public services',
    'Increased demand for healthcare services',
    'Increased risk to already vulnerable populations',
    'Increased conflict and/or crime',
    'Increased resource demand',
    'Loss of traditional jobs',
    'Migration from rural areas to cities',
    'Population displacement',
    'Loss of tax base to support public services',
    'Other'
]

export const COLORS = [
    '#93c849',
    '#2ab683',
    '#1a88c9',
    '#FF8042',
    '#d577f9',
    '#f8ff7a',
    '#745cd6',
    '#c4b2f4',
    '#ffa411',
    '#46d69a',
    '#e2fca9',
    '#ea4698', 
    '#1eb299', 
    '#2a5096', 
    '#6dd1e0', 
    '#8db9dd', 
    '#f700d6', 
    '#088c13', 
    '#b3d868', 
    '#f47458', 
    '#db7283', 
    '#e0ba74', 
    '#1140c1', 
    '#e176e2', 
    '#2e68f2', 
    '#452291', 
    '#e8c278', 
    '#f7c0b7', 
    '#c9a3f7', 
    '#002ec6', 
    '#a53309', 
    '#5df7ea', 
    '#bcce31', 
    '#b77bf7', 
    '#1487bc', 
    '#a6b7ea', 
    '#ffc928', 
    '#7fed36', 
    '#ff0cee', 
    '#f2b0ed', 
    '#f7e06c', 
    '#5e57ba', 
    '#69db80', 
    '#c6c003', 
    '#e1f788', 
    '#38bcb8', 
    '#d822ae', 
    '#ffdccc', 
    '#017f46', 
    '#edc77b', 
    '#f2d95e', 
    '#fcc067', 
    '#1a8fba', 
    '#5105c1', 
    '#c91832', 
    '#deea6e', 
    '#67ef9d', 
    '#bed868', 
    '#ba3f2c', 
    '#11ff3d', 
    '#f9b2a7', 
    '#e5d934', 
    '#d33b8f', 
    '#b44cff', 
    '#bff1ff', 
    '#99fbff', 
    '#9586e0', 
    '#d8b415', 
    '#a7cc3b', 
    '#b3c132', 
    '#a5ffa7', 
    '#ffc599', 
    '#8e6dc6', 
    '#30ad27', 
    '#8de57e', 
    '#ff6026', 
    '#e0b50b', 
    '#c56ddb', 
    '#1845bf', 
    '#6b44ba', 
    '#d8b62b', 
    '#e8a8ed', 
    '#db1cbe', 
    '#45c9f9', 
    '#74d0d3', 
    '#0f8b9e', 
    '#e212b2', 
    '#86f46e', 
    '#6168d3', 
    '#058391', 
    '#0c2575', 
    '#e8683a', 
    '#ff91f0', 
    '#d2ccff', 
    '#9fce40', 
    '#e86899', 
    '#aaffe4', 
    '#6782f7', 
    '#1558b5', 
    '#1339f4', 
    '#8e5cf9', 
    '#9faa1d', 
    '#aa26d6', 
    '#a3d14f',
    '#d8b62e', 
    '#e8a8fg', 
    '#db1ccf', 
    '#45c9f1', 
    '#74d0d7', 
    '#0f8b6a', 
    '#e212d9', 
    '#86f41a', 
    '#6168e1', 
    '#058391', 
    '#0c2512', 
    '#e86812', 
    '#ff9112', 
    '#d2cc12', 
    '#9fce12', 
    '#e86812', 
    '#aaff12', 
    '#678212', 
    '#155812', 
    '#133912', 
    '#8e5c12', 
    '#9faa12', 
    '#aa2612', 
    '#a3d123',
    '#deea21', 
    '#67ef21', 
    '#bed821', 
    '#ba3f21', 
    '#11ff21', 
    '#f9b221', 
    '#e5d921', 
    '#d33b21', 
    '#b44c21', 
    '#bff121', 
    '#99fb21', 
    '#958621', 
    '#d8b421', 
    '#a7cc21', 
    '#b3c121', 
    '#a5ff21', 
    '#ffc521', 
    '#8e6d21', 
    '#30ad21', 
    '#8de521', 
    '#21ea6e', 
    '#21ef9d', 
    '#21d868', 
    '#213f2c', 
    '#21ff3d', 
    '#21b2a7', 
    '#21d934', 
    '#213b8f', 
    '#214cff', 
    '#21f1ff', 
    '#21fbff', 
    '#2186e0', 
    '#21b415', 
    '#21cc3b', 
    '#21c132', 
    '#21ffa7', 
    '#21c599', 
    '#216dc6', 
    '#21ad27', 
    '#21e57e', 
]

export const COUNTRY_CODES = {
    'Belgium':	'BE',
    'Greece': 'GR', 
    'Lithuania': 'LT', 
    'Portugal':  'PT',
    'Bulgaria': 'BG',
    'Spain': 'ES',
    'Luxembourg': 'LU',
    'Romania': 'RO',
    'Czechia':	'CZ',
    'France':	'FR',
    'Hungary':	'HU',
    'Slovenia':	'SI',
    'Denmark': 'DK',
    'Croatia': 'HR',
    'Malta': 'MT',
    'Slovakia': 'SK',
    'Germany': 'DE',
    'Italy': 'IT',
    'Netherlands': 'NL',
    'Finland': 'FI',
    'Estonia': 'EE',
    'Cyprus': 'CY',
    'Austria': 'AT',
	'Sweden': 'SE',
    'Ireland': 'IE',
	'Latvia': 'LV',
    'Poland': 'PL',
    'United Kingdom': 'GB',
    'Scotland': 'GB_SCT',
    'Wales': 'GB_WLS',
    'Iceland': 'IS',
    'Norway': 'NO',
    'Liechtenstein': 'LI',
    'Switzerland': 'CH',
    'Montenegro': 'ME',
    'North Macedonia': 'MK',
    'Albania': 'AL',
    'Serbia': 'RS',
    'Turkey': 'TR',
    'Russian Federation': 'RU'
}
