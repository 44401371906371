import { useContext } from "react"
import AuthContext from "../context/auth"
import { useParams, Navigate, useHref } from "react-router-dom"

const RequireAuthorization = ({ children }) => {
    /**
     * This is only suited for Pages that require City Name in the URL to access them
     * and the edge case of Madrid Case Study
     * ex. https://host/city/City%20of%20Helsinki_2021
     * or https://host//case-studies/madrid
     */
    const { userData } = useContext(AuthContext)
    const location = useHref()
    const params = useParams()
    const navigateHome = <Navigate to={'/service31'} replace />

    // Handle Madrid Case Study URL
    if (location === '/service31/case-studies/madrid' || location === '/service31/case-studies/madrid/') {
        if ( userData.organization !== 'Municipality of Madrid' && !userData.isAdmin ) {
            return navigateHome
        }

        return children
    }

    if ( !params.name.includes(userData.organization) && !userData.isAdmin ) {
        return navigateHome
    }

    return children
}

export default RequireAuthorization