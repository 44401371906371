import { makeStyles } from '@material-ui/styles'

export const styles = makeStyles({
  root: {
    backgroundColor: '#1a88c9 !important',
    border: 0,
    borderRadius: 3,
    color: 'white',
    height: 35
  }
})
