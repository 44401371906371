/**
 * Choose city is used on city insights, diplaying a dropdown to select one of all the cities fetched from backend and go to it's page
 */


import { React,useState,useEffect } from 'react'
import { Grid,TextField,Typography } from '@material-ui/core'
import {Autocomplete} from '@material-ui/lab'
import Loading from './../components/Loading'
import axiosInstance from '../helper/axios'
import { useNavigate } from 'react-router-dom'


export default function ChooseCity() {
    const [cities, setCities] = useState({})
    const [loading, setLoading] = useState(true)
    const navigate = useNavigate()

    useEffect(() => {
        async function fetch() { 
            const r = await axiosInstance.get('/coordinates/all/' )
            var converted = []
            Object.entries(r.data).map(([key, value],i) => {
                converted = [...converted, {label: key.split('_')[0], city_id: value.uid}]       
            })
            setCities(converted)
            setLoading(false)
        }
        fetch()
    }, [])

    return (
            <Grid container>
                <Grid item md={10} lg={10} style={{marginLeft:90}}>
                    <Typography variant='h5' color='primary'>
                        Select city
                    </Typography>
                    <br/>
                    {!loading?<Autocomplete options={cities} getOptionLabel={option => option.label} id="disable-clearable" disableClearable
                        renderInput={(params) => <TextField {...params} label="Choose cities" variant="outlined"/>}
                        onChange={(_event, item) => { navigate("/service31/city/" + item.label + '_2021')}}/>:<Loading/>}
                    
                </Grid>
            </Grid>
    )
}