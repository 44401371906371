import axios from "axios";
import { URLS } from "./util";

const axiosInstance = axios.create({
  baseURL: URLS.SERVER,
  timeout: 25000,
  headers: {
    Authorization: localStorage.getItem("access_token")
      ? "JWT " + localStorage.getItem("access_token")
      : null,
    "Content-Type": "application/json",
    Accept: "application/json",
  },
});

axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  async function (error) {
    const originalRequest = error.config;

    if (typeof error.response === "undefined") {
      // alert(
      // 	'A server/network error occurred. ' +
      // 		'Looks like CORS might be the problem. ' +
      // 		'Sorry about this - we will get it fixed shortly.'
      // );
      return Promise.reject(error);
    }

    if (
      error.response.status === 401 &&
      originalRequest.url === URLS.SERVER + "/auth/refresh/"
    ) {
      window.location.href = "/login/";
      return Promise.reject(error);
    }

    if (
      error.response.data.code === "token_not_valid" &&
      error.response.status === 401 &&
      error.response.statusText === "Unauthorized"
    ) {
      const refreshToken = localStorage.getItem("refresh_token");

      if (refreshToken) {
        const tokenParts = JSON.parse(atob(refreshToken.split(".")[1]));

        // exp date in token is expressed in seconds, while now() returns milliseconds:
        const now = Math.ceil(Date.now() / 1000);

        if (tokenParts.exp > now) {
          return axios
            .post(URLS.SERVER + "/auth/refresh/", { refresh: refreshToken })
            .then((response) => {
              localStorage.setItem("access_token", response.data.access);
              localStorage.setItem("refresh_token", response.data.refresh);

              axiosInstance.defaults.headers["Authorization"] =
                "JWT " + response.data.access;
              originalRequest.headers["Authorization"] =
                "JWT " + response.data.access;

              return axiosInstance(originalRequest);
            })
            .catch((err) => {
              console.log(err.response.data);
            });
        } else {
          console.log("Refresh token is expired");
          localStorage.removeItem('access_token')
          localStorage.setItem('refresh_token', 'expired')
          window.location.href = '/login/';
        }
      } else {
        console.log("Refresh token not available.");
        localStorage.removeItem('access_token')
        localStorage.removeItem('refresh_token')
        window.location.href = "/login/";
      }
    }

    return Promise.reject(error);
  }
);

export default axiosInstance;
