/**
 * StatWithIcon component is used to display a fixed style and size grid with an small image(fixed width) which is passed in as the icon prop and a typography text
 * with the prop stat
 */


import { React } from 'react'
import { Grid, Paper, Typography } from '@material-ui/core'  


export function StatWithIcon({stat, icon}) {    

    return (
        <Grid item xs={12} sm={5} md={5} lg={5} component={Paper} elevation={6} style={{borderRadius: 20, padding: 20, margin: '20px 20px 0 0', height: 150}}>
            <img src={icon} alt='cities' style={{width: 50}}/>
            <Typography>{stat}</Typography>
        </Grid>
    )

}