import React from 'react'
import { useStyles } from './InputField.styles'
import { TextField } from '@mui/material'

export function InputField ({ label, helperText, set, type = 'text', style = [], required = false, onChange, name }) {
  const classes = useStyles()

  return (
    <TextField
      variant='outlined'
      size='small'
      helperText={helperText}
      label={label}
      onBlur={set}
      type={type}
      required={required}
      className={[classes.root, style]}
      autoFocus
      onChange={onChange}
      name={name}
    />
  )
}
