export const buildings = {
    'Biblioteca Pío Baroja': {
        'id': 20, 
        'longitude': -3.70767653, 
        'latitude': 40.40025805, 
        'consumption': {'2020 3': '807.6 m3', '2020 4': '718.9 m3', '2020 5': '592.9 m3', '2020 6': '27.4 m3', '2020 7': '0 m3', '2020 8': '0 m3', '2020 9': '0 m3', '2020 10': '624.9 m3', '2020 11': '1246.8 m3', '2020 12': '1838.2 m3', '2021 1': '2171.6 m3', '2021 2': '1643.2 m3', '2021 3': '1829.1 m3'}}, 
    'CEIP Claudio Moyano': {
        'id': 49, 
        'longitude': -3.708818, 
        'latitude': 40.40438988, 
        'consumption': {'2020 3': '830.4 m3', '2020 4': '0 m3', '2020 5': '0 m3', '2020 6': '0 m3', '2020 7': '0 m3', '2020 8': '0 m3', '2020 9': '0.2 m3', '2020 10': '1306.8 m3', '2020 11': '2835.2 m3', '2020 12': '2774.5 m3', '2021 1': '4575.2 m3', '2021 2': '3285.3 m3', '2021 3': '2865.1 m3'}}, 
    'CEIP Puerto Rico': {
        'id': 108, 
        'longitude': -3.7190190000000003, 
        'latitude': 40.40368419, 
        'consumption': {'2020 3': '0 m3', '2020 4': '0 m3', '2020 5': '0 m3', '2020 6': '0 m3', '2020 7': '0 m3', '2020 8': '0 m3', '2020 9': '0 m3', '2020 10': '0 m3', '2020 11': '859.5 m3', '2020 12': '2258.1 m3', '2021 1': '5418.4 m3', '2021 2': '2597.8 m3', '2021 3': '2590 m3'}}, 
    'CEIP Ntra Sra de la Paloma': {
        'id': 116, 
        'longitude': -3.711375, 
        'latitude': 40.40411096, 
        'consumption': {'2020 3': '1504.7 m3', '2020 4': '403.8 m3', '2020 5': '369.2 m3', '2020 6': '287 m3', '2020 7': '194.5 m3', '2020 8': '160.5 m3', '2020 9': '255.8 m3', '2020 10': '2727.8 m3', '2020 11': '4341 m3', '2020 12': '3604.3 m3', '2021 1': '5665.9 m3', '2021 2': '4473.7 m3', '2021 3': '4353.4 m3'}}, 
    'CEIP Ntra Sra Fuencisla': {
        'id': 167, 
        'longitude': -3.698065, 
        'latitude': 40.40380294, 
        'consumption': {'2020 3': '2237.8 m3', '2020 4': '1420.3 m3', '2020 5': '0 m3', '2020 6': '0 m3', '2020 7': '0 m3', '2020 8': '0 m3', '2020 9': '0.7 m3', '2020 10': '994.9 m3', '2020 11': '2122.1 m3', '2020 12': '2610.5 m3', '2021 1': '3669.5 m3', '2021 2': '2153.9 m3', '2021 3': '2394.6 m3'}}, 
    'CEIP Vázquez de Mella': {
        'id': 177, 
        'longitude': -3.714036047, 
        'latitude': 40.41210162, 
        'consumption': {'2020 3': '75.2 m3', '2020 4': '0 m3', '2020 5': '0 m3', '2020 6': '3.5 m3', '2020 7': '0 m3', '2020 8': '0 m3', '2020 9': '148.2 m3', '2020 10': '214.8 m3', '2020 11': '196.4 m3', '2020 12': '149.4 m3', '2021 1': '90.9 m3', '2021 2': '193.7 m3', '2021 3': '196 m3'}}, 
    'CEIP Pi i Margall': {
        'id': 209, 
        'longitude': -3.704610765, 
        'latitude': 40.42683271, 
        'consumption': {'2020 3': '1687.5 m3', '2020 4': '628.7 m3', '2020 5': '0 m3', '2020 6': '0 m3', '2020 7': '0 m3', '2020 8': '0 m3', '2020 9': '0.5 m3', '2020 10': '818 m3', '2020 11': '1717 m3', '2020 12': '2365.4 m3', '2021 1': '2420 m3', '2021 2': '2707.3 m3', '2021 3': '2714.6 m3'}}, 
    'CEIP Residencia San Ildefonso': {
        'id': 247, 
        'longitude': -3.712126315, 
        'latitude': 40.41293485, 
        'consumption': {'2020 3': '3885.1 m3', '2020 4': '2597.2 m3', '2020 5': '332.2 m3', '2020 6': '0 m3', '2020 7': '0 m3', '2020 8': '0 m3', '2020 9': '1291.5 m3', '2020 10': '3043.9 m3', '2020 11': '7200.4 m3', '2020 12': '6776.5 m3', '2021 1': '10429 m3', '2021 2': '7997.5 m3', '2021 3': '7375.1 m3'}}, 
    'CEIP Concepción Arenal': {
        'id': 278, 
        'longitude': -3.715043, 
        'latitude': 40.40397546, 
        'consumption': {'2020 3': '0 m3', '2020 4': '0 m3', '2020 5': '0 m3', '2020 6': '0 m3', '2020 7': '0 m3', '2020 8': '0 m3', '2020 9': '0 m3', '2020 10': '0 m3', '2020 11': '1489.9 m3', '2020 12': '5176.6 m3', '2021 1': '11817.7 m3', '2021 2': '6595.3 m3', '2021 3': '5737.6 m3'}}, 
    'CC La Vaguada': {
        'id': 288, 
        'longitude': -3.709165156, 
        'latitude': 40.47901716, 
        'consumption': {'2020 3': '0 m3', '2020 4': '0 m3', '2020 5': '0 m3', '2020 6': '0 m3', '2020 7': '0 m3', '2020 8': '0 m3', '2020 9': '0 m3', '2020 10': '0 m3', '2020 11': '0 m3', '2020 12': '0 m3', '2021 1': '0 m3', '2021 2': '0 m3', '2021 3': '0 m3'}}, 
    'CEIP Pradolongo': {
        'id': 304, 
        'longitude': -3.7158309999999997, 
        'latitude': 40.40378942, 
        'consumption': {'2020 3': '0 m3', '2020 4': '0 m3', '2020 5': '0 m3', '2020 6': '0 m3', '2020 7': '0 m3', '2020 8': '0 m3', '2020 9': '0 m3', '2020 10': '0 m3', '2020 11': '40.1 m3', '2020 12': '136.9 m3', '2021 1': 'NO DATA m3', '2021 2': 'NO DATA m3', '2021 3': '84.7 m3'}}, 
    'Biblioteca La Elipa - Islas Filipinas': {
        'id': 509, 
        'longitude': -3.6518230000000003, 
        'latitude': 40.40423435, 
        'consumption': {'2020 3': '1376.21055 m3', '2020 4': '834.6624 m3', '2020 5': '332.41494 m3', '2020 6': '0 m3', '2020 7': '0 m3', '2020 8': '0 m3', '2020 9': '0 m3', '2020 10': '1000.24353 m3', '2020 11': '1769.05443 m3', '2020 12': '2117.87862 m3', '2021 1': '2271.15174 m3', '2021 2': '1913.45583 m3', '2021 3': '2177.20503 m3'}}, 
    'JMD Latina': {
        'id': 547, 
        'longitude': -3.7642101, 
        'latitude': 40.40385055, 
        'consumption': {'2021 3': '4690 kvAr'}},
    'CEIP Isaac Peral': {
        'id': 582, 
        'longitude': -3.73085, 
        'latitude': 40.403866, 
        'consumption': {'2020 3': '1156.8 m3', '2020 4': '0 m3', '2020 5': '0.1 m3', '2020 6': '0 m3', '2020 7': '0 m3', '2020 8': '0 m3', '2020 9': '0 m3', '2020 10': '0 m3', '2020 11': '1508.9 m3', '2020 12': 'NO DATA m3', '2021 1': '4233.3 m3', '2021 2': '4472.9 m3', '2021 3': '4227.2 m3'}}, 
    'CDM Aluche': {
        'id': 631, 
        'longitude': -3.771668673, 
        'latitude': 40.3830956, 
        'consumption': {'2020 3': '260.7 m3', '2020 4': '0 m3', '2020 5': '0 m3', '2020 6': '0 m3', '2020 7': '0 m3', '2020 8': '0 m3', '2020 9': '0 m3', '2020 10': '0 m3', '2020 11': '0 m3', '2020 12': '0 m3', '2021 1': '0 m3', '2021 2': '0 m3', '2021 3': '6.3 m3'}}, 
    'CEIP Bolivia': {
        'id': 658, 
        'longitude': -3.7498699999999996, 
        'latitude': 40.404076, 
        'consumption': {'2020 3': '84.2 m3', '2020 4': '75.9 m3', '2020 5': '0.1 m3', '2020 6': '0 m3', '2020 7': 'NO DATA m3', '2020 8': 'NO DATA m3', '2020 9': 'NO DATA m3', '2020 10': '17.4 m3', '2020 11': '228.9 m3', '2020 12': '264.7 m3', '2021 1': '539.3 m3', '2021 2': '360 m3', '2021 3': '327.3 m3'}}, 
    'Biblioteca Pablo Neruda': {
        'id': 702, 
        'longitude': -3.641747, 
        'latitude': 40.40429437, 
        'consumption': {'2020 3': '856296 m3', '2020 4': '0 m3', '2020 5': '0 m3', '2020 6': '0 m3', '2020 7': '0 m3', '2020 8': '0 m3', '2020 9': '0 m3', '2020 10': '1350792 m3', '2020 11': '2280672 m3', '2020 12': '3330648 m3', '2021 1': '2295576 m3', '2021 2': '50.4 m3', '2021 3': '2632392 m3'}}, 
    'JMD Centro': {
        'id': 772, 
        'longitude': -3.71073693, 
        'latitude': 40.41555721, 
        'consumption': {'2020 3': 'NO DATA m3', '2020 4': '0 m3', '2020 5': '0 m3', '2020 6': '0 m3', '2020 7': '0 m3', '2020 8': '0 m3', '2020 9': '0 m3', '2020 10': 'NO DATA m3', '2020 11': '2444.1 m3', '2020 12': '3754.2 m3', '2021 1': '4259.7 m3', '2021 2': '3327.6 m3', '2021 3': '3183.1 m3'}}, 
    'Biblioteca Iván de Vargas': {
        'id': 782, 
        'longitude': -3.7096459999999998, 
        'latitude': 40.40414041, 
        'consumption': {'2021 3': '2939 kvAr'}}, 
    'CDM Orcasitas': {
        'id': 844, 
        'longitude': -3.712767363, 
        'latitude': 40.37584296, 
        'consumption': {'2020 3': '1542 kVAr', '2020 4': '5126 kVAr', '2020 5': '5174 kVAr', '2020 6': '3003 kVAr', '2020 7': '211 kVAr', '2020 8': '128 kVAr', '2020 9': '510 kVAr', '2020 10': '549 kVAr', '2020 11': '703 kVAr', '2020 12': '406 kVAr', '2021 1': '548 kVAr', '2021 2': '678 kVAr', '2021 3': '771 kVAr'}}, 
    'Biblioteca Miguel Delibes': {
        'id': 850, 
        'longitude': -3.653058708, 
        'latitude': 40.40974219, 
        'consumption': {'2020 3': '1481.7 m3', '2020 4': '0 m3', '2020 5': '0 m3', '2020 6': '0 m3', '2020 7': '0 m3', '2020 8': '0 m3', '2020 9': '0 m3', '2020 10': '1285.5 m3', '2020 11': '2340.4 m3', '2020 12': '4509.2 m3', '2021 1': '6818.2 m3', '2021 2': '6801.6 m3', '2021 3': '4828.6 m3'}}, 
    'CC/BPM Gloria Fuertes': {
        'id': 853, 
        'longitude': -3.593721, 
        'latitude': 40.40460597, 
        'consumption': {'2020 3': '624.54 m3', '2020 4': '178.32 m3', '2020 5': '0 m3', '2020 6': '0 m3', '2020 7': '0 m3', '2020 8': '2.46 m3', '2020 9': '0 m3', '2020 10': '999.6 m3', '2020 11': '1370.34 m3', '2020 12': '2165.22 m3', '2021 1': '730.2 m3', '2021 2': 'NO DATA m3', '2021 3': '840.66 m3'}}, 
    'CC Conde Duque': {
        'id': 906, 
        'longitude': -3.7106806039999998, 
        'latitude': 40.42729129999999, 
        'consumption': {'2020 3': '0 kVAr', '2020 4': '441 kVAr', '2020 5': '12330 kVAr', '2020 6': '47458 kVAr', '2020 7': '54750 kVAr', '2020 8': '57252 kVAr', '2020 9': '60884 kVAr', '2020 10': '54547 kVAr', '2020 11': 'NO DATA kVAr', '2020 12': '42162 kVAr', '2021 1': '52407 kVAr', '2021 2': '48246 kVAr', '2021 3': '55645 kVAr'}}, 
    'Biblioteca Mario Vargas Llosa': {
        'id': 941, 
        'longitude': -3.699376, 
        'latitude': 40.404267700000005, 
        'consumption': {'2020 3': '1190 kVAr', '2020 4': '426 kVAr', '2020 5': '325 kVAr', '2020 6': '7146 kVAr', '2020 7': '7528 kVAr', '2020 8': '6958 kVAr', '2020 9': '3767 kVAr', '2020 10': '4502 kVAr', '2020 11': '9941 kVAr', '2020 12': '9020 kVAr', '2021 1': '8473 kVAr', '2021 2': '7097 kVAr', '2021 3': '5887 kVAr'}}, 
    'CEIP Madroño': {
        'id': 1028, 
        'longitude': -3.6553760000000004, 
        'latitude': 40.40402228, 
        'consumption': {'2020 3': '1507.7 m3', '2020 4': '0.8 m3', '2020 5': '0.2 m3', '2020 6': '0 m3', '2020 7': '0 m3', '2020 8': '0.1 m3', '2020 9': '27.6 m3', '2020 10': '1707.6 m3', '2020 11': '2673 m3', '2020 12': '3696.4 m3', '2021 1': '3135.9 m3', '2021 2': '3326 m3', '2021 3': '0.1 m3'}}, 
    'Centro de Mayores San Francisco': {
        'id': 1033, 
        'longitude': -3.714990914, 
        'latitude': 40.41111869, 
        'consumption': {'2020 3': '0 m3', '2020 4': '0 m3', '2020 5': '0 m3', '2020 6': '0 m3', '2020 7': '0 m3', '2020 8': '0 m3', '2020 9': '0 m3', '2020 10': '0 m3', '2020 11': '0 m3', '2020 12': '0 m3', '2021 1': '0 m3', '2021 2': '0 m3', '2021 3': '0 m3'}}, 
    'Planetario de Madrid': {
        'id': 1046,
        'longitude': -3.6843589999999997, 
        'latitude': 40.40392678, 
        'consumption': {'2021 3': '0.3 m3'}}, 
    'JMD Fuencarral': {
        'id': 1082, 
        'longitude': -3.70972842, 
        'latitude': 40.47854097, 
        'consumption': {'2020 3': '7707.7 kWh', '2020 4': '6229.3 kWh', '2020 5': '4421 kWh', '2020 6': '5442.3 kWh', '2020 7': '7953 kWh', '2020 8': '7288.9 kWh', '2020 9': '6682.8 kWh', '2020 10': '9176.5 kWh', '2020 11': '8558.8 kWh', '2020 12': '10497.6 kWh', '2021 1': '9614 kWh', '2021 2': '6686.6 kWh', '2021 3': '6406.3 kWh'}}, 
    'Complejo La Vaguada': {
        'id': 1083, 
        'longitude': -3.7074566, 
        'latitude': 40.404800099999996, 
        'consumption': {'2020 3': '2693 kVAr', '2020 4': 'NO DATA kVAr', '2020 5': 'NO DATA kVAr', '2020 6': 'NO DATA kVAr', '2020 7': 'NO DATA kVAr', '2020 8': 'NO DATA kVAr', '2020 9': 'NO DATA kVAr', '2020 10': 'NO DATA kVAr', '2020 11': 'NO DATA kVAr', '2020 12': 'NO DATA kVAr', '2021 1': '41931 kVAr', '2021 2': '39560 kVAr', '2021 3': '30255 kVAr'}}, 
    'JMD Usera': {
        'id': 1092, 
        'longitude': -3.7106178999999995, 
        'latitude': 40.403824799999995, 
        'consumption': {'2021 3': '8965 kVAr'}}, 
    'CDM Plata y Castañar': {
        'id': 1114, 
        'longitude': -3.7179870000000004, 
        'latitude': 40.4035053, 
        'consumption': {'2020 3': '12142 m3', '2020 4': '9611 m3', '2020 5': '2282 m3', '2020 6': '3455 m3', '2020 7': '5168 m3', '2020 8': '4821 m3', '2020 9': '9324 m3', '2020 10': '13104 m3', '2020 11': '16544 m3', '2020 12': '22704 m3', '2021 1': '23617 m3', '2021 2': '12481 m3', '2021 3': '2515 m3'}}, 
    'CDI Arganzuela': {
        'id': 1119, 
        'longitude': -3.695442975, 
        'latitude': 40.40265908, 
        'consumption': {'2020 3': '38.7 m3', '2020 4': '0 m3', '2020 5': '0.3 m3', '2020 6': '0 m3', '2020 7': '21.4 m3', '2020 8': '212.6 m3', '2020 9': '6982.9 m3', '2020 10': '7387.3 m3', '2020 11': '5865.7 m3', '2020 12': '5679.6 m3', '2021 1': '832.5 m3', '2021 2': '889.5 m3', '2021 3': '2596.1 m3'}}, 
    'Palacio Cibeles': {
        'id': 1131, 
        'longitude': -3.6919909719999997, 
        'latitude': 40.41879656, 
        'consumption': {'2020 3': '333054 kWh', '2020 4': '201747 kWh', '2020 5': '212870 kWh', '2020 6': '300662 kWh', '2020 7': '409427 kWh', '2020 8': '59858 kVAr', '2020 9': '36245 kVAr', '2020 10': '6807 kVAr', '2020 11': '12128.25 kVAr', '2020 12': '14877 kVAr', '2021 1': '395522 kWh', '2021 2': '353572 kWh', '2021 3': '429076 kWh'}}, 
    'CDM Gallur': {
        'id': 1140, 
        'longitude': -3.736655116, 
        'latitude': 40.39881433, 
        'consumption': {'2020 3': '0 m3', '2020 4': '0 m3', '2020 5': '0 m3', '2020 6': '0 m3', '2020 7': '0 m3', '2020 8': 'NO DATA m3', '2020 9': 'NO DATA m3', '2020 10': '0 m3', '2020 11': '0 m3', '2020 12': '0 m3', '2021 1': '0 m3', '2021 2': '0 m3', '2021 3': '190.5 m3'}},
    'Sede AG Hacienda': {
        'id': 1174, 
        'longitude': -3.6962010000000003, 
        'latitude': 40.40419184,
        'consumption': {'2020 3': '0 kVAr', '2020 4': '5843 kVAr', '2020 5': '5332 kVAr', '2020 6': '8315 kVAr', '2020 7': '14226 kVAr', '2020 8': '20515 kVAr', '2020 9': '14817 kVAr', '2020 10': '12135 kVAr', '2020 11': 'NO DATA kVAr', '2020 12': '3497 kVAr', '2021 1': '1610 kVAr', '2021 2': '4953 kVAr', '2021 3': '1874 kVAr'}}, 
    'Biblioteca Gerardo Diego': {
        'id': 1208, 
        'longitude': -3.62267, 
        'latitude': 40.4037788, 
        'consumption': {'2020 3': '1376.2 m3', '2020 4': '0 m3', '2020 5': '0 m3', '2020 6': '0 m3', '2020 7': '0 m3', '2020 8': '0 m3', '2020 9': '0 m3', '2020 10': '1349.2 m3', '2020 11': '2749.9 m3', '2020 12': '3394.9 m3', '2021 1': '1501.6 m3', '2021 2': '2851.1 m3', '2021 3': '2720.8 m3'}}, 
    'UID Villa de Vallecas': {
        'id': 1392, 
        'longitude': -3.6143, 
        'latitude': 40.3791, 
        'consumption': {'2021 3': '2743.7 kvAr'}}, 
    'Biblioteca José Hierro': {
        'id': 1526, 
        'longitude': -3.610551, 
        'latitude': 40.40433298, 
        'consumption': {'2020 3': '5.7 m3', '2020 4': '14.1 m3', '2020 5': '0 m3', '2020 6': '0 m3', '2020 7': '0 m3', '2020 8': '0 m3', '2020 9': '1.5 m3', '2020 10': '0 m3', '2020 11': '53.8 m3', '2020 12': '0 m3', '2021 1': '0 m3', '2021 2': '0 m3', '2021 3': '5.6 m3'}}, 
    'CDM Samaranch': {
        'id': 1541, 
        'longitude': -3.7174746389999997, 
        'latitude': 40.407495399999995, 
        'consumption': {'2020 3': '14511 m3', '2020 4': '9100 m3', '2020 5': '4361 m3', '2020 6': '3014 m3', '2020 7': '424 m3', '2020 8': '361 m3', '2020 9': '5966 m3', '2020 10': '19927 m3', '2020 11': '31590 m3', '2020 12': '2540 m3', '2021 1': 'NO DATA m3', '2021 2': 'NO DATA m3', '2021 3': '14058 m3'}}, 
    'CDM Alberto García': {
        'id': 1656, 
        'longitude': -3.6568519999999998, 
        'latitude': 40.40371873, 
        'consumption': {'2020 3': '9686.1 m3', '2020 4': '2879.9 m3', '2020 5': '2088.6 m3', '2020 6': '935.4 m3', '2020 7': '569.3 m3', '2020 8': '533.2 m3', '2020 9': '613.6 m3', '2020 10': '3672.6 m3', '2020 11': '7804 m3', '2020 12': '13098.9 m3', '2021 1': '20325.9 m3', '2021 2': '9527.1 m3', '2021 3': '12389 m3'}}, 
    'CDM Barajas': {
        'id': 1692, 
        'longitude': -3.5872300000000004, 
        'latitude': 40.40463228, 
        'consumption': {'2020 3': '10900.2 m3', '2020 4': '4547.3 m3', '2020 5': '2185.2 m3', '2020 6': '2158.6 m3', '2020 7': '1724.1 m3', '2020 8': '3031.6 m3', '2020 9': '6505.4 m3', '2020 10': '11029.2 m3', '2020 11': '13639.3 m3', '2020 12': '16831 m3', '2021 1': '18541.1 m3', '2021 2': '17484.2 m3', '2021 3': '19946.1 m3'}}, 
    'Biblioteca Francisco Ayala': {
        'id': 1763, 
        'longitude': -3.621070683, 
        'latitude': 40.39987282, 
        'consumption': {'2020 3': '933.9126 m3', '2020 4': '440.1762 m3', '2020 5': '0 m3', '2020 6': '0 m3', '2020 7': '0 m3', '2020 8': '0 m3', '2020 9': '0.5073 m3', '2020 10': '576.8535 m3', '2020 11': '2074.7502 m3', '2020 12': '2856.0189 m3', '2021 1': '2035.4745 m3', '2021 2': '2037.9309 m3', '2021 3': '1774.9626 m3'}}, 
    'CDM La Masó': {
        'id': 1771, 
        'longitude': -3.715320826, 
        'latitude': 40.48519441, 
        'consumption': {'2020 3': '0 m3', '2020 4': '0 m3', '2020 5': '0 m3', '2020 6': '0 m3', '2020 7': '0 m3', '2020 8': '0 m3', '2020 9': '0 m3', '2020 10': '0 m3', '2020 11': '0 m3', '2020 12': '0 m3', '2021 1': '0 m3', '2021 2': '0 m3', '2021 3': '0 m3'}}, 
    'CDM Concepción': {
        'id': 1825, 
        'longitude': -3.6484410000000005,
        'latitude': 40.4043725, 
        'consumption': {'2020 3': '8611 m3', '2020 4': '0 m3', '2020 5': '0 m3', '2020 6': '2194 m3', '2020 7': '1620 m3', '2020 8': '1887 m3', '2020 9': '7087 m3', '2020 10': '14559 m3', '2020 11': '19172 m3', '2020 12': '23529 m3', '2021 1': '29065 m3', '2021 2': '23185 m3', '2021 3': '25992 m3'}}, 
    'CDM Pueblo Nuevo': {
        'id': 1889, 
        'longitude': -3.6385199999999998, 
        'latitude': 40.40430042, 
        'consumption': {'2020 3': '3660 m3', '2020 4': '0 m3', '2020 5': '0 m3', '2020 6': '0 m3', '2020 7': '4.5 m3', '2020 8': '0 m3', '2020 9': '4996.1 m3', '2020 10': '8355.6 m3', '2020 11': 'NO DATA m3', '2020 12': 'NO DATA m3', '2021 1': 'NO DATA m3', '2021 2': '6846.5 m3', '2021 3': '15926.2 m3'}}, 
    'CDM Faustina Valladolid - Valdebernardo': {
        'id': 1892, 
        'longitude': -3.6229150000000003, 
        'latitude': 40.40404088, 
        'consumption': {'2020 3': '15209.3 m3', '2020 4': '3852.2 m3', '2020 5': '2338.8 m3', '2020 6': '3269.5 m3', '2020 7': '5867.4 m3', '2020 8': '4075.9 m3', '2020 9': '10406 m3', '2020 10': '19067.5 m3', '2020 11': '22650.2 m3', '2020 12': '1619.1 m3', '2021 1': 'NO DATA m3', '2021 2': 'NO DATA m3', '2021 3': '22471 m3'}}, 
    'CDM El Espinillo': {
        'id': 1895, 
        'longitude': -3.686438, 
        'latitude': 40.40356422, 
        'consumption': {'2021 3': '0 m3'}}, 
    'CC Los Rosales': {
        'id': 1905, 
        'longitude': -3.6772292999999996, 
        'latitude': 40.40350795, 
        'consumption': {'2021 3': '0 m3'}},
    'Biblioteca Manuel Vázquez Montalbán': {
        'id': 1912, 
        'longitude': -3.7109099999999997, 
        'latitude': 40.40456971, 
        'consumption': {'2021 3': '3787 kvAr'}}, 
    'CDM Cerro Almodóvar': {
        'id': 1918, 
        'longitude': -3.6027370000000003, 
        'latitude': 40.40382653, 
        'consumption': {'2020 3': '4859 kVAr', '2020 4': '3221 kVAr', '2020 5': '3190 kVAr', '2020 6': '1857 kVAr', '2020 7': '157 kVAr', '2020 8': '136 kVAr', '2020 9': '912 kVAr', '2020 10': '2022 kVAr', '2020 11': '3207 kVAr', '2020 12': '6464 kVAr', '2021 1': '5237 kVAr', '2021 2': '3319 kVAr', '2021 3': '338 kVAr'}}, 
    'CDM Hortaleza': {
        'id': 1929, 
        'longitude': -3.652621, 
        'latitude': 40.40478809, 
        'consumption': {'2020 3': '6350 m3', '2020 4': '0 m3', '2020 5': '35 m3', '2020 6': '1978 m3', '2020 7': '2633 m3', '2020 8': '2653 m3', '2020 9': '5229 m3', '2020 10': '11492 m3', '2020 11': '18213 m3', '2020 12': '23724 m3', '2021 1': '30271 m3', '2021 2': '18040 m3', '2021 3': '17624 m3'}}, 
    'CDM La Elipa': {
        'id': 1933, 
        'longitude': -3.656014, 
        'latitude': 40.40415170000001, 
        'consumption': {'2020 3': '928.5 m3', '2020 4': '0.5 m3', '2020 5': '0.6 m3', '2020 6': '0 m3', '2020 7': '0.1 m3', '2020 8': '0.1 m3', '2020 9': '354.1 m3', '2020 10': '1976.9 m3', '2020 11': '3416.2 m3', '2020 12': '49.9 m3', '2021 1': '0 m3', '2021 2': '0 m3', '2021 3': '3642.2 m3'}}, 
    'CDM Luis Aragonés': {
        'id': 1937, 
        'longitude': -3.6284440000000004, 
        'latitude': 40.40465317, 
        'consumption': {'2020 3': '13226.4 m3', '2020 4': '4901.3 m3', '2020 5': '2410.7 m3', '2020 6': '5247.4 m3', '2020 7': '1837.1 m3', '2020 8': '3294.1 m3', '2020 9': '7534 m3', '2020 10': '14009.1 m3', '2020 11': '18529.3 m3', '2020 12': '1511 m3', '2021 1': 'NO DATA m3', '2021 2': 'NO DATA m3', '2021 3': '4808.9 m3'}}, 
    'CDM Playa Victoria': {
        'id': 1941, 
        'longitude': -3.7020989999999996, 
        'latitude': 40.40459328, 
        'consumption': {'2020 3': '4916 m3', '2020 4': '0 m3', '2020 5': '0 m3', '2020 6': '0 m3', '2020 7': '149 m3', '2020 8': '4497 m3', '2020 9': '5952 m3', '2020 10': '11474 m3', '2020 11': '12700 m3', '2020 12': '13673 m3', '2021 1': 'NO DATA m3', '2021 2': 'NO DATA m3', '2021 3': '6141 m3'}}, 
    'CDM San Blas': {
        'id': 1942, 
        'longitude': -3.612940907, 
        'latitude': 40.42871195, 
        'consumption': {'2020 3': '0 kWh', '2020 4': '787 kWh', '2020 5': '0 kWh', '2020 6': '0 kWh', '2020 7': '63223 kWh', '2020 8': '70261 kWh', '2020 9': '96927 kWh', '2020 10': '83490 kWh', '2020 11': '102483 kWh', '2020 12': '117616 kWh', '2021 1': '114959 kWh', '2021 2': '103262 kWh', '2021 3': '116037 kWh'}}, 
    'CDM San Juan Bautista': {
        'id': 1945, 
        'longitude': -3.658699, 
        'latitude': 40.404483500000005, 
        'consumption': {'2020 3': '3361.3 m3', '2020 4': '0 m3', '2020 5': '0 m3', '2020 6': '1838 m3', '2020 7': '3942.3 m3', '2020 8': '1111.7 m3', '2020 9': '2846 m3', '2020 10': 'NO DATA m3', '2020 11': 'NO DATA m3', '2020 12': 'NO DATA m3', '2021 1': 'NO DATA m3', '2021 2': '6353.1 m3', '2021 3': '12059.7 m3'}}, 
    'CDM Vicente del Bosque': {
        'id': 1950, 
        'longitude': -3.6939060689999996, 
        'latitude': 40.4783396, 
        'consumption': {'2020 3': '0 kVAr', '2020 4': '0 kVAr', '2020 5': '1 kVAr', '2020 6': '1182 kVAr', '2020 7': '32777 kVAr', '2020 8': '29180 kVAr', '2020 9': '425 kVAr', '2020 10': '184 kVAr', '2020 11': 'NO DATA kVAr', '2020 12': '934 kVAr', '2021 1': '1733 kVAr', '2021 2': '2401 kVAr', '2021 3': '2034 kVAr'}}, 
    'CDM Ángel Nieto - Puente de Vallecas': {
        'id': 1971, 
        'longitude': -3.6599779999999997, 
        'latitude': 40.40391423, 
        'consumption': {'2020 3': '9179 m3', '2020 4': '3218 m3', '2020 5': '1471 m3', '2020 6': '123 m3', '2020 7': '1058 m3', '2020 8': '1186 m3', '2020 9': '1653 m3', '2020 10': '13547 m3', '2020 11': '13010 m3', '2020 12': '15847 m3', '2021 1': '14173 m3', '2021 2': '13499 m3', '2021 3': '14167 m3'}}, 
    'JMD San Blas': {
        'id': 1974, 
        'longitude': -3.609687, 
        'latitude': 40.40434418, 
        'consumption': {'2021 3': '1245 kVAr'}}, 
    'Pabellón Jesús Rollán': {
        'id': 1987, 
        'longitude': -3.71527791, 
        'latitude': 40.37608734,
        'consumption': {'2020 3': '13953 m3', '2020 4': '9017 m3', '2020 5': '5664 m3', '2020 6': '3675 m3', '2020 7': '2285 m3', '2020 8': '3454 m3', '2020 9': '6671 m3', '2020 10': '13916 m3', '2020 11': '15481 m3', '2020 12': '19809 m3', '2021 1': '22604 m3', '2021 2': '17916 m3', '2021 3': '19408 m3'}}, 
    'CC Eduardo Úrculo & Biblioteca María Zambrano': {
        'id': 2011, 
        'longitude': -3.6933019999999996, 
        'latitude': 40.40469175, 
        'consumption': {'2021 3': '1.2 m3'}}, 
    'Biblioteca Eugenio Trías': {
        'id': 2096, 
        'longitude': -3.678915, 
        'latitude': 40.40416859, 
        'consumption': {'2020 3': '1266.7 m3', '2020 4': '0 m3', '2020 5': '0 m3', '2020 6': '0 m3', '2020 7': '0 m3', '2020 8': '0 m3', '2020 9': '0 m3', '2020 10': '411.1 m3', '2020 11': '2308.3 m3', '2020 12': '3528.3 m3', '2021 1': '4399.3 m3', '2021 2': '2472.2 m3', '2021 3': '2178.4 m3'}}, 
    'Biblioteca Ángel González': {
        'id': 2178, 
        'longitude': -3.76637131, 
        'latitude': 40.39696817, 
        'consumption': {'2020 3': '0 m3', '2020 4': 'NO DATA m3', '2020 5': '0 m3', '2020 6': '0 m3', '2020 7': '0 m3', '2020 8': '0 m3', '2020 9': '0 m3', '2020 10': '0 m3', '2020 11': '17.1 m3', '2020 12': '0 m3', '2021 1': '0 m3', '2021 2': '0 m3', '2021 3': '0 m3'}}, 
    'Biblioteca María Lejárraga': {
        'id': 2204, 
        'longitude': -3.6553480000000005, 
        'latitude': 40.40490239, 
        'consumption': {'2020 3': '4576.1804 kWh', '2020 4': '1094.8408 kWh', '2020 5': '1022352 kWh', '2020 6': '2617.6832 kWh', '2020 7': '7965826 kWh', '2020 8': '7314.2932 kWh', '2020 9': '5973106 kWh', '2020 10': '6353.0224 kWh', '2020 11': '8972.4384 kWh', '2020 12': '10935.2676 kWh', '2021 1': '11070.5704 kWh', '2021 2': '8707.8976 kWh', '2021 3': '9744.6896 kWh'}}, 
    'Biblioteca Ana María Matute': {
        'id': 2258, 
        'longitude': -3.720293641, 
        'latitude': 40.39775215, 
        'consumption': {'2020 3': '104 kVAr', '2020 4': '15 kVAr', '2020 5': '3 kVAr', '2020 6': '76 kVAr', '2020 7': '298 kVAr', '2020 8': '177 kVAr', '2020 9': '29 kVAr', '2020 10': '45 kVAr', '2020 11': '180 kVAr', '2020 12': '350 kVAr', '2021 1': '843 kVAr', '2021 2': '186 kVAr', '2021 3': '202 kVAr'}}
}

export const panels = {
    'ALMACÉN DE LA VILLA': {
        'id': 2258, 
        'energy': {'2020 1': '1757.7kWh', '2020 2': '2099.16kWh', '2020 3': '2909.97kWh', '2020 4': '2.868.075kWh', '2020 5': '31.394.475kWh', '2020 6': '3.331.125kWh', '2020 7': '36.862.875kWh', '2020 8': '35.886.375kWh', '2020 9': '3099.6kWh', '2020 10': '25.828.425kWh', '2020 11': '1871.1kWh', '2020 12': '1699.11kWh', '2021 1': '963.9kWh', '2021 2': '2099.16kWh', '2021 3': '2909.97kWh'}, 
        'longitude': -3.597144, 
        'latitude': 40.395628, 
        'capacity': 175.0}, 
    'ANTIGUO CANÓDROMO': {
        'id': 2258, 
        'energy': {'2019 4': '491.67kWh', '2019 5': '538.191kWh', '2019 6': '571.05kWh', '2019 7': '631.935kWh', '2019 8': '615.195kWh', '2019 9': '531.36kWh', '2019 10': '442.773kWh', '2019 11': '320.76kWh', '2019 12': '291.276kWh', '2020 1': '301.32kWh', '2020 2': '359.856kWh', '2020 3': '498.852kWh', '2020 4': '491.67kWh', '2020 5': '538.191kWh', '2020 6': '571.05kWh', '2020 7': '631.935kWh', '2020 8': '615.195kWh', '2020 9': '531.36kWh', '2020 10': '442.773kWh', '2020 11': '320.76kWh', '2020 12': '291.276kWh', '2021 1': '165.24kWh', '2021 2': '359.856kWh', '2021 3': '498.852kWh'}, 
        'longitude': -3.73527250405395, 
        'latitude': 40.39801608, 
        'capacity': 3.0}, 
    'AULA DE LA NATURALEZA DEHESA DE LA VILLA': {
        'id': 2258, 
        'energy': {'2019 1': '251.1kWh', '2019 2': '299.88kWh', '2019 3': '415.71kWh', '2019 4': '409.725kWh', '2019 5': '4.484.925kWh', '2019 6': '475.875kWh', '2019 7': '5.266.125kWh', '2019 8': '5.126.625kWh', '2019 9': '442.8kWh', '2019 10': '3.689.775kWh', '2019 11': '267.3kWh', '2019 12': '242.73kWh', '2020 1': '251.1kWh', '2020 2': '299.88kWh', '2020 3': '415.71kWh', '2020 4': '409.725kWh', '2020 5': '4.484.925kWh', '2020 6': '475.875kWh', '2020 7': '5.266.125kWh', '2020 8': '5.126.625kWh', '2020 9': '442.8kWh', '2020 10': '3.689.775kWh', '2020 11': '267.3kWh', '2020 12': '242.73kWh', '2021 1': '137.7kWh', '2021 2': '299.88kWh', '2021 3': '415.71kWh'}, 
        'longitude': -3.71827056367926, 
        'latitude': 40.45914104, 
        'capacity': 25.0},
    'BASE 14 SAMUR': {
        'id': 2258, 
        'energy': {'2019 1': '602.64kWh', '2019 2': '719.712kWh', '2019 3': '997.704kWh', '2019 4': '983.34kWh', '2019 5': '1.076.382kWh', '2019 6': '1142.1kWh', '2019 7': '1263.87kWh', '2019 8': '1230.39kWh', '2019 9': '1062.72kWh', '2019 10': '885.546kWh', '2019 11': '641.52kWh', '2019 12': '582.552kWh', '2020 1': '602.64kWh', '2020 2': '719.712kWh', '2020 3': '997.704kWh', '2020 4': '983.34kWh', '2020 5': '1.076.382kWh', '2020 6': '1142.1kWh', '2020 7': '1263.87kWh', '2020 8': '1230.39kWh', '2020 9': '1062.72kWh', '2020 10': '885.546kWh', '2020 11': '641.52kWh', '2020 12': '582.552kWh', '2021 1': '330.48kWh', '2021 2': '719.712kWh', '2021 3': '997.704kWh'}, 
        'longitude': -3.765466, 
        'latitude': 40.380148, 
        'capacity': 6.0}, 
    'BIBLIOTECA EUGENIO TRIAS (CASA FIERAS)': {
        'id': 2258, 
        'energy': {'2019 7': '1.727.289kWh', '2019 8': '1.681.533kWh', '2019 9': '1.452.384kWh', '2019 10': '12.102.462kWh', '2019 11': '876.744kWh', '2019 12': '7.961.544kWh', '2020 1': '823.608kWh', '2020 2': '9.836.064kWh', '2020 3': '13.635.288kWh', '2020 4': '1.343.898kWh', '2020 5': '14.710.554kWh', '2020 6': '1560.87kWh', '2020 7': '1.727.289kWh', '2020 8': '1.681.533kWh', '2020 9': '1.452.384kWh', '2020 10': '12.102.462kWh', '2020 11': '876.744kWh', '2020 12': '7.961.544kWh', '2021 1': '451.656kWh', '2021 2': '9.836.064kWh', '2021 3': '662kWh'}, 
        'longitude': -3.67959303680348, 
        'latitude': 40.41660911, 
        'capacity': 82.0}, 
    'BIBLIOTECA GERARDO DIEGO': {
        'id': 2258, 
        'energy': {'2020 11': '3.449.952kWh', '2020 12': '8.544.096kWh', '2021 1': '484.704kWh', '2021 2': '10.555.776kWh', '2021 3': '406.06kWh'}, 
        'longitude': -3.62272966050395, 
        'latitude': 40.37790196, 
        'capacity': 88.0}, 
    'BIBLIOTECA IVÁN DE VARGAS': {
        'id': 2258, 
        'energy': {'2019 7': '631.935kWh', '2019 8': '615.195kWh', '2019 9': '531.36kWh', '2019 10': '442.773kWh', '2019 11': '320.76kWh', '2019 12': '291.276kWh', '2020 1': '301.32kWh', '2020 2': '359.856kWh', '2020 3': '498.852kWh', '2020 4': '491.67kWh', '2020 5': '538.191kWh', '2020 6': '571.05kWh', '2020 7': '631.935kWh', '2020 8': '615.195kWh', '2020 9': '531.36kWh', '2020 10': '442.773kWh', '2020 11': '320.76kWh', '2020 12': '291.276kWh', '2021 1': '165.24kWh', '2021 2': '359.856kWh', '2021 3': '388kWh'}, 
        'longitude': -3.70985912127358, 
        'latitude': 40.41410291, 
        'capacity': 3.0}, 
    'CC BOHEMIOS': {
        'id': 2258, 
        'energy': {'2019 1': '1004.4kWh', '2019 2': '1199.52kWh', '2019 3': '1662.84kWh', '2019 4': '1638.9kWh', '2019 5': '1793.97kWh', '2019 6': '1903.5kWh', '2019 7': '2106.45kWh', '2019 8': '2050.65kWh', '2019 9': '1771.2kWh', '2019 10': '1475.91kWh', '2019 11': '1069.2kWh', '2019 12': '970.92kWh', '2020 1': '1004.4kWh', '2020 2': '1199.52kWh', '2020 3': '1662.84kWh', '2020 4': '1638.9kWh', '2020 5': '1793.97kWh', '2020 6': '1903.5kWh', '2020 7': '2106.45kWh', '2020 8': '2050.65kWh', '2020 9': '1771.2kWh', '2020 10': '1475.91kWh', '2020 11': '1069.2kWh', '2020 12': '970.92kWh', '2021 1': '550.8kWh', '2021 2': '1199.52kWh', '2021 3': '1344kWh'}, 
        'longitude': -3.69405018466108, 
        'latitude': 40.35639654, 
        'capacity': 10.0}, 
    'CC EDUARDO CHILLIDA Y BIBLIOTECA MIGUEL DELIBES': {
        'id': 2258, 
        'energy': {'2019 1': '1004.4kWh', '2019 2': '1199.52kWh', '2019 3': '1662.84kWh', '2019 4': '1638.9kWh', '2019 5': '1793.97kWh', '2019 6': '1903.5kWh', '2019 7': '2106.45kWh', '2019 8': '2050.65kWh', '2019 9': '1771.2kWh', '2019 10': '1475.91kWh', '2019 11': '1069.2kWh', '2019 12': '970.92kWh', '2020 1': '1004.4kWh', '2020 2': '1199.52kWh', '2020 3': '1662.84kWh', '2020 4': '1638.9kWh', '2020 5': '1793.97kWh', '2020 6': '1903.5kWh', '2020 7': '2106.45kWh', '2020 8': '2050.65kWh', '2020 9': '1771.2kWh', '2020 10': '1475.91kWh', '2020 11': '1069.2kWh', '2020 12': '970.92kWh', '2021 1': '550.8kWh', '2021 2': '1199.52kWh', '2021 3': '1568kWh'}, 
        'longitude': -3.65321477735933, 
        'latitude': 40.40939174, 
        'capacity': 10.0}, 
    'CC EDUARDO ÚRCULO Y BIBLIOTECA MARÍA ZAMBRANO': {
        'id': 2258, 
        'energy': {'2019 1': '2762.1kWh', '2019 2': '3298.68kWh', '2019 3': '4572.81kWh', '2019 4': '4.506.975kWh', '2019 5': '49.334.175kWh', '2019 6': '5.234.625kWh', '2019 7': '57.927.375kWh', '2019 8': '56.392.875kWh', '2019 9': '4870.8kWh', '2019 10': '40.587.525kWh', '2019 11': '2940.3kWh', '2019 12': '2670.03kWh', '2020 1': '2762.1kWh', '2020 2': '3298.68kWh', '2020 3': '4572.81kWh', '2020 4': '4.506.975kWh', '2020 5': '49.334.175kWh', '2020 6': '5.234.625kWh', '2020 7': '57.927.375kWh', '2020 8': '56.392.875kWh', '2020 9': '4870.8kWh', '2020 10': '40.587.525kWh', '2020 11': '2940.3kWh', '2020 12': '2670.03kWh', '2021 1': '1514.7kWh', '2021 2': '3298.68kWh', '2021 3': '4572.81kWh'}, 
        'longitude': -3.69766200679649, 
        'latitude': 40.46903299, 
        'capacity': 275.0}, 
    'CC EL MADROÑO Y BIBLIOTECA VICÁLVARO': {
        'id': 2258, 'energy': {'2019 1': '823.608kWh', '2019 2': '9.836.064kWh', '2019 3': '13.635.288kWh', '2019 4': '1.343.898kWh', '2019 5': '14.710.554kWh', '2019 6': '1560.87kWh', '2019 7': '1.727.289kWh', '2019 8': '1.681.533kWh', '2019 9': '1.452.384kWh', '2019 10': '12.102.462kWh', '2019 11': '876.744kWh', '2019 12': '7.961.544kWh', '2020 1': '823.608kWh', '2020 2': '9.836.064kWh', '2020 3': '13.635.288kWh', '2020 4': '1.343.898kWh', '2020 5': '14.710.554kWh', '2020 6': '1560.87kWh', '2020 7': '1.727.289kWh', '2020 8': '1.681.533kWh', '2020 9': '1.452.384kWh', '2020 10': '12.102.462kWh', '2020 11': '876.744kWh', '2020 12': '7.961.544kWh', '2021 1': '451.656kWh', '2021 2': '9.836.064kWh', '2021 3': '1137kWh'}, 
        'longitude': -3.60861612000171, 
        'latitude': 40.40529272, 
        'capacity': 82.0},
    'CC FEDERICO CHUECA ': {
        'id': 2258, 
        'energy': {'2019 1': '2008.8kWh', '2019 2': '2399.04kWh', '2019 3': '3325.68kWh', '2019 4': '3277.8kWh', '2019 5': '3587.94kWh', '2019 6': '3807kWh', '2019 7': '4212.9kWh', '2019 8': '4101.3kWh', '2019 9': '3542.4kWh', '2019 10': '2951.82kWh', '2019 11': '2138.4kWh', '2019 12': '1941.84kWh', '2020 1': '2008.8kWh', '2020 2': '2399.04kWh', '2020 3': '3325.68kWh', '2020 4': '3277.8kWh', '2020 5': '3587.94kWh', '2020 6': '3807kWh', '2020 7': '4212.9kWh', '2020 8': '4101.3kWh', '2020 9': '3542.4kWh', '2020 10': '2951.82kWh', '2020 11': '2138.4kWh', '2020 12': '1941.84kWh', '2021 1': '1101.6kWh', '2021 2': '2399.04kWh', '2021 3': '3325.68kWh'}, 
        'longitude': -3.65056826658469, 
        'latitude': 40.46498335, 
        'capacity': 20.0},
    'CC GARCÍA LORCA': {
        'id': 2258, 
        'energy': {'2019 1': '301.32kWh', '2019 2': '359.856kWh', '2019 3': '498.852kWh', '2019 4': '491.67kWh', '2019 5': '538.191kWh', '2019 6': '571.05kWh', '2019 7': '631.935kWh', '2019 8': '615.195kWh', '2019 9': '531.36kWh', '2019 10': '442.773kWh', '2019 11': '320.76kWh', '2019 12': '291.276kWh', '2020 1': '301.32kWh', '2020 2': '359.856kWh', '2020 3': '498.852kWh', '2020 4': '491.67kWh', '2020 5': '538.191kWh', '2020 6': '571.05kWh', '2020 7': '631.935kWh', '2020 8': '615.195kWh', '2020 9': '531.36kWh', '2020 10': '442.773kWh', '2020 11': '320.76kWh', '2020 12': '291.276kWh', '2021 1': '165.24kWh', '2021 2': '359.856kWh', '2021 3': '498.852kWh'}, 
        'longitude': -3.75103374498486, 
        'latitude': 40.3742029, 
        'capacity': 3.0}, 
    'CC JOSE LUIS LÓPEZ VÁZQUEZ': {
        'id': 2258, 
        'energy': {'2019 1': '1506.6kWh', '2019 2': '1799.28kWh', '2019 3': '2494.26kWh', '2019 4': '2458.35kWh', '2019 5': '2.690.955kWh', '2019 6': '2855.25kWh', '2019 7': '3.159.675kWh', '2019 8': '3.075.975kWh', '2019 9': '2656.8kWh', '2019 10': '2.213.865kWh', '2019 11': '1603.8kWh', '2019 12': '1456.38kWh', '2020 1': '1506.6kWh', '2020 2': '1799.28kWh', '2020 3': '2494.26kWh', '2020 4': '2458.35kWh', '2020 5': '2.690.955kWh', '2020 6': '2855.25kWh', '2020 7': '3.159.675kWh', '2020 8': '3.075.975kWh', '2020 9': '2656.8kWh', '2020 10': '2.213.865kWh', '2020 11': '1603.8kWh', '2020 12': '1456.38kWh', '2021 1': '826.2kWh', '2021 2': '1799.28kWh', '2021 3': '2494.26kWh'}, 
        'longitude': -3.57274116382091, 
        'latitude': 40.44681859, 
        'capacity': 15.0}, 
    'CC LA ELIPA Y CSS SANTA FELICIDAD': {
        'id': 2258, 
        'energy': {'2019 1': '1506.6kWh', '2019 2': '1799.28kWh', '2019 3': '2494.26kWh', '2019 4': '2458.35kWh', '2019 5': '2.690.955kWh', '2019 6': '2855.25kWh', '2019 7': '3.159.675kWh', '2019 8': '3.075.975kWh', '2019 9': '2656.8kWh', '2019 10': '2.213.865kWh', '2019 11': '1603.8kWh', '2019 12': '1456.38kWh', '2020 1': '1506.6kWh', '2020 2': '1799.28kWh', '2020 3': '2494.26kWh', '2020 4': '2458.35kWh', '2020 5': '2.690.955kWh', '2020 6': '2855.25kWh', '2020 7': '3.159.675kWh', '2020 8': '3.075.975kWh', '2020 9': '2656.8kWh', '2020 10': '2.213.865kWh', '2020 11': '1603.8kWh', '2020 12': '1456.38kWh', '2021 1': '826.2kWh', '2021 2': '1799.28kWh', '2021 3': '1938kWh'}, 
        'longitude': -3.65042533242011, 
        'latitude': 40.42447061, 
        'capacity': 15.0}, 
    'CC LOS ROSALES (NUEVO ROSALES)': {
        'id': 2258, 
        'energy': {'2019 1': '2008.8kWh', '2019 2': '2399.04kWh', '2019 3': '3325.68kWh', '2019 4': '3277.8kWh', '2019 5': '3587.94kWh', '2019 6': '3807kWh', '2019 7': '4212.9kWh', '2019 8': '4101.3kWh', '2019 9': '3542.4kWh', '2019 10': '2951.82kWh', '2019 11': '2138.4kWh', '2019 12': '1941.84kWh', '2020 1': '2008.8kWh', '2020 2': '2399.04kWh', '2020 3': '3325.68kWh', '2020 4': '3277.8kWh', '2020 5': '3587.94kWh', '2020 6': '3807kWh', '2020 7': '4212.9kWh', '2020 8': '4101.3kWh', '2020 9': '3542.4kWh', '2020 10': '2951.82kWh', '2020 11': '2138.4kWh', '2020 12': '1941.84kWh', '2021 1': '1101.6kWh', '2021 2': '2399.04kWh', '2021 3': '2589kWh'}, 
        'longitude': -3.67730644219692, 
        'latitude': 40.35074797, 
        'capacity': 20.0}, 
    'CC MARCONI': {
        'id': 2258, 
        'energy': {'2019 1': '1004.4kWh', '2019 2': '1199.52kWh', '2019 3': '1662.84kWh', '2019 4': '1638.9kWh', '2019 5': '1793.97kWh', '2019 6': '1903.5kWh', '2019 7': '2106.45kWh', '2019 8': '2050.65kWh', '2019 9': '1771.2kWh', '2019 10': '1475.91kWh', '2019 11': '1069.2kWh', '2019 12': '970.92kWh', '2020 1': '1004.4kWh', '2020 2': '1199.52kWh', '2020 3': '1662.84kWh', '2020 4': '1638.9kWh', '2020 5': '1793.97kWh', '2020 6': '1903.5kWh', '2020 7': '2106.45kWh', '2020 8': '2050.65kWh', '2020 9': '1771.2kWh', '2020 10': '1475.91kWh', '2020 11': '1069.2kWh', '2020 12': '970.92kWh', '2021 1': '550.8kWh', '2021 2': '1199.52kWh', '2021 3': '1662.84kWh'}, 
        'longitude': -3.70080956092159, 
        'latitude': 40.33475183, 
        'capacity': 10.0}, 
    'CC ORCASUR Y BIBLIOTECA GABRIEL GARCÍA MÁRQUEZ': {
        'id': 2258, 
        'energy': {'2019 1': '3013.2kWh', '2019 2': '3598.56kWh', '2019 3': '4988.52kWh', '2019 4': '4916.7kWh', '2019 5': '5381.91kWh', '2019 6': '5710.5kWh', '2019 7': '6319.35kWh', '2019 8': '6151.95kWh', '2019 9': '5313.6kWh', '2019 10': '4427.73kWh', '2019 11': '3207.6kWh', '2019 12': '2912.76kWh', '2020 1': '3013.2kWh', '2020 2': '3598.56kWh', '2020 3': '4988.52kWh', '2020 4': '4916.7kWh', '2020 5': '5381.91kWh', '2020 6': '5710.5kWh', '2020 7': '6319.35kWh', '2020 8': '6151.95kWh', '2020 9': '5313.6kWh', '2020 10': '4427.73kWh', '2020 11': '3207.6kWh', '2020 12': '2912.76kWh', '2021 1': '1652.4kWh', '2021 2': '3598.56kWh', '2021 3': '4988.52kWh'}, 
        'longitude': -3.7011410630838, 
        'latitude': 40.36676832, 
        'capacity': 30.0}, 
    'CC SAN FRANCISCO LA PRENSA': {
        'id': 2258, 
        'energy': {'2019 1': '502.2kWh', '2019 2': '599.76kWh', '2019 3': '831.42kWh', '2019 4': '819.45kWh', '2019 5': '896.985kWh', '2019 6': '951.75kWh', '2019 7': '1.053.225kWh', '2019 8': '1.025.325kWh', '2019 9': '885.6kWh', '2019 10': '737.955kWh', '2019 11': '534.6kWh', '2019 12': '485.46kWh', '2020 1': '502.2kWh', '2020 2': '599.76kWh', '2020 3': '831.42kWh', '2020 4': '819.45kWh', '2020 5': '896.985kWh', '2020 6': '951.75kWh', '2020 7': '1.053.225kWh', '2020 8': '1.025.325kWh', '2020 9': '885.6kWh', '2020 10': '737.955kWh', '2020 11': '534.6kWh', '2020 12': '485.46kWh', '2021 1': '275.4kWh', '2021 2': '599.76kWh', '2021 3': '831.42kWh'}, 
        'longitude': -3.74163973555209, 
        'latitude': 40.37690319, 
        'capacity': 5.0}, 
    'CC SANCHINARRO Y BIBLIOTECA MARÍA LEJÁRRAGA': {
        'id': 2258, 
        'energy': {'2019 2': '2399.04kWh', '2019 3': '3325.68kWh', '2019 4': '3277.8kWh', '2019 5': '3587.94kWh', '2019 6': '3807kWh', '2019 7': '4212.9kWh', '2019 8': '4101.3kWh', '2019 9': '3542.4kWh', '2019 10': '2951.82kWh', '2019 11': '2138.4kWh', '2019 12': '1941.84kWh', '2020 1': '2008.8kWh', '2020 2': '2399.04kWh', '2020 3': '3325.68kWh', '2020 4': '3277.8kWh', '2020 5': '3587.94kWh', '2020 6': '3807kWh', '2020 7': '4212.9kWh', '2020 8': '4101.3kWh', '2020 9': '3542.4kWh', '2020 10': '2951.82kWh', '2020 11': '2138.4kWh', '2020 12': '1941.84kWh', '2021 1': '1101.6kWh', '2021 2': '2399.04kWh', '2021 3': '3189kWh'}, 
        'longitude': -3.65526174471725, 
        'latitude': 40.4903531, 
        'capacity': 20.0}, 
    'CC VALDEBERNARDO Y BIBLIOTECA FRANCISCO AYALA': {
        'id': 2258, 
        'energy': {'2019 1': '2008.8kWh', '2019 2': '2399.04kWh', '2019 3': '3325.68kWh', '2019 4': '3277.8kWh', '2019 5': '3587.94kWh', '2019 6': '3807kWh', '2019 7': '4212.9kWh', '2019 8': '4101.3kWh', '2019 9': '3542.4kWh', '2019 10': '2951.82kWh', '2019 11': '2138.4kWh', '2019 12': '1941.84kWh', '2020 1': '2008.8kWh', '2020 2': '2399.04kWh', '2020 3': '3325.68kWh', '2020 4': '3277.8kWh', '2020 5': '3587.94kWh', '2020 6': '3807kWh', '2020 7': '4212.9kWh', '2020 8': '4101.3kWh', '2020 9': '3542.4kWh', '2020 10': '2951.82kWh', '2020 11': '2138.4kWh', '2020 12': '1941.84kWh', '2021 1': '1101.6kWh', '2021 2': '2399.04kWh', '2021 3': '2720kWh'}, 
        'longitude': -3.62128189057037, 
        'latitude': 40.39982891, 
        'capacity': 20.0}, 
    'CC Y BIBLIOTECA GLORIA FUERTES': {
        'id': 2258, 
        'energy': {'2019 1': '823.608kWh', '2019 2': '9.836.064kWh', '2019 3': '13.635.288kWh', '2019 4': '1.343.898kWh', '2019 5': '14.710.554kWh', '2019 6': '1560.87kWh', '2019 7': '1.727.289kWh', '2019 8': '1.681.533kWh', '2019 9': '1.452.384kWh', '2019 10': '12.102.462kWh', '2019 11': '876.744kWh', '2019 12': '7.961.544kWh', '2020 1': '823.608kWh', '2020 2': '9.836.064kWh', '2020 3': '13.635.288kWh', '2020 4': '1.343.898kWh', '2020 5': '14.710.554kWh', '2020 6': '1560.87kWh', '2020 7': '1.727.289kWh', '2020 8': '1.681.533kWh', '2020 9': '1.452.384kWh', '2020 10': '12.102.462kWh', '2020 11': '876.744kWh', '2020 12': '7.961.544kWh', '2021 1': '451.656kWh', '2021 2': '9.836.064kWh', '2021 3': '1084kWh'}, 
        'longitude': -3.59368776573359, 
        'latitude': 40.46062284, 
        'capacity': 82.0}, 
    'CC Y CMM SAN JUAN BAUTISTA': {
        'id': 2258, 
        'energy': {'2019 1': '2511kWh', '2019 2': '2998.8kWh', '2019 3': '4157.1kWh', '2019 4': '4097.25kWh', '2019 5': '4.484.925kWh', '2019 6': '4758.75kWh', '2019 7': '5.266.125kWh', '2019 8': '5.126.625kWh', '2019 9': '4428kWh', '2019 10': '3.689.775kWh', '2019 11': '2673kWh', '2019 12': '2427.3kWh', '2020 1': '2511kWh', '2020 2': '2998.8kWh', '2020 3': '4157.1kWh', '2020 4': '4097.25kWh', '2020 5': '4.484.925kWh', '2020 6': '4758.75kWh', '2020 7': '5.266.125kWh', '2020 8': '5.126.625kWh', '2020 9': '4428kWh', '2020 10': '3.689.775kWh', '2020 11': '2673kWh', '2020 12': '2427.3kWh', '2021 1': '1377kWh', '2021 2': '2998.8kWh', '2021 3': '4157.1kWh'}, 
        'longitude': -3.65804022879119, 
        'latitude': 40.45224738, 
        'capacity': 25.0}, 
    'CDI ARGANZUELA': {
        'id': 2258, 
        'energy': {'2019 4': '14750.1kWh', '2019 5': '16145.73kWh', '2019 6': '17131.5kWh', '2019 7': '18958.05kWh', '2019 8': '18455.85kWh', '2019 9': '15940.8kWh', '2019 10': '13283.19kWh', '2019 11': '9622.8kWh', '2019 12': '8738.28kWh', '2020 1': '9039.6kWh', '2020 2': '10795.68kWh', '2020 3': '14965.56kWh', '2020 4': '14750.1kWh', '2020 5': '16145.73kWh', '2020 6': '17131.5kWh', '2020 7': '18958.05kWh', '2020 8': '18455.85kWh', '2020 9': '15940.8kWh', '2020 10': '13283.19kWh', '2020 11': '9622.8kWh', '2020 12': '8738.28kWh', '2021 1': '4957.2kWh', '2021 2': '10795.68kWh', '2021 3': '14965.56kWh'}, 
        'longitude': -3.6955325387824, 
        'latitude': 40.40207279999999, 
        'capacity': 90.0}, 
    'CDM ANTONIO DÍAZ MIGUEL': {
        'id': 2258, 'energy': {'2019 1': '2511kWh', '2019 2': '2998.8kWh', '2019 3': '4157.1kWh', '2019 4': '4097.25kWh', '2019 5': '4.484.925kWh', '2019 6': '4758.75kWh', '2019 7': '5.266.125kWh', '2019 8': '5.126.625kWh', '2019 9': '4428kWh', '2019 10': '3.689.775kWh', '2019 11': '2673kWh', '2019 12': '2427.3kWh', '2020 1': '2511kWh', '2020 2': '2998.8kWh', '2020 3': '4157.1kWh', '2020 4': '4097.25kWh', '2020 5': '4.484.925kWh', '2020 6': '4758.75kWh', '2020 7': '5.266.125kWh', '2020 8': '5.126.625kWh', '2020 9': '4428kWh', '2020 10': '3.689.775kWh', '2020 11': '2673kWh', '2020 12': '2427.3kWh', '2021 1': '1377kWh', '2021 2': '2998.8kWh', '2021 3': '4157.1kWh'}, 
        'longitude': -3.69532569789638, 
        'latitude': 40.47125427, 
        'capacity': 25.0}, 
    'CDM ARGANZUELA LA FUNDI': {
        'id': 2258, 'energy': {'2020 1': '1004.4kWh', '2020 2': '1199.52kWh', '2020 3': '1662.84kWh', '2020 4': '1638.9kWh', '2020 5': '1793.97kWh', '2020 6': '1903.5kWh', '2020 7': '2106.45kWh', '2020 8': '2050.65kWh', '2020 9': '1771.2kWh', '2020 10': '1475.91kWh', '2020 11': '1069.2kWh', '2020 12': '970.92kWh', '2021 1': '550.8kWh', '2021 2': '1199.52kWh', '2021 3': '1662.84kWh'}, 
        'longitude': -3.6913410601461, 
        'latitude': 40.39437716, 'capacity': 10.0}, 
    'CDM EL ESPINILLO': {
        'id': 2258, 
        'energy': {'2020 1': '1004.4kWh', '2020 2': '1199.52kWh', '2020 3': '1662.84kWh', '2020 4': '1638.9kWh', '2020 5': '1793.97kWh', '2020 6': '1903.5kWh', '2020 7': '2106.45kWh', '2020 8': '2050.65kWh', '2020 9': '1771.2kWh', '2020 10': '1475.91kWh', '2020 11': '1069.2kWh', '2020 12': '970.92kWh', '2021 1': '550.8kWh', '2021 2': '1199.52kWh', '2021 3': '1481kWh'}, 
        'longitude': -3.68659514182063, 
        'latitude': 40.35634194, 
        'capacity': 10.0}, 
    'CDM FÉLIX RUBIO': {
        'id': 2258, 
        'energy': {'2019 1': '823.608kWh', '2019 2': '9.836.064kWh', '2019 3': '13.635.288kWh', '2019 4': '1.343.898kWh', '2019 5': '14.710.554kWh', '2019 6': '1560.87kWh', '2019 7': '1.727.289kWh', '2019 8': '1.681.533kWh', '2019 9': '1.452.384kWh', '2019 10': '12.102.462kWh', '2019 11': '876.744kWh', '2019 12': '7.961.544kWh', '2020 1': '823.608kWh', '2020 2': '9.836.064kWh', '2020 3': '13.635.288kWh', '2020 4': '1.343.898kWh', '2020 5': '14.710.554kWh', '2020 6': '1560.87kWh', '2020 7': '1.727.289kWh', '2020 8': '1.681.533kWh', '2020 9': '1.452.384kWh', '2020 10': '12.102.462kWh', '2020 11': '876.744kWh', '2020 12': '7.961.544kWh', '2021 1': '451.656kWh', '2021 2': '9.836.064kWh', '2021 3': '1518kWh'}, 
        'longitude': -3.69152880469694, 
        'latitude': 40.35742968, 
        'capacity': 82.0}, 
    'CDM GALLUR': {
        'id': 2258, 
        'energy': {'2020 1': '2008.8kWh', '2020 2': '2399.04kWh', '2020 3': '3325.68kWh', '2020 4': '3277.8kWh', '2020 5': '3587.94kWh', '2020 6': '3807kWh', '2020 7': '4212.9kWh', '2020 8': '4101.3kWh', '2020 9': '3542.4kWh', '2020 10': '2951.82kWh', '2020 11': '2138.4kWh', '2020 12': '1941.84kWh', '2021 1': '1101.6kWh', '2021 2': '2399.04kWh', '2021 3': '3325.68kWh'}, 
        'longitude': -3.73538345068692, 
        'latitude': 40.39846583, 
        'capacity': 20.0}, 
    'CDM LA MASÓ': {
        'id': 2258, 
        'energy': {'2020 1': '703.08kWh', '2020 2': '839.664kWh', '2020 3': '1.163.988kWh', '2020 4': '1147.23kWh', '2020 5': '1.255.779kWh', '2020 6': '1332.45kWh', '2020 7': '1.474.515kWh', '2020 8': '1.435.455kWh', '2020 9': '1239.84kWh', '2020 10': '1.033.137kWh', '2020 11': '748.44kWh', '2020 12': '679.644kWh', '2021 1': '385.56kWh', '2021 2': '839.664kWh', '2021 3': '1.163.988kWh'}, 
        'longitude': -3.71442225647198, 
        'latitude': 40.484426, 
        'capacity': 7.0}, 
    'CDM LAS CRUCES': {
        'id': 2258, 
        'energy': {'2019 2': '2.039.184kWh', '2019 3': '2.826.828kWh', '2019 4': '2786.13kWh', '2019 5': '3.049.749kWh', '2019 6': '3235.95kWh', '2019 7': '3.580.965kWh', '2019 8': '3.486.105kWh', '2019 9': '3011.04kWh', '2019 10': '2.509.047kWh', '2019 11': '1817.64kWh', '2019 12': '1.650.564kWh', '2020 1': '1707.48kWh', '2020 2': '2.039.184kWh', '2020 3': '2.826.828kWh', '2020 4': '2786.13kWh', '2020 5': '3.049.749kWh', '2020 6': '3235.95kWh', '2020 7': '3.580.965kWh', '2020 8': '3.486.105kWh', '2020 9': '3011.04kWh', '2020 10': '2.509.047kWh', '2020 11': '1817.64kWh', '2020 12': '1.650.564kWh', '2021 1': '936.36kWh', '2021 2': '2.039.184kWh', '2021 3': '2117kWh'}, 
        'longitude': -3.75655476241196, 
        'latitude': 40.37914894, 
        'capacity': 17.0}, 
    'CDM LUIS ARAGONÉS': {
        'id': 2258, 
        'energy': {'2019 1': '1255.5kWh', '2019 2': '1499.4kWh', '2019 3': '2078.55kWh', '2019 4': '2.048.625kWh', '2019 5': '22.424.625kWh', '2019 6': '2.379.375kWh', '2019 7': '26.330.625kWh', '2019 8': '25.633.125kWh', '2019 9': '2214kWh', '2019 10': '18.448.875kWh', '2019 11': '1336.5kWh', '2019 12': '1213.65kWh', '2020 1': '1255.5kWh', '2020 2': '1499.4kWh', '2020 3': '2078.55kWh', '2020 4': '2.048.625kWh', '2020 5': '22.424.625kWh', '2020 6': '2.379.375kWh', '2020 7': '26.330.625kWh', '2020 8': '25.633.125kWh', '2020 9': '2214kWh', '2020 10': '18.448.875kWh', '2020 11': '1336.5kWh', '2020 12': '1213.65kWh', '2021 1': '688.5kWh', '2021 2': '1499.4kWh', '2021 3': '1835kWh'}, 
        'longitude': -3.62758771450258, 
        'latitude': 40.46465095, 
        'capacity': 125.0}, 
    'CDM MARQUÉS DE SAMARANCH': {
        'id': 2258, 
        'energy': {'2019 3': '14965.56kWh', '2019 4': '14750.1kWh', '2019 5': '16145.73kWh', '2019 6': '17131.5kWh', '2019 7': '18958.05kWh', '2019 8': '18455.85kWh', '2019 9': '15940.8kWh', '2019 10': '13283.19kWh', '2019 11': '9622.8kWh', '2019 12': '8738.28kWh', '2020 1': '9039.6kWh', '2020 2': '10795.68kWh', '2020 3': '14965.56kWh', '2020 4': '14750.1kWh', '2020 5': '16145.73kWh', '2020 6': '17131.5kWh', '2020 7': '18958.05kWh', '2020 8': '18455.85kWh', '2020 9': '15940.8kWh', '2020 10': '13283.19kWh', '2020 11': '9622.8kWh', '2020 12': '8738.28kWh', '2021 1': '4957.2kWh', '2021 2': '10795.68kWh', '2021 3': '14965.56kWh'}, 
        'longitude': -3.71738072219391, 
        'latitude': 40.40761501, 
        'capacity': 90.0}, 
    'CDM MORATALAZ': {
        'id': 2258, 
        'energy': {'2019 4': '6555.6kWh', '2019 5': '7175.88kWh', '2019 6': '7614kWh', '2019 7': '8425.8kWh', '2019 8': '8202.6kWh', '2019 9': '7084.8kWh', '2019 10': '5903.64kWh', '2019 11': '4276.8kWh', '2019 12': '3883.68kWh', '2020 1': '4017.6kWh', '2020 2': '4798.08kWh', '2020 3': '6651.36kWh', '2020 4': '6555.6kWh', '2020 5': '7175.88kWh', '2020 6': '7614kWh', '2020 7': '8425.8kWh', '2020 8': '8202.6kWh', '2020 9': '7084.8kWh', '2020 10': '5903.64kWh', '2020 11': '4276.8kWh', '2020 12': '3883.68kWh', '2021 1': '2203.2kWh', '2021 2': '4798.08kWh', '2021 3': '6651.36kWh'}, 
        'longitude': -3.63243229021453, 
        'latitude': 40.39885855, 
        'capacity': 40.0}, 
    'CDM ORCASUR': {
        'id': 2258, 
        'energy': {'2019 3': '2909.97kWh', '2019 4': '2.868.075kWh', '2019 5': '31.394.475kWh', '2019 6': '3.331.125kWh', '2019 7': '36.862.875kWh', '2019 8': '35.886.375kWh', '2019 9': '3099.6kWh', '2019 10': '25.828.425kWh', '2019 11': '1871.1kWh', '2019 12': '1699.11kWh', '2020 1': '1757.7kWh', '2020 2': '2099.16kWh', '2020 3': '2909.97kWh', '2020 4': '2.868.075kWh', '2020 5': '31.394.475kWh', '2020 6': '3.331.125kWh', '2020 7': '36.862.875kWh', '2020 8': '35.886.375kWh', '2020 9': '3099.6kWh', '2020 10': '25.828.425kWh', '2020 11': '1871.1kWh', '2020 12': '1699.11kWh', '2021 1': '963.9kWh', '2021 2': '2099.16kWh', '2021 3': '2909.97kWh'}, 'longitude': -3.69946890060774, 
        'latitude': 40.37021993, 
        'capacity': 175.0}, 
    'CDM VICENTE DEL BOSQUE': {
        'id': 2258, 
        'energy': {'2019 6': '9517.5kWh', '2019 7': '10532.25kWh', '2019 8': '10253.25kWh', '2019 9': '8856kWh', '2019 10': '7379.55kWh', '2019 11': '5346kWh', '2019 12': '4854.6kWh', '2020 1': '5022kWh', '2020 2': '5997.6kWh', '2020 3': '8314.2kWh', '2020 4': '8194.5kWh', '2020 5': '8969.85kWh', '2020 6': '9517.5kWh', '2020 7': '10532.25kWh', '2020 8': '10253.25kWh', '2020 9': '8856kWh', '2020 10': '7379.55kWh', '2020 11': '5346kWh', '2020 12': '4854.6kWh', '2021 1': '2754kWh', '2021 2': '5997.6kWh', '2021 3': '8314.2kWh'}, 
        'longitude': -3.69354065974728, 
        'latitude': 40.47891359, 
        'capacity': 50.0}, 
    'CDM VICÁLVARO (MARGOT MOLÉS)': {
        'id': 2258, 'energy': {'2019 1': '4017.6kWh', '2019 2': '4798.08kWh', '2019 3': '6651.36kWh', '2019 4': '6555.6kWh', '2019 5': '7175.88kWh', '2019 6': '7614kWh', '2019 7': '8425.8kWh', '2019 8': '8202.6kWh', '2019 9': '7084.8kWh', '2019 10': '5903.64kWh', '2019 11': '4276.8kWh', '2019 12': '3883.68kWh', '2020 1': '4017.6kWh', '2020 2': '4798.08kWh', '2020 3': '6651.36kWh', '2020 4': '6555.6kWh', '2020 5': '7175.88kWh', '2020 6': '7614kWh', '2020 7': '8425.8kWh', '2020 8': '8202.6kWh', '2020 9': '7084.8kWh', '2020 10': '5903.64kWh', '2020 11': '4276.8kWh', '2020 12': '3883.68kWh', '2021 1': '2203.2kWh', '2021 2': '4798.08kWh', '2021 3': '6651.36kWh'}, 
        'longitude': -3.60326473285039, 
        'latitude': 40.40914062, 
        'capacity': 40.0}, 
    'CEIP CIUDAD DE ROMA': {
        'id': 2258, 
        'energy': {'2019 4': '6555.6kWh', '2019 5': '7175.88kWh', '2019 6': '7614kWh', '2019 7': '8425.8kWh', '2019 8': '8202.6kWh', '2019 9': '7084.8kWh', '2019 10': '5903.64kWh', '2019 11': '4276.8kWh', '2019 12': '3883.68kWh', '2020 1': '4017.6kWh', '2020 2': '4798.08kWh', '2020 3': '6651.36kWh', '2020 4': '6555.6kWh', '2020 5': '7175.88kWh', '2020 6': '7614kWh', '2020 7': '8425.8kWh', '2020 8': '8202.6kWh', '2020 9': '7084.8kWh', '2020 10': '5903.64kWh', '2020 11': '4276.8kWh', '2020 12': '3883.68kWh', '2021 1': '2203.2kWh', '2021 2': '4798.08kWh', '2021 3': '6651.36kWh'}, 
        'longitude': -3.66520562618556, 
        'latitude': 40.41586395, 
        'capacity': 40.0}, 
    'CEIP FERNANDO EL CATÓLICO': {
        'id': 2258, 
        'energy': {'2019 1': '1255.5kWh', '2019 2': '1499.4kWh', '2019 3': '2078.55kWh', '2019 4': '2.048.625kWh', '2019 5': '22.424.625kWh', '2019 6': '2.379.375kWh', '2019 7': '26.330.625kWh', '2019 8': '25.633.125kWh', '2019 9': '2214kWh', '2019 10': '18.448.875kWh', '2019 11': '1336.5kWh', '2019 12': '1213.65kWh', '2020 1': '1255.5kWh', '2020 2': '1499.4kWh', '2020 3': '2078.55kWh', '2020 4': '2.048.625kWh', '2020 5': '22.424.625kWh', '2020 6': '2.379.375kWh', '2020 7': '26.330.625kWh', '2020 8': '25.633.125kWh', '2020 9': '2214kWh', '2020 10': '18.448.875kWh', '2020 11': '1336.5kWh', '2020 12': '1213.65kWh', '2021 1': '688.5kWh', '2021 2': '1499.4kWh', '2021 3': '1982kWh'}, 
        'longitude': -3.70973929433482, 
        'latitude': 40.43410369, 
        'capacity': 125.0}, 
    'CEIP JOSE CALVO SOTELO': {
        'id': 2258, 
        'energy': {'2019 4': '1147.23kWh', '2019 5': '1.255.779kWh', '2019 6': '1332.45kWh', '2019 7': '1.474.515kWh', '2019 8': '1.435.455kWh', '2019 9': '1239.84kWh', '2019 10': '1.033.137kWh', '2019 11': '748.44kWh', '2019 12': '679.644kWh', '2020 1': '703.08kWh', '2020 2': '839.664kWh', '2020 3': '1.163.988kWh', '2020 4': '1147.23kWh', '2020 5': '1.255.779kWh', '2020 6': '1332.45kWh', '2020 7': '1.474.515kWh', '2020 8': '1.435.455kWh', '2020 9': '1239.84kWh', '2020 10': '1.033.137kWh', '2020 11': '748.44kWh', '2020 12': '679.644kWh', '2021 1': '385.56kWh', '2021 2': '839.664kWh', '2021 3': '713kWh'}, 
        'longitude': -3.67376625240067, 
        'latitude': 40.40084131, 
        'capacity': 7.0}, 
    'CENTRO DE ACOGIDA MUNICIPAL PUERTA ABIERTA': {
        'id': 2258, 
        'energy': {'2019 1': '1205.28kWh', '2019 2': '1.439.424kWh', '2019 3': '1.995.408kWh', '2019 4': '1966.68kWh', '2019 5': '2.152.764kWh', '2019 6': '2284.2kWh', '2019 7': '2527.74kWh', '2019 8': '2460.78kWh', '2019 9': '2125.44kWh', '2019 10': '1.771.092kWh', '2019 11': '1283.04kWh', '2019 12': '1.165.104kWh', '2020 1': '1205.28kWh', '2020 2': '1.439.424kWh', '2020 3': '1.995.408kWh', '2020 4': '1966.68kWh', '2020 5': '2.152.764kWh', '2020 6': '2284.2kWh', '2020 7': '2527.74kWh', '2020 8': '2460.78kWh', '2020 9': '2125.44kWh', '2020 10': '1.771.092kWh', '2020 11': '1283.04kWh', '2020 12': '1.165.104kWh', '2021 1': '660.96kWh', '2021 2': '1.439.424kWh', '2021 3': '1.995.408kWh'}, 
        'longitude': -3.77604986037844, 
        'latitude': 40.36362322, 
        'capacity': 12.0}, 
    'CENTRO DE DÍA GERTRUDIS DE LA FUENTE': {
        'id': 2258, 
        'energy': {'2019 6': '9517.5kWh', '2019 7': '10532.25kWh', '2019 8': '10253.25kWh', '2019 9': '8856kWh', '2019 10': '7379.55kWh', '2019 11': '5346kWh', '2019 12': '4854.6kWh', '2020 1': '5022kWh', '2020 2': '5997.6kWh', '2020 3': '8314.2kWh', '2020 4': '8194.5kWh', '2020 5': '8969.85kWh', '2020 6': '9517.5kWh', '2020 7': '10532.25kWh', '2020 8': '10253.25kWh', '2020 9': '8856kWh', '2020 10': '7379.55kWh', '2020 11': '5346kWh', '2020 12': '4854.6kWh', '2021 1': '2754kWh', '2021 2': '5997.6kWh', '2021 3': '8314.2kWh'}, 
        'longitude': -3.74165624171805, 
        'latitude': 40.39922282, 
        'capacity': 50.0}, 
    'CENTRO DE DÍA Y DE MAYORES ASCAO/\nBIBLIOTECA PABLO NERUDA': {
        'id': 2258, 
        'energy': {'2019 1': '1757.7kWh', '2019 2': '2099.16kWh', '2019 3': '2909.97kWh', '2019 4': '2.868.075kWh', '2019 5': '31.394.475kWh', '2019 6': '3.331.125kWh', '2019 7': '36.862.875kWh', '2019 8': '35.886.375kWh', '2019 9': '3099.6kWh', '2019 10': '25.828.425kWh', '2019 11': '1871.1kWh', '2019 12': '1699.11kWh', '2020 1': '1757.7kWh', '2020 2': '2099.16kWh', '2020 3': '2909.97kWh', '2020 4': '2.868.075kWh', '2020 5': '31.394.475kWh', '2020 6': '3.331.125kWh', '2020 7': '36.862.875kWh', '2020 8': '35.886.375kWh', '2020 9': '3099.6kWh', '2020 10': '25.828.425kWh', '2020 11': '1871.1kWh', '2020 12': '1699.11kWh', '2021 1': '963.9kWh', '2021 2': '2099.16kWh', '2021 3': '2488kWh'}, 
        'longitude': -3.64134974147187, 
        'latitude': 40.42958439, 
        'capacity': 175.0}, 
    'CENTRO PROTECCIÓN ANIMAL': {
        'id': 2258, 
        'energy': {'2019 1': '9.923.472kWh', '2019 2': '118.512.576kWh', '2019 3': '164.288.592kWh', '2019 4': '16.192.332kWh', '2019 5': '177.244.236kWh', '2019 6': '1.880.658kWh', '2019 7': '20.811.726kWh', '2019 8': '20.260.422kWh', '2019 9': '17.499.456kWh', '2019 10': '145.819.908kWh', '2019 11': '10.563.696kWh', '2019 12': '95.926.896kWh', '2020 1': '9.923.472kWh', '2020 2': '118.512.576kWh', '2020 3': '164.288.592kWh', '2020 4': '16.192.332kWh', '2020 5': '177.244.236kWh', '2020 6': '1.880.658kWh', '2020 7': '20.811.726kWh', '2020 8': '20.260.422kWh', '2020 9': '17.499.456kWh', '2020 10': '145.819.908kWh', '2020 11': '10.563.696kWh', '2020 12': '95.926.896kWh', '2021 1': '5.441.904kWh', '2021 2': '118.512.576kWh', '2021 3': '164.288.592kWh'}, 
        'longitude': -3.77811006728668, 
        'latitude': 40.36354631, 
        'capacity': 988.0}, 
    'CMM ACUARIO': {
        'id': 2258, 
        'energy': {'2019 3': '19.455.228kWh', '2019 4': '1.917.513kWh', '2019 5': '20.989.449kWh', '2019 6': '2.227.095kWh', '2019 7': '24.645.465kWh', '2019 8': '23.992.605kWh', '2019 9': '2.072.304kWh', '2019 10': '17.268.147kWh', '2019 11': '1.250.964kWh', '2019 12': '11.359.764kWh', '2020 1': '1.175.148kWh', '2020 2': '14.034.384kWh', '2020 3': '19.455.228kWh', '2020 4': '1.917.513kWh', '2020 5': '20.989.449kWh', '2020 6': '2.227.095kWh', '2020 7': '24.645.465kWh', '2020 8': '23.992.605kWh', '2020 9': '2.072.304kWh', '2020 10': '17.268.147kWh', '2020 11': '1.250.964kWh', '2020 12': '11.359.764kWh', '2021 1': '644.436kWh', '2021 2': '14.034.384kWh', '2021 3': '828kWh'}, 
        'longitude': -3.57969145174686, 
        'latitude': 40.47480197, 
        'capacity': 117.0}, 
    'CMM ISLAS FILIPINAS, CENTRO JUVENIL Y BIBLIOTECA LA ELIPA': {
        'id': 2258, 'energy': {'2019 1': '1506.6kWh', '2019 2': '1799.28kWh', '2019 3': '2494.26kWh', '2019 4': '2458.35kWh', '2019 5': '2.690.955kWh', '2019 6': '2855.25kWh', '2019 7': '3.159.675kWh', '2019 8': '3.075.975kWh', '2019 9': '2656.8kWh', '2019 10': '2.213.865kWh', '2019 11': '1603.8kWh', '2019 12': '1456.38kWh', '2020 1': '1506.6kWh', '2020 2': '1799.28kWh', '2020 3': '2494.26kWh', '2020 4': '2458.35kWh', '2020 5': '2.690.955kWh', '2020 6': '2855.25kWh', '2020 7': '3.159.675kWh', '2020 8': '3.075.975kWh', '2020 9': '2656.8kWh', '2020 10': '2.213.865kWh', '2020 11': '1603.8kWh', '2020 12': '1456.38kWh', '2021 1': '826.2kWh', '2021 2': '1799.28kWh', '2021 3': '1715kWh'}, 
        'longitude': -3.65184164138948, 
        'latitude': 40.42342659, 
        'capacity': 15.0}, 
    'CMM LA ESTRELLA - CARMEN MARTÍN GAITE': {
        'id': 2258, 
        'energy': {'2019 1': '1255.5kWh', '2019 2': '1499.4kWh', '2019 3': '2078.55kWh', '2019 4': '2.048.625kWh', '2019 5': '22.424.625kWh', '2019 6': '2.379.375kWh', '2019 7': '26.330.625kWh', '2019 8': '25.633.125kWh', '2019 9': '2214kWh', '2019 10': '18.448.875kWh', '2019 11': '1336.5kWh', '2019 12': '1213.65kWh', '2020 1': '1255.5kWh', '2020 2': '1499.4kWh', '2020 3': '2078.55kWh', '2020 4': '2.048.625kWh', '2020 5': '22.424.625kWh', '2020 6': '2.379.375kWh', '2020 7': '26.330.625kWh', '2020 8': '25.633.125kWh', '2020 9': '2214kWh', '2020 10': '18.448.875kWh', '2020 11': '1336.5kWh', '2020 12': '1213.65kWh', '2021 1': '688.5kWh', '2021 2': '1499.4kWh', '2021 3': '2078.55kWh'}, 
        'longitude': -3.66620904783224, 
        'latitude': 40.41337872, 
        'capacity': 125.0}, 
    'CMM ORCASUR': {
        'id': 2258, 
        'energy': {'2019 1': '823.608kWh', '2019 2': '9.836.064kWh', '2019 3': '13.635.288kWh', '2019 4': '1.343.898kWh', '2019 5': '14.710.554kWh', '2019 6': '1560.87kWh', '2019 7': '1.727.289kWh', '2019 8': '1.681.533kWh', '2019 9': '1.452.384kWh', '2019 10': '12.102.462kWh', '2019 11': '876.744kWh', '2019 12': '7.961.544kWh', '2020 1': '823.608kWh', '2020 2': '9.836.064kWh', '2020 3': '13.635.288kWh', '2020 4': '1.343.898kWh', '2020 5': '14.710.554kWh', '2020 6': '1560.87kWh', '2020 7': '1.727.289kWh', '2020 8': '1.681.533kWh', '2020 9': '1.452.384kWh', '2020 10': '12.102.462kWh', '2020 11': '876.744kWh', '2020 12': '7.961.544kWh', '2021 1': '451.656kWh', '2021 2': '9.836.064kWh', '2021 3': '1157kWh'}, 
        'longitude': -3.70012965685136, 
        'latitude': 40.36805375, 
        'capacity': 82.0}, 
    'CMM VALLEHERMOSO': {
        'id': 2258, 
        'energy': {'2019 1': '502.2kWh', '2019 2': '599.76kWh', '2019 3': '831.42kWh', '2019 4': '819.45kWh', '2019 5': '896.985kWh', '2019 6': '951.75kWh', '2019 7': '1.053.225kWh', '2019 8': '1.025.325kWh', '2019 9': '885.6kWh', '2019 10': '737.955kWh', '2019 11': '534.6kWh', '2019 12': '485.46kWh', '2020 1': '502.2kWh', '2020 2': '599.76kWh', '2020 3': '831.42kWh', '2020 4': '819.45kWh', '2020 5': '896.985kWh', '2020 6': '951.75kWh', '2020 7': '1.053.225kWh', '2020 8': '1.025.325kWh', '2020 9': '885.6kWh', '2020 10': '737.955kWh', '2020 11': '534.6kWh', '2020 12': '485.46kWh', '2021 1': '275.4kWh', '2021 2': '599.76kWh', '2021 3': '831.42kWh'}, 
        'longitude': -3.708785, 
        'latitude': 40.434646, 
        'capacity': 5.0}, 
    'CMM VILLA DE VALLECAS ': {
        'id': 2258, 
        'energy': {'2019 1': '602.64kWh', '2019 2': '719.712kWh', '2019 3': '997.704kWh', '2019 4': '983.34kWh', '2019 5': '1.076.382kWh', '2019 6': '1142.1kWh', '2019 7': '1263.87kWh', '2019 8': '1230.39kWh', '2019 9': '1062.72kWh', '2019 10': '885.546kWh', '2019 11': '641.52kWh', '2019 12': '582.552kWh', '2020 1': '602.64kWh', '2020 2': '719.712kWh', '2020 3': '997.704kWh', '2020 4': '983.34kWh', '2020 5': '1.076.382kWh', '2020 6': '1142.1kWh', '2020 7': '1263.87kWh', '2020 8': '1230.39kWh', '2020 9': '1062.72kWh', '2020 10': '885.546kWh', '2020 11': '641.52kWh', '2020 12': '582.552kWh', '2021 1': '330.48kWh', '2021 2': '719.712kWh', '2021 3': '727kWh'}, 
        'longitude': -3.62124004842169, 
        'latitude': 40.37732438, 
        'capacity': 6.0}, 
    'CMM/CENTRO DIA/CSS LA GUINDALERA': {
        'id': 2258, 
        'energy': {'2019 1': '371.628kWh', '2019 2': '4.438.224kWh', '2019 3': '6.152.508kWh', '2019 4': '606.393kWh', '2019 5': '6.637.689kWh', '2019 6': '704.295kWh', '2019 7': '7.793.865kWh', '2019 8': '7.587.405kWh', '2019 9': '655.344kWh', '2019 10': '5.460.867kWh', '2019 11': '395.604kWh', '2019 12': '3.592.404kWh', '2020 1': '371.628kWh', '2020 2': '4.438.224kWh', '2020 3': '6.152.508kWh', '2020 4': '606.393kWh', '2020 5': '6.637.689kWh', '2020 6': '704.295kWh', '2020 7': '7.793.865kWh', '2020 8': '7.587.405kWh', '2020 9': '655.344kWh', '2020 10': '5.460.867kWh', '2020 11': '395.604kWh', '2020 12': '3.592.404kWh', '2021 1': '203.796kWh', '2021 2': '4.438.224kWh', '2021 3': '462kWh'}, 
        'longitude': -3.66911677846917, 
        'latitude': 40.433499100000006, 
        'capacity': 37.0}, 
    'CMM/CSS LADERA EL ALMENDRO': {
        'id': 2258, 
        'energy': {'2019 1': '1255.5kWh', '2019 2': '1499.4kWh', '2019 3': '2078.55kWh', '2019 4': '2.048.625kWh', '2019 5': '22.424.625kWh', '2019 6': '2.379.375kWh', '2019 7': '26.330.625kWh', '2019 8': '25.633.125kWh', '2019 9': '2214kWh', '2019 10': '18.448.875kWh', '2019 11': '1336.5kWh', '2019 12': '1213.65kWh', '2020 1': '1255.5kWh', '2020 2': '1499.4kWh', '2020 3': '2078.55kWh', '2020 4': '2.048.625kWh', '2020 5': '22.424.625kWh', '2020 6': '2.379.375kWh', '2020 7': '26.330.625kWh', '2020 8': '25.633.125kWh', '2020 9': '2214kWh', '2020 10': '18.448.875kWh', '2020 11': '1336.5kWh', '2020 12': '1213.65kWh', '2021 1': '688.5kWh', '2021 2': '1499.4kWh', '2021 3': '1688kWh'}, 
        'longitude': -3.62391894122114, 
        'latitude': 40.40210302, 
        'capacity': 125.0}, 
    'CSS  Y APARTAMENTOS TUTELADOS RETIRO': {
        'id': 2258, 
        'energy': {'2019 4': '2.048.625kWh', '2019 5': '22.424.625kWh', '2019 6': '2.379.375kWh', '2019 7': '26.330.625kWh', '2019 8': '25.633.125kWh', '2019 9': '2214kWh', '2019 10': '18.448.875kWh', '2019 11': '1336.5kWh', '2019 12': '1213.65kWh', '2020 1': '1255.5kWh', '2020 2': '1499.4kWh', '2020 3': '2078.55kWh', '2020 4': '2.048.625kWh', '2020 5': '22.424.625kWh', '2020 6': '2.379.375kWh', '2020 7': '26.330.625kWh', '2020 8': '25.633.125kWh', '2020 9': '2214kWh', '2020 10': '18.448.875kWh', '2020 11': '1336.5kWh', '2020 12': '1213.65kWh', '2021 1': '688.5kWh', '2021 2': '1499.4kWh', '2021 3': '1403kWh'}, 
        'longitude': -3.66777459044681, 
        'latitude': 40.4146149, 
        'capacity': 125.0}, 
    'CSS JOSÉ VILLARREAL (ARGANZUELA)': {
        'id': 2258, 
        'energy': {'2019 1': '1707.48kWh', '2019 2': '2.039.184kWh', '2019 3': '2.826.828kWh', '2019 4': '2786.13kWh', '2019 5': '3.049.749kWh', '2019 6': '3235.95kWh', '2019 7': '3.580.965kWh', '2019 8': '3.486.105kWh', '2019 9': '3011.04kWh', '2019 10': '2.509.047kWh', '2019 11': '1817.64kWh', '2019 12': '1.650.564kWh', '2020 1': '1707.48kWh', '2020 2': '2.039.184kWh', '2020 3': '2.826.828kWh', '2020 4': '2786.13kWh', '2020 5': '3.049.749kWh', '2020 6': '3235.95kWh', '2020 7': '3.580.965kWh', '2020 8': '3.486.105kWh', '2020 9': '3011.04kWh', '2020 10': '2.509.047kWh', '2020 11': '1817.64kWh', '2020 12': '1.650.564kWh', '2021 1': '936.36kWh', '2021 2': '2.039.184kWh', '2021 3': '2.826.828kWh'}, 
        'longitude': -3.69705214496161, 
        'latitude': 40.39401854, 
        'capacity': 17.0}, 
    'CSS PUERTO DE LAS PILAS': {
        'id': 2258, 
        'energy': {'2019 1': '2008.8kWh', '2019 2': '2399.04kWh', '2019 3': '3325.68kWh', '2019 4': '3277.8kWh', '2019 5': '3587.94kWh', '2019 6': '3807kWh', '2019 7': '4212.9kWh', '2019 8': '4101.3kWh', '2019 9': '3542.4kWh', '2019 10': '2951.82kWh', '2019 11': '2138.4kWh', '2019 12': '1941.84kWh', '2020 1': '2008.8kWh', '2020 2': '2399.04kWh', '2020 3': '3325.68kWh', '2020 4': '3277.8kWh', '2020 5': '3587.94kWh', '2020 6': '3807kWh', '2020 7': '4212.9kWh', '2020 8': '4101.3kWh', '2020 9': '3542.4kWh', '2020 10': '2951.82kWh', '2020 11': '2138.4kWh', '2020 12': '1941.84kWh', '2021 1': '1101.6kWh', '2021 2': '2399.04kWh', '2021 3': '1799kWh'}, 
        'longitude': -3.621679, 
        'latitude': 40.370701000000004, 
        'capacity': 20.0}, 
    'CSS SAN FILIBERTO': {
        'id': 2258, 
        'energy': {'2019 1': '1255.5kWh', '2019 2': '1499.4kWh', '2019 3': '2078.55kWh', '2019 4': '2.048.625kWh', '2019 5': '22.424.625kWh', '2019 6': '2.379.375kWh', '2019 7': '26.330.625kWh', '2019 8': '25.633.125kWh', '2019 9': '2214kWh', '2019 10': '18.448.875kWh', '2019 11': '1336.5kWh', '2019 12': '1213.65kWh', '2020 1': '1255.5kWh', '2020 2': '1499.4kWh', '2020 3': '2078.55kWh', '2020 4': '2.048.625kWh', '2020 5': '22.424.625kWh', '2020 6': '2.379.375kWh', '2020 7': '26.330.625kWh', '2020 8': '25.633.125kWh', '2020 9': '2214kWh', '2020 10': '18.448.875kWh', '2020 11': '1336.5kWh', '2020 12': '1213.65kWh', '2021 1': '688.5kWh', '2021 2': '1499.4kWh', '2021 3': '753kWh'}, 
        'longitude': -3.71518188811652, 
        'latitude': 40.38581768, 
        'capacity': 125.0}, 
    'CSS SAN VICENTE DE PAUL': {
        'id': 2258, 
        'energy': {'2019 1': '522.288kWh', '2019 2': '6.237.504kWh', '2019 3': '8.646.768kWh', '2019 4': '852.228kWh', '2019 5': '9.328.644kWh', '2019 6': '989.82kWh', '2019 7': '1.095.354kWh', '2019 8': '1.066.338kWh', '2019 9': '921.024kWh', '2019 10': '7.674.732kWh', '2019 11': '555.984kWh', '2019 12': '5.048.784kWh', '2020 1': '522.288kWh', '2020 2': '6.237.504kWh', '2020 3': '8.646.768kWh', '2020 4': '852.228kWh', '2020 5': '9.328.644kWh', '2020 6': '989.82kWh', '2020 7': '1.095.354kWh', '2020 8': '1.066.338kWh', '2020 9': '921.024kWh', '2020 10': '7.674.732kWh', '2020 11': '555.984kWh', '2020 12': '5.048.784kWh', '2021 1': '286.416kWh', '2021 2': '6.237.504kWh', '2021 3': '8.646.768kWh'}, 
        'longitude': -3.71660783300847, 
        'latitude': 40.47248463, 
        'capacity': 52.0}, 
    'EI BOLA DE CRISTAL (VALDEZARZA)': {
        'id': 2258, 
        'energy': {'2019 1': '602.64kWh', '2019 2': '719.712kWh', '2019 3': '997.704kWh', '2019 4': '983.34kWh', '2019 5': '1.076.382kWh', '2019 6': '1142.1kWh', '2019 7': '1263.87kWh', '2019 8': '1230.39kWh', '2019 9': '1062.72kWh', '2019 10': '885.546kWh', '2019 11': '641.52kWh', '2019 12': '582.552kWh', '2020 1': '602.64kWh', '2020 2': '719.712kWh', '2020 3': '997.704kWh', '2020 4': '983.34kWh', '2020 5': '1.076.382kWh', '2020 6': '1142.1kWh', '2020 7': '1263.87kWh', '2020 8': '1230.39kWh', '2020 9': '1062.72kWh', '2020 10': '885.546kWh', '2020 11': '641.52kWh', '2020 12': '582.552kWh', '2021 1': '330.48kWh', '2021 2': '719.712kWh', '2021 3': '997.704kWh'}, 
        'longitude': -3.715308, 
        'latitude': 40.467857, 
        'capacity': 6.0}, 
    'EI EL FRESNO': {
        'id': 2258, 
        'energy': {'2019 5': '13.454.775kWh', '2019 6': '1.427.625kWh', '2019 7': '15.798.375kWh', '2019 8': '15.379.875kWh', '2019 9': '1328.4kWh', '2019 10': '11.069.325kWh', '2019 11': '801.9kWh', '2019 12': '728.19kWh', '2020 1': '753.3kWh', '2020 2': '899.64kWh', '2020 3': '1247.13kWh', '2020 4': '1.229.175kWh', '2020 5': '13.454.775kWh', '2020 6': '1.427.625kWh', '2020 7': '15.798.375kWh', '2020 8': '15.379.875kWh', '2020 9': '1328.4kWh', '2020 10': '11.069.325kWh', '2020 11': '801.9kWh', '2020 12': '728.19kWh', '2021 1': '413.1kWh', '2021 2': '899.64kWh', '2021 3': '1247.13kWh'}, 
        'longitude': -3.724127, 
        'latitude': 40.494405, 
        'capacity': 75.0},
    'EI ELENA FORTÚN (MONTECARMELO)': {
        'id': 2258, 
        'energy': {'2019 4': '1.229.175kWh', '2019 5': '13.454.775kWh', '2019 6': '1.427.625kWh', '2019 7': '15.798.375kWh', '2019 8': '15.379.875kWh', '2019 9': '1328.4kWh', '2019 10': '11.069.325kWh', '2019 11': '801.9kWh', '2019 12': '728.19kWh', '2020 1': '753.3kWh', '2020 2': '899.64kWh', '2020 3': '1247.13kWh', '2020 4': '1.229.175kWh', '2020 5': '13.454.775kWh', '2020 6': '1.427.625kWh', '2020 7': '15.798.375kWh', '2020 8': '15.379.875kWh', '2020 9': '1328.4kWh', '2020 10': '11.069.325kWh', '2020 11': '801.9kWh', '2020 12': '728.19kWh', '2021 1': '413.1kWh', '2021 2': '899.64kWh', '2021 3': '1247.13kWh'}, 
        'longitude': -3.694447,
        'latitude': 40.506657000000004, 
        'capacity': 75.0}, 
    'EI LA MELONERA ': {
        'id': 2258, 
        'energy': {'2019 5': '13.454.775kWh', '2019 6': '1.427.625kWh', '2019 7': '15.798.375kWh', '2019 8': '15.379.875kWh', '2019 9': '1328.4kWh', '2019 10': '11.069.325kWh', '2019 11': '801.9kWh', '2019 12': '728.19kWh', '2020 1': '753.3kWh', '2020 2': '899.64kWh', '2020 3': '1247.13kWh', '2020 4': '1.229.175kWh', '2020 5': '13.454.775kWh', '2020 6': '1.427.625kWh', '2020 7': '15.798.375kWh', '2020 8': '15.379.875kWh', '2020 9': '1328.4kWh', '2020 10': '11.069.325kWh', '2020 11': '801.9kWh', '2020 12': '728.19kWh', '2021 1': '413.1kWh', '2021 2': '899.64kWh', '2021 3': '1247.13kWh'}, 
        'longitude': -3.68055149170835, 
        'latitude': 40.39390298, 
        'capacity': 75.0}, 
    'EI MAMUT  (BUTARQUE)': {
        'id': 2258, 
        'energy': {'2019 2': '599.76kWh', '2019 3': '831.42kWh', '2019 4': '819.45kWh', '2019 5': '896.985kWh', '2019 6': '951.75kWh', '2019 7': '1.053.225kWh', '2019 8': '1.025.325kWh', '2019 9': '885.6kWh', '2019 10': '737.955kWh', '2019 11': '534.6kWh', '2019 12': '485.46kWh', '2020 1': '502.2kWh', '2020 2': '599.76kWh', '2020 3': '831.42kWh', '2020 4': '819.45kWh', '2020 5': '896.985kWh', '2020 6': '951.75kWh', '2020 7': '1.053.225kWh', '2020 8': '1.025.325kWh', '2020 9': '885.6kWh', '2020 10': '737.955kWh', '2020 11': '534.6kWh', '2020 12': '485.46kWh', '2021 1': '275.4kWh', '2021 2': '599.76kWh', '2021 3': '831.42kWh'}, 
        'longitude': -3.677163, 
        'latitude': 40.337689000000005, 
        'capacity': 5.0}, 
    'EI PENSAMIENTO (MIRASIERRA)': {
        'id': 2258, 'energy': {'2019 2': '11.635.344kWh', '2019 3': '16.129.548kWh', '2019 4': '1.589.733kWh', '2019 5': '17.401.509kWh', '2019 6': '1.846.395kWh', '2019 7': '20.432.565kWh', '2019 8': '19.891.305kWh', '2019 9': '1.718.064kWh', '2019 10': '14.316.327kWh', '2019 11': '1.037.124kWh', '2019 12': '9.417.924kWh', '2020 1': '974.268kWh', '2020 2': '11.635.344kWh', '2020 3': '16.129.548kWh', '2020 4': '1.589.733kWh', '2020 5': '17.401.509kWh', '2020 6': '1.846.395kWh', '2020 7': '20.432.565kWh', '2020 8': '19.891.305kWh', '2020 9': '1.718.064kWh', '2020 10': '14.316.327kWh', '2020 11': '1.037.124kWh', '2020 12': '9.417.924kWh', '2021 1': '534.276kWh', '2021 2': '11.635.344kWh', '2021 3': '16.129.548kWh'}, 
        'longitude': -3.602926, 
        'latitude': 40.409341, 
        'capacity': 97.0}, 
    'EI PLÉYADES': {
        'id': 2258, 
        'energy': {'2019 1': '823.608kWh', '2019 2': '9.836.064kWh', '2019 3': '13.635.288kWh', '2019 4': '1.343.898kWh', '2019 5': '14.710.554kWh', '2019 6': '1560.87kWh', '2019 7': '1.727.289kWh', '2019 8': '1.681.533kWh', '2019 9': '1.452.384kWh', '2019 10': '12.102.462kWh', '2019 11': '876.744kWh', '2019 12': '7.961.544kWh', '2020 1': '823.608kWh', '2020 2': '9.836.064kWh', '2020 3': '13.635.288kWh', '2020 4': '1.343.898kWh', '2020 5': '14.710.554kWh', '2020 6': '1560.87kWh', '2020 7': '1.727.289kWh', '2020 8': '1.681.533kWh', '2020 9': '1.452.384kWh', '2020 10': '12.102.462kWh', '2020 11': '876.744kWh', '2020 12': '7.961.544kWh', '2021 1': '451.656kWh', '2021 2': '9.836.064kWh', '2021 3': '13.635.288kWh'}, 
        'longitude': -3.62038557307297, 
        'latitude': 40.49533034, 
        'capacity': 82.0}, 
    'EI POETA GLORIA FUERTES (LAS TABLAS)': {
        'id': 2258, 
        'energy': {'2019 3': '1247.13kWh', '2019 4': '1.229.175kWh', '2019 5': '13.454.775kWh', '2019 6': '1.427.625kWh', '2019 7': '15.798.375kWh', '2019 8': '15.379.875kWh', '2019 9': '1328.4kWh', '2019 10': '11.069.325kWh', '2019 11': '801.9kWh', '2019 12': '728.19kWh', '2020 1': '753.3kWh', '2020 2': '899.64kWh', '2020 3': '1247.13kWh', '2020 4': '1.229.175kWh', '2020 5': '13.454.775kWh', '2020 6': '1.427.625kWh', '2020 7': '15.798.375kWh', '2020 8': '15.379.875kWh', '2020 9': '1328.4kWh', '2020 10': '11.069.325kWh', '2020 11': '801.9kWh', '2020 12': '728.19kWh', '2021 1': '413.1kWh', '2021 2': '899.64kWh', '2021 3': '1247.13kWh'}, 
        'longitude': -3.676283, 
        'latitude': 40.504951, 
        'capacity': 75.0}, 
    'EI SABINA (MORATALAZ)': {
        'id': 2258, 
        'energy': {'2019 1': '602.64kWh', '2019 2': '719.712kWh', '2019 3': '997.704kWh', '2019 4': '983.34kWh', '2019 5': '1.076.382kWh', '2019 6': '1142.1kWh', '2019 7': '1263.87kWh', '2019 8': '1230.39kWh', '2019 9': '1062.72kWh', '2019 10': '885.546kWh', '2019 11': '641.52kWh', '2019 12': '582.552kWh', '2020 1': '602.64kWh', '2020 2': '719.712kWh', '2020 3': '997.704kWh', '2020 4': '983.34kWh', '2020 5': '1.076.382kWh', '2020 6': '1142.1kWh', '2020 7': '1263.87kWh', '2020 8': '1230.39kWh', '2020 9': '1062.72kWh', '2020 10': '885.546kWh', '2020 11': '641.52kWh', '2020 12': '582.552kWh', '2021 1': '330.48kWh', '2021 2': '719.712kWh', '2021 3': '779kWh'}, 
        'longitude': -3.630877, 
        'latitude': 40.405577, 
        'capacity': 6.0}, 
    'IDM LA BOMBILLA': {
        'id': 2258, 
        'energy': {'2019 2': '1.439.424kWh', '2019 3': '1.995.408kWh', '2019 4': '1966.68kWh', '2019 5': '2.152.764kWh', '2019 6': '2284.2kWh', '2019 7': '2527.74kWh', '2019 8': '2460.78kWh', '2019 9': '2125.44kWh', '2019 10': '1.771.092kWh', '2019 11': '1283.04kWh', '2019 12': '1.165.104kWh', '2020 1': '1205.28kWh', '2020 2': '1.439.424kWh', '2020 3': '1.995.408kWh', '2020 4': '1966.68kWh', '2020 5': '2.152.764kWh', '2020 6': '2284.2kWh', '2020 7': '2527.74kWh', '2020 8': '2460.78kWh', '2020 9': '2125.44kWh', '2020 10': '1.771.092kWh', '2020 11': '1283.04kWh', '2020 12': '1.165.104kWh', '2021 1': '660.96kWh', '2021 2': '1.439.424kWh', '2021 3': '1.995.408kWh'}, 
        'longitude': -3.733391, 
        'latitude': 40.436623, 
        'capacity': 12.0}, 
    'JMD CIUDAD LINEAL (CMM, CC, BIBLIOTECA)': {
        'id': 2258, 
        'energy': {'2019 1': '2762.1kWh', '2019 2': '3298.68kWh', '2019 3': '4572.81kWh', '2019 4': '4.506.975kWh', '2019 5': '49.334.175kWh', '2019 6': '5.234.625kWh', '2019 7': '57.927.375kWh', '2019 8': '56.392.875kWh', '2019 9': '4870.8kWh', '2019 10': '40.587.525kWh', '2019 11': '2940.3kWh', '2019 12': '2670.03kWh', '2020 1': '2762.1kWh', '2020 2': '3298.68kWh', '2020 3': '4572.81kWh', '2020 4': '4.506.975kWh', '2020 5': '49.334.175kWh', '2020 6': '5.234.625kWh', '2020 7': '57.927.375kWh', '2020 8': '56.392.875kWh', '2020 9': '4870.8kWh', '2020 10': '40.587.525kWh', '2020 11': '2940.3kWh', '2020 12': '2670.03kWh', '2021 1': '1514.7kWh', '2021 2': '3298.68kWh', '2021 3': '4572.81kWh'}, 
        'longitude': -3.6369774916267, 
        'latitude': 40.43599597, 
        'capacity': 275.0}, 
    'JMD HORTALEZA': {
        'id': 2258, 
        'energy': {'2019 4': '2.212.515kWh', '2019 5': '24.218.595kWh', '2019 6': '2.569.725kWh', '2019 7': '28.437.075kWh', '2019 8': '27.683.775kWh', '2019 9': '2391.12kWh', '2019 10': '19.924.785kWh', '2019 11': '1443.42kWh', '2019 12': '1.310.742kWh', '2020 1': '1355.94kWh', '2020 2': '1.619.352kWh', '2020 3': '2.244.834kWh', '2020 4': '2.212.515kWh', '2020 5': '24.218.595kWh', '2020 6': '2.569.725kWh', '2020 7': '28.437.075kWh', '2020 8': '27.683.775kWh', '2020 9': '2391.12kWh', '2020 10': '19.924.785kWh', '2020 11': '1443.42kWh', '2020 12': '1.310.742kWh', '2021 1': '743.58kWh', '2021 2': '1.619.352kWh', '2021 3': '1494kWh'}, 
        'longitude': -3.65715427699194, 
        'latitude': 40.46299114, 
        'capacity': 135.0}, 
    'JMD LATINA': {
        'id': 2258, 
        'energy': {'2019 1': '2711.88kWh', '2019 2': '3.238.704kWh', '2019 3': '4.489.668kWh', '2019 4': '4425.03kWh', '2019 5': '4.843.719kWh', '2019 6': '5139.45kWh', '2019 7': '5.687.415kWh', '2019 8': '5.536.755kWh', '2019 9': '4782.24kWh', '2019 10': '3.984.957kWh', '2019 11': '2886.84kWh', '2019 12': '2.621.484kWh', '2020 1': '2711.88kWh', '2020 2': '3.238.704kWh', '2020 3': '4.489.668kWh', '2020 4': '4425.03kWh', '2020 5': '4.843.719kWh', '2020 6': '5139.45kWh', '2020 7': '5.687.415kWh', '2020 8': '5.536.755kWh', '2020 9': '4782.24kWh', '2020 10': '3.984.957kWh', '2020 11': '2886.84kWh', '2020 12': '2.621.484kWh', '2021 1': '1487.16kWh', '2021 2': '3.238.704kWh', '2021 3': '2979kWh'}, 
        'longitude': -3.76530195937242, 
        'latitude': 40.38385718, 
        'capacity': 27.0}, 
    'JMD USERA': {
        'id': 2258, 
        'energy': {'2019 1': '502.2kWh', '2019 2': '599.76kWh', '2019 3': '831.42kWh', '2019 4': '819.45kWh', '2019 5': '896.985kWh', '2019 6': '951.75kWh', '2019 7': '1.053.225kWh', '2019 8': '1.025.325kWh', '2019 9': '885.6kWh', '2019 10': '737.955kWh', '2019 11': '534.6kWh', '2019 12': '485.46kWh', '2020 1': '502.2kWh', '2020 2': '599.76kWh', '2020 3': '831.42kWh', '2020 4': '819.45kWh', '2020 5': '896.985kWh', '2020 6': '951.75kWh', '2020 7': '1.053.225kWh', '2020 8': '1.025.325kWh', '2020 9': '885.6kWh', '2020 10': '737.955kWh', '2020 11': '534.6kWh', '2020 12': '485.46kWh', '2021 1': '275.4kWh', '2021 2': '599.76kWh', '2021 3': '893kWh'}, 
        'longitude': -3.71075157478223, 
        'latitude': 40.38241253, 
        'capacity': 5.0}, 
    'JMD VICÁLVARO': {
        'id': 2258, 
        'energy': {'2019 1': '371.628kWh', '2019 2': '4.438.224kWh', '2019 3': '6.152.508kWh', '2019 4': '606.393kWh', '2019 5': '6.637.689kWh', '2019 6': '704.295kWh', '2019 7': '7.793.865kWh', '2019 8': '7.587.405kWh', '2019 9': '655.344kWh', '2019 10': '5.460.867kWh', '2019 11': '395.604kWh', '2019 12': '3.592.404kWh', '2020 1': '371.628kWh', '2020 2': '4.438.224kWh', '2020 3': '6.152.508kWh', '2020 4': '606.393kWh', '2020 5': '6.637.689kWh', '2020 6': '704.295kWh', '2020 7': '7.793.865kWh', '2020 8': '7.587.405kWh', '2020 9': '655.344kWh', '2020 10': '5.460.867kWh', '2020 11': '395.604kWh', '2020 12': '3.592.404kWh', '2021 1': '203.796kWh', '2021 2': '4.438.224kWh', '2021 3': '549kWh'}, 
        'longitude': -3.60598453308407, 
        'latitude': 40.40227048, 
        'capacity': 37.0}, 
    'JMD VILLA DE VALLECAS': {
        'id': 2258, 
        'energy': {'2019 1': '1255.5kWh', '2019 2': '1499.4kWh', '2019 3': '2078.55kWh', '2019 4': '2.048.625kWh', '2019 5': '22.424.625kWh', '2019 6': '2.379.375kWh', '2019 7': '26.330.625kWh', '2019 8': '25.633.125kWh', '2019 9': '2214kWh', '2019 10': '18.448.875kWh', '2019 11': '1336.5kWh', '2019 12': '1213.65kWh', '2020 1': '1255.5kWh', '2020 2': '1499.4kWh', '2020 3': '2078.55kWh', '2020 4': '2.048.625kWh', '2020 5': '22.424.625kWh', '2020 6': '2.379.375kWh', '2020 7': '26.330.625kWh', '2020 8': '25.633.125kWh', '2020 9': '2214kWh', '2020 10': '18.448.875kWh', '2020 11': '1336.5kWh', '2020 12': '1213.65kWh', '2021 1': '688.5kWh', '2021 2': '1499.4kWh', '2021 3': '1843kWh'}, 
        'longitude': -3.6214547858699, 
        'latitude': 40.37889982, 
        'capacity': 125.0}, 
    'JMD VILLAVERDE': {
        'id': 2258, 
        'energy': {'2019 1': '1757.7kWh', '2019 2': '2099.16kWh', '2019 3': '2909.97kWh', '2019 4': '2.868.075kWh', '2019 5': '31.394.475kWh', '2019 6': '3.331.125kWh', '2019 7': '36.862.875kWh', '2019 8': '35.886.375kWh', '2019 9': '3099.6kWh', '2019 10': '25.828.425kWh', '2019 11': '1871.1kWh', '2019 12': '1699.11kWh', '2020 1': '1757.7kWh', '2020 2': '2099.16kWh', '2020 3': '2909.97kWh', '2020 4': '2.868.075kWh', '2020 5': '31.394.475kWh', '2020 6': '3.331.125kWh', '2020 7': '36.862.875kWh', '2020 8': '35.886.375kWh', '2020 9': '3099.6kWh', '2020 10': '25.828.425kWh', '2020 11': '1871.1kWh', '2020 12': '1699.11kWh', '2021 1': '963.9kWh', '2021 2': '2099.16kWh', '2021 3': '2475kWh'}, 
        'longitude': -3.71120030905872, 
        'latitude': 40.34889585, 
        'capacity': 175.0}, 
    'MADRID EMPRENDE': {
        'id': 2258, 
        'energy': {'2019 1': '1004.4kWh', '2019 2': '1199.52kWh', '2019 3': '1662.84kWh', '2019 4': '1638.9kWh', '2019 5': '1793.97kWh', '2019 6': '1903.5kWh', '2019 7': '2106.45kWh', '2019 8': '2050.65kWh', '2019 9': '1771.2kWh', '2019 10': '1475.91kWh', '2019 11': '1069.2kWh', '2019 12': '970.92kWh', '2020 1': '1004.4kWh', '2020 2': '1199.52kWh', '2020 3': '1662.84kWh', '2020 4': '1638.9kWh', '2020 5': '1793.97kWh', '2020 6': '1903.5kWh', '2020 7': '2106.45kWh', '2020 8': '2050.65kWh', '2020 9': '1771.2kWh', '2020 10': '1475.91kWh', '2020 11': '1069.2kWh', '2020 12': '970.92kWh', '2021 1': '550.8kWh', '2021 2': '1199.52kWh', '2021 3': '1662.84kWh'}, 'longitude': -3.74573026512817, 'latitude': 40.36861554, 'capacity': 10.0}, 
    'MARES MADRID': {
        'id': 2258, 
        'energy': {'2019 1': '703.08kWh', '2019 2': '839.664kWh', '2019 3': '1.163.988kWh', '2019 4': '1147.23kWh', '2019 5': '1.255.779kWh', '2019 6': '1332.45kWh', '2019 7': '1.474.515kWh', '2019 8': '1.435.455kWh', '2019 9': '1239.84kWh', '2019 10': '1.033.137kWh', '2019 11': '748.44kWh', '2019 12': '679.644kWh', '2020 1': '703.08kWh', '2020 2': '839.664kWh', '2020 3': '1.163.988kWh', '2020 4': '1147.23kWh', '2020 5': '1.255.779kWh', '2020 6': '1332.45kWh', '2020 7': '1.474.515kWh', '2020 8': '1.435.455kWh', '2020 9': '1239.84kWh', '2020 10': '1.033.137kWh', '2020 11': '748.44kWh', '2020 12': '679.644kWh', '2021 1': '385.56kWh', '2021 2': '839.664kWh', '2021 3': '1.163.988kWh'}, 
        'longitude': -3.669802, 
        'latitude': 40.390628, 
        'capacity': 7.0}, 
    'MARES VILLAVERDE MAR DE ALIMENTACION Y CUIDADO': {
        'id': 2258, 
        'energy': {'2019 2': '839.664kWh', '2019 3': '1.163.988kWh', '2019 4': '1147.23kWh', '2019 5': '1.255.779kWh', '2019 6': '1332.45kWh', '2019 7': '1.474.515kWh', '2019 8': '1.435.455kWh', '2019 9': '1239.84kWh', '2019 10': '1.033.137kWh', '2019 11': '748.44kWh', '2019 12': '679.644kWh', '2020 1': '703.08kWh', '2020 2': '839.664kWh', '2020 3': '1.163.988kWh', '2020 4': '1147.23kWh', '2020 5': '1.255.779kWh', '2020 6': '1332.45kWh', '2020 7': '1.474.515kWh', '2020 8': '1.435.455kWh', '2020 9': '1239.84kWh', '2020 10': '1.033.137kWh', '2020 11': '748.44kWh', '2020 12': '679.644kWh', '2021 1': '385.56kWh', '2021 2': '839.664kWh', '2021 3': '1.163.988kWh'}, 
        'longitude': -3.710522, 
        'latitude': 40.346099, 
        'capacity': 7.0}, 
    'NAVE BOETTICHER': {
        'id': 2258, 
        'energy': {'2019 1': '502.2kWh', '2019 2': '599.76kWh', '2019 3': '831.42kWh', '2019 4': '819.45kWh', '2019 5': '896.985kWh', '2019 6': '951.75kWh', '2019 7': '1.053.225kWh', '2019 8': '1.025.325kWh', '2019 9': '885.6kWh', '2019 10': '737.955kWh', '2019 11': '534.6kWh', '2019 12': '485.46kWh', '2020 1': '502.2kWh', '2020 2': '599.76kWh', '2020 3': '831.42kWh', '2020 4': '819.45kWh', '2020 5': '896.985kWh', '2020 6': '951.75kWh', '2020 7': '1.053.225kWh', '2020 8': '1.025.325kWh', '2020 9': '885.6kWh', '2020 10': '737.955kWh', '2020 11': '534.6kWh', '2020 12': '485.46kWh', '2021 1': '275.4kWh', '2021 2': '599.76kWh', '2021 3': '831.42kWh'}, 
        'longitude': -3.696499, 
        'latitude': 40.347177, 
        'capacity': 5.0}, 
    'PARQUE BOMBEROS Nº 6': {
        'id': 2258, 
        'energy': {'2020 1': '502.2kWh', '2020 2': '599.76kWh', '2020 3': '831.42kWh', '2020 4': '819.45kWh', '2020 5': '896.985kWh', '2020 6': '951.75kWh', '2020 7': '1.053.225kWh', '2020 8': '1.025.325kWh', '2020 9': '885.6kWh', '2020 10': '737.955kWh', '2020 11': '534.6kWh', '2020 12': '485.46kWh', '2021 1': '275.4kWh', '2021 2': '599.76kWh', '2021 3': '831.42kWh'}, 
        'longitude': -3.70654962226942, 
        'latitude': 40.42673583, 
        'capacity': 5.0}, 
    'PARQUE BOMBEROS Nº 8': {
        'id': 2258, 
        'energy': {'2019 11': '2673kWh', '2019 12': '2427.3kWh', '2020 1': '2511kWh', '2020 2': '2998.8kWh', '2020 3': '4157.1kWh', '2020 4': '4097.25kWh', '2020 5': '4.484.925kWh', '2020 6': '4758.75kWh', '2020 7': '5.266.125kWh', '2020 8': '5.126.625kWh', '2020 9': '4428kWh', '2020 10': '3.689.775kWh', '2020 11': '2673kWh', '2020 12': '2427.3kWh', '2021 1': '1377kWh', '2021 2': '2998.8kWh', '2021 3': '4157.1kWh'}, 
        'longitude': -3.65313089708813, 
        'latitude': 40.39461732, 
        'capacity': 25.0}, 
    'PARQUE DE BOMBEROS Nº 11': {
        'id': 2258, 
        'energy': {'2019 10': '3.689.775kWh', '2019 11': '2673kWh', '2019 12': '2427.3kWh', '2020 1': '2511kWh', '2020 2': '2998.8kWh', '2020 3': '4157.1kWh', '2020 4': '4097.25kWh', '2020 5': '4.484.925kWh', '2020 6': '4758.75kWh', '2020 7': '5.266.125kWh', '2020 8': '5.126.625kWh', '2020 9': '4428kWh', '2020 10': '3.689.775kWh', '2020 11': '2673kWh', '2020 12': '2427.3kWh', '2021 1': '1377kWh', '2021 2': '2998.8kWh', '2021 3': '4157.1kWh'}, 
        'longitude': -3.66508396658848, 
        'latitude': 40.4740989, 
        'capacity': 25.0}, 
    'PARQUE DE BOMBEROS Nº 7': {
        'id': 2258, 
        'energy': {'2019 1': '3515.4kWh', '2019 2': '4198.32kWh', '2019 3': '5819.94kWh', '2019 4': '5736.15kWh', '2019 5': '6.278.895kWh', '2019 6': '6662.25kWh', '2019 7': '7.372.575kWh', '2019 8': '7.177.275kWh', '2019 9': '6199.2kWh', '2019 10': '5.165.685kWh', '2019 11': '3742.2kWh', '2019 12': '3398.22kWh', '2020 1': '3515.4kWh', '2020 2': '4198.32kWh', '2020 3': '5819.94kWh', '2020 4': '5736.15kWh', '2020 5': '6.278.895kWh', '2020 6': '6662.25kWh', '2020 7': '7.372.575kWh', '2020 8': '7.177.275kWh', '2020 9': '6199.2kWh', '2020 10': '5.165.685kWh', '2020 11': '3742.2kWh', '2020 12': '3398.22kWh', '2021 1': '1927.8kWh', '2021 2': '4198.32kWh', '2021 3': '5819.94kWh'}, 
        'longitude': -3.61543736646443, 
        'latitude': 40.42832884, 
        'capacity': 35.0}, 
    'PLANETARIO': {
        'id': 2258, 
        'energy': {'2019 10': '3.689.775kWh', '2019 11': '2673kWh', '2019 12': '2427.3kWh', '2020 1': '2511kWh', '2020 2': '2998.8kWh', '2020 3': '4157.1kWh', '2020 4': '4097.25kWh', '2020 5': '4.484.925kWh', '2020 6': '4758.75kWh', '2020 7': '5.266.125kWh', '2020 8': '5.126.625kWh', '2020 9': '4428kWh', '2020 10': '3.689.775kWh', '2020 11': '2673kWh', '2020 12': '2427.3kWh', '2021 1': '1377kWh', '2021 2': '2998.8kWh', '2021 3': '4157.1kWh'}, 
        'longitude': -3.68539115543989, 
        'latitude': 40.39094408, 
        'capacity': 25.0}, 
    'ROSALEDA DEL PARQUE DEL OESTE': {
        'id': 2258, 
        'energy': {'2019 1': '502.2kWh', '2019 2': '599.76kWh', '2019 3': '831.42kWh', '2019 4': '819.45kWh', '2019 5': '896.985kWh', '2019 6': '951.75kWh', '2019 7': '1.053.225kWh', '2019 8': '1.025.325kWh', '2019 9': '885.6kWh', '2019 10': '737.955kWh', '2019 11': '534.6kWh', '2019 12': '485.46kWh', '2020 1': '502.2kWh', '2020 2': '599.76kWh', '2020 3': '831.42kWh', '2020 4': '819.45kWh', '2020 5': '896.985kWh', '2020 6': '951.75kWh', '2020 7': '1.053.225kWh', '2020 8': '1.025.325kWh', '2020 9': '885.6kWh', '2020 10': '737.955kWh', '2020 11': '534.6kWh', '2020 12': '485.46kWh', '2021 1': '275.4kWh', '2021 2': '599.76kWh', '2021 3': '831.42kWh'}, 
        'longitude': -3.721771, 
        'latitude': 40.426646000000005, 
        'capacity': 5.0},  
    'VIVERO MUNICIPAL DE CASA DE CAMPO': {
        'id': 2258, 
        'energy': {'2019 1': '502.2kWh', '2019 2': '599.76kWh', '2019 3': '831.42kWh', '2019 4': '819.45kWh', '2019 5': '896.985kWh', '2019 6': '951.75kWh', '2019 7': '1.053.225kWh', '2019 8': '1.025.325kWh', '2019 9': '885.6kWh', '2019 10': '737.955kWh', '2019 11': '534.6kWh', '2019 12': '485.46kWh', '2020 1': '502.2kWh', '2020 2': '599.76kWh', '2020 3': '831.42kWh', '2020 4': '819.45kWh', '2020 5': '896.985kWh', '2020 6': '951.75kWh', '2020 7': '1.053.225kWh', '2020 8': '1.025.325kWh', '2020 9': '885.6kWh', '2020 10': '737.955kWh', '2020 11': '534.6kWh', '2020 12': '485.46kWh', '2021 1': '275.4kWh', '2021 2': '599.76kWh', '2021 3': '831.42kWh'}, 
        'longitude': -3.723958, 
        'latitude': 40.420606, 
        'capacity': 5.0}, 
    'VIVERO MUNICIPAL DE ESTUFAS DEL RETIRO': {
        'id': 2258, 
        'energy': {'2019 1': '502.2kWh', '2019 2': '599.76kWh', '2019 3': '831.42kWh', '2019 4': '819.45kWh', '2019 5': '896.985kWh', '2019 6': '951.75kWh', '2019 7': '1.053.225kWh', '2019 8': '1.025.325kWh', '2019 9': '885.6kWh', '2019 10': '737.955kWh', '2019 11': '534.6kWh', '2019 12': '485.46kWh', '2020 1': '502.2kWh', '2020 2': '599.76kWh', '2020 3': '831.42kWh', '2020 4': '819.45kWh', '2020 5': '896.985kWh', '2020 6': '951.75kWh', '2020 7': '1.053.225kWh', '2020 8': '1.025.325kWh', '2020 9': '885.6kWh', '2020 10': '737.955kWh', '2020 11': '534.6kWh', '2020 12': '485.46kWh', '2021 1': '275.4kWh', '2021 2': '599.76kWh', '2021 3': '831.42kWh'}, 
        'longitude': -3.68617978009932, 
        'latitude': 40.40943503, 
        'capacity': 5.0}, 
    'VIVERO MUNICIPAL DE MIGAS CALIENTES': {
        'id': 2258, 
        'energy': {'2019 1': '502.2kWh', '2019 2': '599.76kWh', '2019 3': '831.42kWh', '2019 4': '819.45kWh', '2019 5': '896.985kWh', '2019 6': '951.75kWh', '2019 7': '1.053.225kWh', '2019 8': '1.025.325kWh', '2019 9': '885.6kWh', '2019 10': '737.955kWh', '2019 11': '534.6kWh', '2019 12': '485.46kWh', '2020 1': '502.2kWh', '2020 2': '599.76kWh', '2020 3': '831.42kWh', '2020 4': '819.45kWh', '2020 5': '896.985kWh', '2020 6': '951.75kWh', '2020 7': '1.053.225kWh', '2020 8': '1.025.325kWh', '2020 9': '885.6kWh', '2020 10': '737.955kWh', '2020 11': '534.6kWh', '2020 12': '485.46kWh', '2021 1': '275.4kWh', '2021 2': '599.76kWh', '2021 3': '831.42kWh'}, 
        'longitude': -3.73817152304904, 
        'latitude': 40.43686257,
        'capacity': 5.0}
}
