import { createTheme } from '@material-ui/core/styles';


export const theme = createTheme({
    typography: {
      fontFamily: 'Comfortaa',
    },
    overrides: {
      MuiCssBaseline: {
        '@global': {
          '*::-webkit-scrollbar': {
            width: '0.2em',
          },
          '*::-webkit-scrollbar-track': {
            '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.00)'
          },
          '*::-webkit-scrollbar-thumb': {
            backgroundColor: '#14487c',
            outline: '1px solid slategrey',
            height: 100
          }
        }
      },
      MuiIcon: {
        root: {
          overflow: 'none'
        }
      },
      MuiSelect: {
        icon: {
          color: '#1a88c9',
          fontSize: 30, 
          marginTop: -5
        }
      },
    //   MuiFormControlLabel: {
    //     root: {
    //       color: 'black'
    //     }
    //   },
    //   MuiLink: {
    //     underlineHover: {
    //       cursor: 'pointer'
    //     }
    //   }
    },
    palette: {
      background: {
        default: "white"
      },
      primary: {
        main: '#14487c',
      },
      secondary: {
        main: '#2ab683'
        // '#93c849'
        // '#1a88c9',
      },
    },
    
  });
  