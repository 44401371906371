/**
 * This component provides a multiple filter functionality on a multiple select dropdown.
 * Provided from the props, filterType is the general category of the filter(string),filterOptions need an array of filters which we can select through a checkbox 
 * (checked filters are the active filters),filters needs an array of all the filters(strings), setFilters gets a function that toggles the current filters,
 * group prop defines from which "group" the filters come from (arrays of strings in options.js which categorise the filters).
 * The last prop, applyFilters is for a function that applies the whole multi-filtering functionality onClose of the Select dropdown
 */


import React, {useEffect, useState} from 'react';
import {OutlinedInput, 
  InputLabel, 
  MenuItem, 
  FormControl, 
  ListItemText,
  Select,
  Checkbox,
} from '@material-ui/core';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 400,
    },
  },
};

export function GenMultipleSelectCheckmarks({ filterType, filterOptions, filters, setFilters, group, applyFilters }) {
  const activeFilters = filters.map((filter, i) => filter.name);
  const [checked, setChecked] = useState([])

  useEffect(() => {
    if (checked.length !== 0) {
      setFilters(checked, group);
      setChecked([])
    }
  }, [checked])

  return (
    <div style={{margin: '20px 20px 20px 0'}}>
      <FormControl fullWidth >
        <InputLabel style={{paddingLeft: 10}}>{ filterType }</InputLabel>
        <Select
          multiple
          onClose={applyFilters}
          value={filters.map((item, i) => item.group === group && item.name)}
          input={<OutlinedInput label={ filterType } />}
          renderValue={(selected) => selected}
          MenuProps={{
            getContentAnchorEl: () => null,
            MenuProps,
          }}
        >
        <div style={{height: 500}}>
          {filterOptions.map((type) => (
            <MenuItem key={type} value={type}>
              <Checkbox checked={activeFilters.indexOf(type) > -1} onChange={(e) => setChecked(type)}/>
              <ListItemText primary={type} />
            </MenuItem>
          ))}
        </div>
        </Select>
      </FormControl>
    </div>
  );
}