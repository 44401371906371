/**
 * This component helps in organising a page, as it is used to display "cards" with different content.
 * The props used in this component are the image(passed in as jpg or png files in public folder), 
 * the title,description (strings) and a button which navigates the user to an endpoint(string for the endpoint path), given by locateTo prop. 
 */


import { React } from 'react'
import { Card, CardMedia, Typography, CardActions, Button, CardContent } from '@material-ui/core'  


export function CardComponent({image, title, description, button=false, locateTo=''}) {    

    return (
        <Card>
            <CardMedia
                component="img"
                height="250"
                image={image}
                />
            <CardContent style={{height: 140}}>
              <Typography gutterBottom variant="h5" component="div">
                    {title}
                </Typography>
                <Typography variant="body2">
                    {description}
                </Typography>
            </CardContent>
            {button ? 
                <CardActions>
                    <Button size="small" onClick={() => window.location.href = locateTo}>inspect data</Button>
                </CardActions>
                :
                <CardActions style={{height: 45}}/>
            }
        </Card>
    )

}