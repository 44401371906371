/**
 * Header component is as the name states, the header of the page, containing a logo of the site which goes to the main page on click and 2 navigation links
 * One for main page and one for city insights which allows you to select a city which you want to go to it's page.
 */


import { React } from 'react'
import { Grid, Paper } from '@material-ui/core'  
import {URLS, GetRenders} from '../helper/util'

export function HeaderSimple() {   
    const styles={
        link: {
            color: '#222', 
            fontSize: GetRenders().XS ? 12 : 20, 
            marginRight: GetRenders().XS ? 5 : 10, 
            cursor: 'pointer'
        }
    } 
    

    return (
        <Grid container style={{padding: 10, marginBottom: 20, width: '100%', backgroundColor: '#cad2d4', borderRadius: 0}} component={Paper} elevation={6}>
            <Grid item xs={12} sm={7} md={8} lg={9} style={{padding: GetRenders().XS ? 0 : 20}}>
                <img src='/logo.svg' alt='matrycs-logo' onClick={() => window.location.href=URLS.HOME}/>
            </Grid>
        </Grid>
    )

}