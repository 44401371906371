import React from 'react'
import { Button } from '@mui/material'
import { styles } from './Button.styles.js'

export function MyButton ({ buttonText, onClick }) {
  const classes = styles()

  return (
    <Button variant='contained' className={classes.root} onClick={onClick}>{buttonText}</Button>
  )
}
